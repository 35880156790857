import { Sparkles } from '@/icons';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import CopyTextComponent from '../../../components/common/CopyTextComponent';
import FullScreenLoading from '../../../components/common/FullScreenLoading';
import CoveBadge from '../components/CoveBadge';
import CoveModal from '../components/CoveModal';
import { CoveModalFooterButtonProps } from '../components/CoveModalFooter';
import DashboardHeader from '../components/DashboardHeader';

import {
  GQLUserPenaltySeverity,
  GQLUserPermission,
  useGQLDeletePolicyMutation,
  useGQLGetOrgIsReadyToCreateCoveModelsQuery,
  useGQLPoliciesWithModelsQuery,
} from '../../../graphql/generated';
import LogoAndWordmarkAndAIDarkPurple from '../../../images/LogoAndWordmarkAndAIDarkPurple.png';
import { userHasPermissions } from '../../../routing/permissions';
import { Tree, treeFromList, TreeNode } from '../../../utils/tree';
import { ModalInfo } from '../types/ModalInfo';
import HTMLRenderer from './HTMLRenderer';
import PolicyInputModal, { PolicyInputModalInfo } from './PolicyInputModal';

export type Policy = {
  id: string;
  name: string;
  penalty: GQLUserPenaltySeverity;
  policyText?: string;
  enforcementGuidelines?: string;
};

export type PoliciesDashboardState = {
  modalInfo: ModalInfo;
  mutatePolicyModalInfo: PolicyInputModalInfo | undefined;
  canEditPolicies: boolean;
  isEdited: boolean;
  policyTree: Tree<Policy>;
};

gql`
  fragment PolicyFields on Policy {
    id
    name
    policyText
    enforcementGuidelines
    parentId
    penalty
    policyType
  }

  query Policies {
    myOrg {
      policies {
        ...PolicyFields
      }
    }
  }

  query PoliciesWithModels {
    myOrg {
      policies {
        ...PolicyFields
      }
      models {
        id
        status
        version
        policy {
          id
        }
      }
    }
    me {
      permissions
    }
  }

  mutation AddPolicies($policies: [AddPolicyInput!]!) {
    addPolicies(policies: $policies) {
      policies {
        ...PolicyFields
      }
      failures
    }
  }

  query GetOrgIsReadyToCreateCoveModels {
    getOrgIsReadyToCreateCoveModels
  }

  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ...PolicyFields
    }
  }

  mutation DeletePolicy($id: ID!) {
    deletePolicy(id: $id)
  }
`;

/**
 * Policy Dashboard screen
 */
export default function PoliciesDashboard() {
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useGQLPoliciesWithModelsQuery();
  const { error: orgIsReadyError, data: orgIsReadyData } =
    useGQLGetOrgIsReadyToCreateCoveModelsQuery();
  const orgIsReady =
    !orgIsReadyError && orgIsReadyData?.getOrgIsReadyToCreateCoveModels;

  const [deletePolicy, { loading: deletePolicyLoading }] =
    useGQLDeletePolicyMutation();

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    visible: false,
    title: '',
    body: '',
    okText: 'OK',
    onOk: () => {},
    okIsDangerButton: false,
    cancelVisible: false,
  });
  const [mutatePolicyModalInfo, setMutatePolicyModalInfo] = useState<
    PolicyInputModalInfo | undefined
  >(undefined);
  const [policyTree, setPolicyTree] = useState<Tree<Policy>>(
    new Tree<Policy>('root', {
      id: '-1',
      name: 'root',
      penalty: GQLUserPenaltySeverity.None,
    }),
  );
  const [expandedPolicies, setExpandedPolicies] = useState<string[]>([]);
  const [clickedPolicyId, setClickedPolicyId] = useState<string | undefined>(
    undefined,
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const filteredPolicyTree = useMemo(() => {
    if (searchTerm.trim().length === 0) {
      return policyTree;
    }

    return policyTree.filterTree((policy) =>
      policy.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [policyTree, searchTerm]);

  const treeSize = policyTree.size();
  const policyNames = policyTree.getValues((policy) => policy.name);

  const onHideModal = () => {
    setModalInfo({ ...modalInfo, visible: false });
    refetch();
  };
  const onHideMutatePolicyModal = () => {
    setMutatePolicyModalInfo(undefined);
    refetch();
  };

  const permissions = data?.me?.permissions;
  const policyList = data?.myOrg?.policies;
  const policyIdsWithDeployedModels = data?.myOrg?.models
    .filter((it) => it.status === 'DEPLOYED')
    .map((it) => it.policy.id);

  const clickedPolicy = clickedPolicyId
    ? policyList?.find((it) => it.id === clickedPolicyId)
    : undefined;

  useMemo(() => {
    if (policyList) {
      setPolicyTree(
        treeFromList<Policy>(
          policyList,
          { id: '-1', name: 'root', penalty: GQLUserPenaltySeverity.None },
          (policy) => ({
            id: policy.id,
            name: policy.name,
            penalty: policy.penalty,
            policyText: policy.policyText,
            enforcementGuidelines: policy.enforcementGuidelines,
          }),
        ),
      );
    }
  }, [policyList]);

  const noPoliciesYet = useMemo(
    () => policyTree.root && policyTree.root.isLeaf,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [policyTree.root, treeSize],
  );

  const showAddPolicyModal = (parent: TreeNode<Policy>) =>
    setMutatePolicyModalInfo({
      parent:
        // NB: We filter out -1 because any root node can have an ID of -1 (see
        // initial state of policyTree), but root nodes do not have parents
        parent.value && parent.value.name !== 'root' && parent.value.id !== '-1'
          ? parent.value
          : undefined,
      onClose: onHideMutatePolicyModal,
    });
  const toggleExpanded = (policyName: string) => {
    if (expandedPolicies.includes(policyName)) {
      setExpandedPolicies(expandedPolicies.filter((it) => it !== policyName));
    } else {
      setExpandedPolicies([...expandedPolicies, policyName]);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      // Expand all policies
      setExpandedPolicies(policyNames);
    } else {
      setExpandedPolicies([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const renderPolicy = (policy: TreeNode<Policy>) => {
    const children = policy.children.length ? (
      <div className="flex items-stretch h-full pb-8">
        <div className="flex w-px h-full mx-2 bg-slate-200" />
        <div className="flex flex-col justify-center w-full pl-8 mb-6">
          {policy.children.map((child, i) => (
            <div key={i} className="flex flex-col">
              {renderPolicy(child)}
            </div>
          ))}
          <Button
            className="ml-3 focus:text-black focus:border-slate-200"
            shape={'circle'}
            type="default"
            icon={<PlusOutlined />}
            onClick={() => showAddPolicyModal(policy)}
          />
        </div>
      </div>
    ) : null;

    const previewPolicyText = policy.value.policyText?.replace(/<[^>]+>/g, ' ');

    return (
      <div key={policy.key} className="flex flex-col w-full">
        <div className="flex flex-col items-stretch mb-6">
          <div
            className={`flex items-start justify-between p-4 rounded-md border border-solid cursor-pointer w-full ${
              clickedPolicyId === policy.value.id
                ? 'border-sky-400 bg-sky-100'
                : 'bg-white border-slate-200 hover:bg-sky-50 hover:border-sky-200'
            }`}
            onClick={() => setClickedPolicyId(policy.value.id)}
          >
            <div className="flex flex-col w-full p-2">
              <div className="flex items-center gap-6">
                <div className="text-base font-bold text-start">
                  {policy.value?.name}
                </div>
                <div className="flex items-center gap-2 text-slate-400">
                  ID: <CopyTextComponent value={policy.value.id} />
                </div>
                <div className="grow" />
                {policyIdsWithDeployedModels?.includes(policy.value.id) && (
                  <div className="justify-self-end">
                    <CoveBadge
                      colorVariant="soft-green"
                      shapeVariant="rounded"
                      label="AI Enabled"
                      icon={<Sparkles />}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center justify-between mt-4 text-slate-700 text-start">
                {previewPolicyText ? (
                  <div className="text-start">
                    {previewPolicyText.length > 100
                      ? previewPolicyText.slice(0, 100) + '...'
                      : previewPolicyText}
                  </div>
                ) : (
                  'No definition provided'
                )}
                <div className="flex">
                  <Button
                    className="ml-3 focus:text-black focus:border-slate-200"
                    shape={noPoliciesYet ? 'round' : 'circle'}
                    type="default"
                    icon={<PlusOutlined />}
                    onClick={() => showAddPolicyModal(policy)}
                  >
                    {noPoliciesYet ? 'Add your first policy' : null}
                  </Button>
                  <Button
                    className="ml-3 focus:text-black focus:border-slate-200"
                    key={`edit_${policy.key}`}
                    shape="circle"
                    icon={<EditOutlined />}
                    disabled={
                      !userHasPermissions(permissions, [
                        GQLUserPermission.ManageOrg,
                      ])
                    }
                    onClick={() => {
                      setMutatePolicyModalInfo({
                        parent:
                          // NB: We filter out -1 because any root node can have
                          // an ID of -1 (see initial state of policyTree), but
                          // root nodes do not have parents
                          policy.parent && policy.parent.value.id !== '-1'
                            ? policy.parent?.value
                            : undefined,
                        onClose: onHideMutatePolicyModal,
                        existingPolicy: policy.value,
                      });
                    }}
                  />
                  <Button
                    className="ml-3 hover:text-red-500 hover:border-red-500"
                    key={`delete_${policy.key}`}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    loading={deletePolicyLoading}
                    onClick={() =>
                      setModalInfo({
                        visible: true,
                        title: `Delete ${policy.value.name} policy?`,
                        body: `Please confirm you'd like to delete this policy. This action cannot be undone.`,
                        okText: 'OK',
                        cancelText: 'Cancel',
                        onOk: () => {
                          deletePolicy({
                            variables: { id: policy.value.id },
                            onCompleted: onHideModal,
                            onError: () =>
                              setModalInfo({
                                visible: true,
                                title: 'Error',
                                body: 'Error deleting policy. Please try again.',
                                okText: 'OK',
                                onOk: onHideModal,
                                okIsDangerButton: false,
                                cancelVisible: false,
                              }),
                          });
                        },
                        onCancel: onHideModal,
                        okIsDangerButton: true,
                        cancelVisible: true,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {policy.children.length ? (
            <div className="flex items-center gap-4 pt-3 pl-6 font-medium text-slate-500">
              <div>
                {policy.children.length === 1
                  ? '1 SUB-POLICY'
                  : `${policy.children.length} SUB-POLICIES`}
              </div>
              <div className="w-1 h-1 rounded-full bg-slate-500"></div>
              <div
                className="cursor-pointer flex items-center justify-start gap-1.5"
                onClick={() => toggleExpanded(policy.value.name)}
              >
                {expandedPolicies.includes(policy.value.name)
                  ? 'Hide all'
                  : 'Show all'}
                {expandedPolicies.includes(policy.value.name) ? (
                  <UpOutlined className="flex text-xs" />
                ) : (
                  <DownOutlined className="flex text-xs" />
                )}
              </div>
            </div>
          ) : null}
        </div>
        {expandedPolicies.includes(policy.value.name) ? children : null}
      </div>
    );
  };

  const policies = useMemo(() => {
    return (
      <div className="flex flex-col items-stretch w-full mb-6">
        {filteredPolicyTree?.root.children.map(renderPolicy)}
        <Button
          className="focus:text-black focus:border-slate-200"
          shape={noPoliciesYet ? 'round' : 'circle'}
          type="default"
          icon={<PlusOutlined />}
          onClick={() => showAddPolicyModal(policyTree.root)}
        >
          {noPoliciesYet ? 'Add your first policy' : null}
        </Button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPolicyTree, treeSize, policyNames, noPoliciesYet]);

  const modalFooter: CoveModalFooterButtonProps[] = [
    {
      title: modalInfo.okText,
      type: modalInfo.okIsDangerButton ? 'danger' : 'primary',
      onClick: modalInfo.onOk,
    },
  ];
  if (modalInfo.cancelVisible) {
    modalFooter.unshift({
      title: 'Cancel',
      onClick: onHideModal,
      type: modalInfo.okIsDangerButton ? 'primary' : 'secondary',
    });
  }

  const modal = (
    <CoveModal
      title={modalInfo.title}
      visible={modalInfo.visible}
      onClose={onHideModal}
      footer={modalFooter}
    >
      {modalInfo.body}
    </CoveModal>
  );

  if (error) {
    console.log(error);
    return <div />;
  }
  if (loading) {
    return <FullScreenLoading />;
  }

  const searchBar = (
    <Input
      key="searchBar"
      className="!w-48"
      placeholder="Search"
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.target.value)}
      suffix={
        searchTerm ? (
          <div className="cursor-pointer">
            <CloseCircleOutlined onClick={() => setSearchTerm('')} />
          </div>
        ) : null
      }
      // Note: we autofocus here because the input component behaves weirdly
      // otherwise...specifically, after writing the first character (or
      // removing the last character when there's only a single character in the
      // field), it unfocuses automatically, which is an incredibly annoying
      // user experience. Instead, let's just autofocus. The users who aren't
      // trying to search won't care/notice, and the ones who are will be
      // grateful that it's already selected for them.
      autoFocus
    />
  );

  return (
    <div className="flex flex-col items-start">
      <Helmet>
        <title>Policies</title>
      </Helmet>
      <DashboardHeader
        title="Policies"
        subtitle="Create policy categories here so you can track metrics across various policies. You can assign each rule you create to one or more of these policies."
      />
      {searchBar}
      <div className="flex flex-row items-stretch w-full mt-6">
        <div className="flex flex-col items-start w-2/3 overflow-y-auto">
          {policies}
        </div>
        <div className="flex w-px h-auto mx-8 bg-slate-200" />
        <div className="sticky flex w-1/3 h-[80vh] overflow-y-auto top-8">
          {clickedPolicyId ? (
            <div className="flex flex-col items-start w-full">
              <div className="flex items-center justify-between w-full">
                <div className="text-lg font-bold text-slate-800">
                  {clickedPolicy?.name} Policy
                </div>
              </div>
              <div className="flex flex-col items-center w-full gap-6 p-6 my-6 border border-solid rounded-lg border-slate-200 bg-slate-50">
                <img
                  alt="Cove AI"
                  className="w-auto h-8"
                  src={LogoAndWordmarkAndAIDarkPurple}
                />
                {clickedPolicy?.id &&
                policyIdsWithDeployedModels?.includes(clickedPolicy.id) ? (
                  <div>
                    You've already created an AI model for this policy. To
                    retrain it, please contact our support team directly.
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      if (orgIsReady && clickedPolicy) {
                        navigate(
                          `/training/select_modality?policyId=${clickedPolicy.id}`,
                        );
                      } else {
                        setModalInfo({
                          visible: true,
                          title: 'Get Started With Cove AI',
                          body: "We're excited you want to use Cove AI to enforce this policy! For now, the Cove team has to set this up for you. Please contact them at support@getcove.com",
                          okText: 'OK',
                          onOk: onHideModal,
                          okIsDangerButton: false,
                          cancelVisible: false,
                        });
                      }
                    }}
                    className="flex items-center justify-center w-full p-3 text-base font-bold text-white rounded cursor-pointer bg-primary hover:bg-primary/70 focus:bg-primary"
                  >
                    Get started
                  </div>
                )}
              </div>
              <div className="mb-1 font-bold">Definition</div>
              <HTMLRenderer
                rawHTML={clickedPolicy?.policyText ?? 'No definition provided'}
              />
              {clickedPolicy?.enforcementGuidelines ? (
                <>
                  <div className="mt-3 mb-1 font-bold">
                    Enforcement Guidelines
                  </div>
                  <HTMLRenderer rawHTML={clickedPolicy.enforcementGuidelines} />
                </>
              ) : null}
            </div>
          ) : (
            <div className="flex flex-col items-start w-full">
              <div className="flex flex-col w-full gap-6 py-24 text-lg text-center border border-solid rounded-lg border-slate-200 bg-slate-50 text-slate-500">
                Select a policy to get details
              </div>
            </div>
          )}
        </div>
      </div>
      {modal}
      {mutatePolicyModalInfo ? (
        <PolicyInputModal modalInfo={mutatePolicyModalInfo} />
      ) : null}
    </div>
  );
}

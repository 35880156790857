import GreenCheck from '../../../../../images/GreenCheck.png';
import LogoGradient from '../../../../../images/LogoGradient.png';

export default function TrainingPipelineLoading(props: {
  title: string;
  loading: boolean;
  subtitle?: string;
}) {
  const { title, loading, subtitle } = props;
  return (
    <div className="flex flex-col items-center justify-center h-full max-w-2xl text-start">
      <div className="pb-6 text-3xl font-bold">{title}</div>
      {subtitle ? (
        <div className="pb-4 text-lg text-slate-500">{subtitle}</div>
      ) : null}
      <div className="relative w-full h-48">
        {loading ? (
          <>
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <img alt="logo" src={LogoGradient} width={75} />
            </div>
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <div className="w-36 h-36 border border-dashed border-[#8087F7] border-t-[#8087F733] rounded-full animate-spin-slow" />
            </div>
          </>
        ) : (
          <img
            alt="logo"
            src={GreenCheck}
            className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-36 h-36"
          />
        )}
      </div>
    </div>
  );
}

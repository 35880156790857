import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { hiveProjectNames } from '@protego-api/types';
import { Button, Input } from 'antd';

import {
  GQLAmazonRekognitionIntegrationApiCredential,
  GQLCheckstepIntegrationApiCredential,
  GQLHiveIntegrationApiCredential,
  GQLIntegration,
  GQLIntegrationApiCredential,
  GQLOpenAiIntegrationApiCredential,
  GQLPerspectiveIntegrationApiCredential,
  GQLSpectrumIntegrationApiCredential,
} from '../../../graphql/generated';

export default function IntegrationConfigApiCredentialsSection(props: {
  name: GQLIntegration;
  setApiCredential: (cred: GQLIntegrationApiCredential) => void;
  apiCredential: GQLIntegrationApiCredential;
}) {
  const { setApiCredential, apiCredential } = props;

  const renderSpectrumCredential = (
    apiCredential: GQLSpectrumIntegrationApiCredential,
  ) => {
    return (
      <div className="flex flex-col items-start gap-4">
        <div className="flex flex-col w-1/2">
          <div className="mb-1">API Key</div>
          <Input
            value={apiCredential.apiKey}
            onChange={(event) =>
              setApiCredential({
                ...apiCredential,
                apiKey: event.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-col w-1/2">
          <div className="mb-1">Client ID</div>
          <Input
            value={apiCredential.clientId}
            onChange={(event) =>
              setApiCredential({
                ...apiCredential,
                clientId: event.target.value,
              })
            }
          />
        </div>
      </div>
    );
  };

  const renderHiveCredential = (
    apiCredential: GQLHiveIntegrationApiCredential,
  ) => {
    return (
      <div className="flex flex-col gap-4">
        {hiveProjectNames.map((project) => (
          <div key={project} className="flex flex-col w-1/2">
            <div className="mb-1">{project} API Key</div>
            <Input
              value={
                apiCredential.apiKeys.find((key) => key.project === project)
                  ?.apiKey
              }
              onChange={(event) => {
                const newApiKeys = [...apiCredential.apiKeys];
                const index = newApiKeys.findIndex(
                  (pair) => pair.project === project,
                );
                if (index === -1) {
                  // This project did not have any API key set prior
                  newApiKeys.push({
                    __typename: 'HiveIntegrationApiCredentialPair',
                    apiKey: event.target.value,
                    project,
                  });
                } else if (!event.target.value.length) {
                  // The entire API key has been deleted, so we remove the (project, apiKey)
                  // pair from newApiKeys
                  newApiKeys.splice(index, 1);
                } else {
                  // We're just editing an existing API key
                  newApiKeys.splice(index, 1, {
                    ...newApiKeys[index],
                    apiKey: event.target.value,
                  });
                }
                setApiCredential({
                  ...apiCredential,
                  apiKeys: newApiKeys,
                });
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderOpenAiCredential = (
    apiCredential: GQLOpenAiIntegrationApiCredential,
  ) => {
    return (
      <div className="flex flex-col w-1/2">
        <div className="mb-1">API Key</div>
        <Input
          value={apiCredential.apiKey}
          onChange={(event) =>
            setApiCredential({
              ...apiCredential,
              apiKey: event.target.value,
            })
          }
        />
      </div>
    );
  };

  const renderPerspectiveCredential = (
    apiCredential: GQLPerspectiveIntegrationApiCredential,
  ) => {
    return (
      <div className="flex flex-col w-1/2">
        <div className="mb-1">API Key</div>
        <Input
          value={apiCredential.apiKey}
          onChange={(event) =>
            setApiCredential({
              ...apiCredential,
              apiKey: event.target.value,
            })
          }
        />
      </div>
    );
  };

  const renderAmazonRekognitionCredential = (
    apiCredential: GQLAmazonRekognitionIntegrationApiCredential,
  ) => {
    return (
      <div className="flex flex-col items-start w-1/2">
        <div className="mb-1">AWS Access Key ID</div>
        <Input
          value={apiCredential.awsAccessKeyId}
          onChange={(event) =>
            setApiCredential({
              ...apiCredential,
              awsAccessKeyId: event.target.value,
            })
          }
        />
        <div className="mt-4 mb-1">AWS Secret Access Key</div>
        <Input
          value={apiCredential.awsSecretAccessKey}
          onChange={(event) =>
            setApiCredential({
              ...apiCredential,
              awsSecretAccessKey: event.target.value,
            })
          }
        />
      </div>
    );
  };

  const renderCheckstepCredential = (
    apiCredential: GQLCheckstepIntegrationApiCredential,
  ) => {
    // Spread to create a mutable copy
    const apiKeys = [...apiCredential.apiKeys];
    if (!apiKeys.length) {
      // Add a new empty credential pair if none exists
      apiKeys.push({
        __typename: 'CheckstepIntegrationApiCredentialPair',
        apiKey: '',
        platform: '',
      });
    }
    return (
      <div className="flex flex-col justify-start">
        {apiKeys.map(({ platform, apiKey }, index) => (
          <div className="flex items-center" key={index}>
            <div className="w-36">
              <Input
                value={platform}
                onChange={(event) => {
                  const newApiKeys = [...apiCredential.apiKeys];
                  newApiKeys.splice(index, 1, {
                    ...newApiKeys[index],
                    platform: event.target.value,
                  });
                  setApiCredential({
                    ...apiCredential,
                    apiKeys: newApiKeys,
                  });
                }}
              />
            </div>
            <div className="w-36">
              <Input
                value={apiKey}
                onChange={(event) => {
                  const newApiKeys = [...apiCredential.apiKeys];
                  newApiKeys.splice(index, 1, {
                    ...newApiKeys[index],
                    apiKey: event.target.value,
                  });
                  setApiCredential({
                    ...apiCredential,
                    apiKeys: newApiKeys,
                  });
                }}
              />
            </div>
            <div className="flex flex-row mt-1">
              <Button
                shape="circle"
                type="default"
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newApiKeys = [...apiCredential.apiKeys];
                  newApiKeys.splice(index, 1);
                  setApiCredential({
                    ...apiCredential,
                    apiKeys: newApiKeys,
                  });
                }}
              />
            </div>
          </div>
        ))}
        <div className="flex flex-row">
          <Button
            shape="circle"
            type="default"
            icon={<PlusOutlined />}
            onClick={() => {
              const newApiKeys = [...apiCredential.apiKeys];
              newApiKeys.push({
                __typename: 'CheckstepIntegrationApiCredentialPair',
                apiKey: '',
                platform: '',
              });
              setApiCredential({
                ...apiCredential,
                apiKeys: newApiKeys,
              });
            }}
          />
        </div>
      </div>
    );
  };

  const projectKeysInput = () => {
    switch (apiCredential.__typename) {
      case 'HiveIntegrationApiCredential':
        return renderHiveCredential(apiCredential);
      case 'SpectrumIntegrationApiCredential':
        return renderSpectrumCredential(apiCredential);
      case 'OpenAiIntegrationApiCredential':
        return renderOpenAiCredential(apiCredential);
      case 'AmazonRekognitionIntegrationApiCredential':
        return renderAmazonRekognitionCredential(apiCredential);
      case 'CheckstepIntegrationApiCredential':
        return renderCheckstepCredential(apiCredential);
      case 'PerspectiveIntegrationApiCredential':
        return renderPerspectiveCredential(apiCredential);
      default:
        throw new Error('Integration not implemented yet');
    }
  };

  return <div className="flex flex-col pb-4">{projectKeysInput()}</div>;
}

import {
  GQLUserRole,
  useGQLHasNcmecReportingEnabledQuery,
  useGQLInviteUserMutation,
} from '@/graphql/generated';
import { titleCaseEnumString } from '@/utils/string';
import { gql } from '@apollo/client';
import { useState } from 'react';

import CoveButton from '../dashboard/components/CoveButton';
import CoveInput from '../dashboard/components/CoveInput';
import CoveModal from '../dashboard/components/CoveModal';
import CoveRadioGroup from '../dashboard/components/CoveRadioGroup';
import FormSectionHeader from '../dashboard/components/FormSectionHeader';

import { getRoleDescription } from './ManageUsersFormUtils';

gql`
  query HasNcmecReportingEnabled {
    myOrg {
      hasNCMECReportingEnabled
    }
  }

  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input)
  }
`;

export default function ManageUsersInviteUserSection() {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<GQLUserRole | undefined>(undefined);
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [inviteSentModalVisible, setInviteSentModalVisible] = useState(false);

  const { data } = useGQLHasNcmecReportingEnabledQuery();
  const hasNCMECReportingEnabled = data?.myOrg?.hasNCMECReportingEnabled;

  const [inviteUser, { loading, error }] = useGQLInviteUserMutation({
    onError: () => setInviteSentModalVisible(true),
    onCompleted: () => setInviteSentModalVisible(true),
  });

  const rolesModal = (
    <CoveModal
      title="User Roles"
      visible={roleModalVisible}
      onClose={() => setRoleModalVisible(false)}
    >
      <div className="max-w-[600px]">
        {Object.values(GQLUserRole)
          // If the org doesn't have NCMEC reporting enabled, don't show the CHILD_SAFETY_MODERATOR role
          .filter((role) =>
            !hasNCMECReportingEnabled
              ? role !== GQLUserRole.ChildSafetyModerator
              : true,
          )
          .map((role, i) => (
            <div key={i}>
              <div className="text-lg font-bold">
                {titleCaseEnumString(role)}
              </div>
              <div className="h-1" />
              {getRoleDescription(role)}
              <div className="h-3" />
            </div>
          ))}
      </div>
    </CoveModal>
  );

  const inviteSentModal = (
    <CoveModal
      title="Invitation Sent"
      visible={inviteSentModalVisible}
      onClose={() => setInviteSentModalVisible(false)}
    >
      {error ? (
        'We encountered an issue trying to invite this user. Please try again.'
      ) : (
        <div>
          We have sent an invite link to{' '}
          <span className="font-semibold">{email}</span> to create a Cove
          account. The link will expire in 24 hours.
        </div>
      )}
    </CoveModal>
  );

  const onInviteUser = () => {
    inviteUser({
      variables: {
        input: { email: email!, role: role! },
      },
    });
  };

  return (
    <div className="flex flex-col items-start mb-8 text-start">
      <FormSectionHeader
        title="Invite Users"
        subtitle="Grant account access to members of your team. Enter their email address and select their Role."
      />
      <div className="w-2/5">
        <CoveInput
          type="email"
          placeholder="Email address"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mt-8 mb-4 text-base text-zinc-900">
        Select this new user's role. Roles determine what features they can
        access. Click
        <CoveButton
          title="here"
          type="link"
          onClick={() => setRoleModalVisible(true)}
        />
        to see which permissions each role has.
      </div>
      <div className="ml-4">
        <CoveRadioGroup
          options={Object.values(GQLUserRole)
            // If the org doesn't have NCMEC reporting enabled, don't show the CHILD_SAFETY_MODERATOR role
            .filter((role) =>
              !hasNCMECReportingEnabled
                ? role !== GQLUserRole.ChildSafetyModerator
                : true,
            )
            .map((role) => ({
              label: titleCaseEnumString(role),
              value: role,
            }))}
          onChange={(e) => setRole(e.target.value as GQLUserRole)}
        />
      </div>
      <div className="flex items-start mt-8">
        <CoveButton
          title="Send Invite Link"
          size="middle"
          onClick={onInviteUser}
          loading={loading}
          disabled={!email?.length || !role}
        />
      </div>
      {rolesModal}
      {inviteSentModal}
    </div>
  );
}

import HTMLRenderer from '@/webpages/dashboard/policies/HTMLRenderer';
import { Progress } from 'antd';

export default function TrainingPipelineLabelingProgressComponent(props: {
  currentIndex: number;
  total: number;
  policy?: {
    policyName: string;
    policyText: string;
  };
  size?: 'small' | 'large';
}) {
  const { currentIndex, total, policy, size = 'small' } = props;
  const policyName = policy?.policyName ?? '';
  const policyText = policy?.policyText ?? '';
  const progress = Math.round(100 * (currentIndex / total));

  const isSmall = size === 'small';
  const containerClass = isSmall ? 'max-w-sm' : 'max-w-sm w-96';
  const titleClass = isSmall ? 'text-base' : 'text-xl';
  const countClass = isSmall ? 'text-lg' : 'text-xl';
  const totalClass = isSmall ? 'text-sm' : 'text-base';
  const contentLabeledClass = isSmall
    ? 'text-slate-500'
    : 'text-slate-500 text-lg';

  return (
    <div
      className={`flex flex-col items-stretch ${containerClass} px-4 text-start bg-inherit`}
    >
     <div className="flex flex-row items-center justify-between">
       <div className={`font-semibold ${titleClass}`}>
         Model Training Progress
       </div>
     </div>
     <div className="flex flex-row justify-between">
       <div className="flex flex-row items-end pt-4 pb-2">
         <span className={`font-semibold ${countClass} text-[#0369A1]`}>
           {currentIndex}
           <span className={`p-px font-semibold ${totalClass} text-[#0C4A6E]`}>
             /{total}
           </span>
         </span>
       </div>
       <span className={`self-end pb-2 ${contentLabeledClass}`}>
         content labeled
       </span>
     </div>
     <Progress
       percent={progress}
       strokeColor={{ '0%': '#7e8af7', '100%': '#a25ef7' }}
       showInfo={false}
     />
     {policyText && (
       <div className="flex flex-col">
         <div className="self-stretch h-px my-6 bg-gray-200" />
         <div className="text-base font-bold">Your {policyName} Policy</div>
         <HTMLRenderer rawHTML={policyText ?? ''} />
       </div>
     )}
    </div>
  );
}

import { SuiteGrid } from '../../animations/landing/SuiteGrid';
import Counter from '../../Counter';
import { FeatureSection } from '../../sections/landing/FeatureSection';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

interface StatisticProps {
  statistic: number;
  after?: string;
  before?: string;
  start?: number;
  label: string;
}

function Statistic({ statistic, start, before, after, label }: StatisticProps) {
  return (
    <div className="flex min-w-32 flex-col items-start">
      <Typography
        color="primary"
        variant="display"
        className="text-3.5xl md:text-4.5xl lg:text-5.5xl text-start md:text-start w-full"
      >
        {before}
        <Counter value={statistic} start={start} />
        {after}
      </Typography>
      <Typography
        variant="body"
        color="primary"
        className="whitespace-normal md:whitespace-nowrap ml-1 font-bold text-sm md:text-base w-full text-start md:text-start"
      >
        {label}
      </Typography>
    </div>
  );
}

export function Suite(props: { showRequestDemoModal: () => void }) {
  return (
    <FeatureSection
      className="mb-8"
      label="Comprehensive & flexible"
      title="Your entire Trust & Safety workflow in one place"
      description="Choose from our suite of products and only pay for what you need. Add on new Cove products as your platform grows, and watch as each product improves the others."
      cta={
        <Button onClick={props.showRequestDemoModal}>
          Get started with Cove
        </Button>
      }
      extraGraphicAboveCta={
        <div className="hidden sm:flex flex-col sm:flex-row xl:gap-8 mb-10 items-start gap-4">
          <Statistic
            after="M+"
            statistic={100}
            label="daily content moderated"
          />

          <Statistic after="%" statistic={85} label="cost savings" />

          <Statistic
            after=" min"
            start={30}
            statistic={10}
            label="to create a custom AI model"
          />
        </div>
      }
    >
      <div className="w-full md:w-auto">
        <SuiteGrid />
      </div>
    </FeatureSection>
  );
}

import { Checkbox, Label } from '@/cove-ui';
import { useGQLOrgDataQuery } from '@/graphql/generated';
import { useState } from 'react';

export default function NotionUserCommsCheckboxes(props: {
  selectedActionIds: string[];
}) {
  const { selectedActionIds } = props;
  const [banUserChecked, setBanUserChecked] = useState(false);
  const [flagChecked, setFlagChecked] = useState(true);

  const { data } = useGQLOrgDataQuery();

  if (data?.myOrg && data.myOrg.id !== 'd259ca846a5') {
    // This should only render for Notion
    return null;
  }

  const notionUserCommsActions = {
    'Ban User': '87b7954110a',
    Flag: '37a204d03a7',
  };
  if (
    !selectedActionIds.some((id) =>
      Object.values(notionUserCommsActions).includes(id),
    )
  ) {
    return null;
  }
  return (
    <div className="flex flex-col gap-1 mt-2 mb-4">
      {selectedActionIds.includes(notionUserCommsActions['Ban User']) ? (
        <div className="flex gap-2 items-center">
          <Checkbox
            id="ban-user-checkbox"
            checked={banUserChecked}
            onCheckedChange={setBanUserChecked}
          />
          <Label htmlFor="ban-user-checkbox">Send "Ban User" user comms</Label>
        </div>
      ) : null}
      {selectedActionIds.includes(notionUserCommsActions['Flag']) ? (
        <div className="flex gap-2 items-center">
          <Checkbox
            id="flag-checkbox"
            checked={flagChecked}
            onCheckedChange={setFlagChecked}
          />
          <Label htmlFor="flag-checkbox">Send "Flag" user comms</Label>
        </div>
      ) : null}
    </div>
  );
}

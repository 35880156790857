export default function FormHeader(props: {
  title: string;
  subtitle?: string;
  topRightComponent?: React.ReactNode;
}) {
  const { title, subtitle, topRightComponent } = props;
  return (
    <div className="flex justify-between items-center text-start mb-8 w-full">
      <div className="flex flex-col justify-start">
        <div className="text-2xl font-bold mb-1">{title}</div>
        {subtitle && <div className="text-base text-slate-500">{subtitle}</div>}
      </div>
      {topRightComponent}
    </div>
  );
}

import { inView } from 'framer-motion';
import {
  useEffect,
  useRef,
  useState,
  type HTMLAttributes,
  type RefObject,
} from 'react';

import { cn } from '../../../../lib/utils';
import { Pill } from '../../../ui/Pill';

interface AnimatedPillProps {
  animate: boolean;
  children: string;
  parentRef: RefObject<HTMLDivElement>;
}

function AnimatedPill({ children, parentRef, animate }: AnimatedPillProps) {
  const [isColored, setIsColored] = useState(false);

  const color = children[0] === '+' ? 'primary' : 'accent';
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animate && ref.current && parentRef.current) {
      return inView(
        ref.current,
        () => {
          setIsColored(true);
          return () => setIsColored(false);
        },
        { amount: 'all', root: parentRef.current },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsColored, parentRef.current, ref.current, animate]);

  return (
    <Pill color={isColored ? color : 'default'} ref={ref}>
      {children}
    </Pill>
  );
}

interface AnimatedPillRowProps extends HTMLAttributes<HTMLDivElement> {
  pills: string[];
  highlightCells?: number[];
  parentRef: RefObject<HTMLDivElement>;
}

function AnimatedPillRow({
  className,
  highlightCells = [],
  parentRef,
  pills,
}: AnimatedPillRowProps) {
  return (
    <div className={cn('flex flex-row gap-4 relative w-min', className)}>
      {pills.map((pill, idx) => (
        <AnimatedPill
          parentRef={parentRef}
          animate={highlightCells.includes(idx)}
          key={pill}
        >
          {pill}
        </AnimatedPill>
      ))}
      <div className="flex flex-row gap-4 absolute translate-x-full -right-4 top-0 bottom-0">
        {pills.map((pill, idx) => (
          <AnimatedPill
            parentRef={parentRef}
            animate={highlightCells.includes(idx)}
            key={pill}
          >
            {pill}
          </AnimatedPill>
        ))}
      </div>
    </div>
  );
}

export function AnimatedPills() {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={cn(
        'flex flex-col overflow-hidden gap-4 w-[22rem] opacity-70',
        'mask-[linear-gradient(90deg,transparent_0%,black_20%,black_80%,transparent_100%)]',
      )}
    >
      <AnimatedPillRow
        parentRef={ref}
        highlightCells={[1, 3]}
        pills={['Hate', 'Violence', 'Harrassment', 'Sexual conduct']}
        className="animate-carousel-left"
      />

      <AnimatedPillRow
        parentRef={ref}
        highlightCells={[2, 0]}
        pills={['Drug sales', 'Spam', 'Custom: counterfeit', 'Piracy']}
        className="animate-carousel-right"
      />

      <AnimatedPillRow
        parentRef={ref}
        pills={[
          'Terrorism',
          'Sexual exploitation',
          '+ Create Custom',
          'Assault',
        ]}
        highlightCells={[2, 0]}
        className="animate-carousel-left"
      />
    </div>
  );
}

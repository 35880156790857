import React from 'react';

function ContentIcon(props) {
  const fill = props.fill || 'currentColor';
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'layers alt 1';

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={fill}>
        <path d="M59.4 37.9l-3.9-2.4c-1-.7-2.4-.3-3.1.7-.7 1.1-.3 2.4.7 3.1l2.6 1.7L32 55.9 8.2 41l2.6-1.7c1.1-.7 1.4-2.1.7-3.1-.7-1.1-2.1-1.4-3.1-.7l-3.9 2.4c-1 .7-1.7 1.8-1.7 3s.6 2.4 1.7 3L30.1 60c.6.4 1.2.5 1.9.5s1.3-.2 1.9-.5l25.5-16c1-.7 1.7-1.8 1.7-3s-.6-2.4-1.7-3.1z" />
        <path d="M35.5 36.1L32 38.2 28.5 36c-1.1-.7-2.4-.3-3.1.7-.7 1.1-.3 2.4.7 3.1l4.7 2.9c.4.2.8.3 1.2.3s.8-.1 1.2-.3l4.7-2.9c1.1-.7 1.4-2.1.7-3.1s-2.1-1.3-3.1-.6z" />
        <path d="M13.5 31.9l6.6 4.2c.4.2.8.3 1.2.3.7 0 1.5-.4 1.9-1.1.7-1.1.3-2.4-.7-3.1L15.9 28c-1.1-.7-2.4-.3-3.1.7-.7 1.2-.4 2.6.7 3.2z" />
        <path d="M8.7 28.5c.7 0 1.5-.4 1.9-1.1.7-1.1.3-2.4-.7-3.1l-1.6-1 1.6-1c1.1-.7 1.4-2.1.7-3.1-.7-1.1-2.1-1.4-3.1-.7l-4.7 2.9c-.7.4-1.1 1.1-1.1 1.9s.4 1.5 1.1 1.9l4.7 2.9c.3.3.7.4 1.2.4z" />
        <path d="M14.7 18.8c.4 0 .8-.1 1.2-.3l6.6-4.2c1.1-.7 1.4-2.1.7-3.1-.7-1.1-2.1-1.4-3.1-.7l-6.6 4.2c-1.1.7-1.4 2.1-.7 3.1.4.6 1.1 1 1.9 1z" />
        <path d="M28.5 10.5L32 8.3l3.5 2.2c.4.2.8.3 1.2.3.7 0 1.5-.4 1.9-1.1.7-1.1.3-2.4-.7-3.1l-4.7-2.9c-.7-.5-1.7-.5-2.4 0l-4.7 2.9c-1.1.7-1.4 2.1-.7 3.1s2.1 1.5 3.1.8z" />
        <path d="M41.5 14.3l6.6 4.2c.4.2.8.3 1.2.3.7 0 1.5-.4 1.9-1.1.7-1.1.3-2.4-.7-3.1l-6.6-4.2c-1-.7-2.4-.3-3.1.7-.7 1.1-.4 2.5.7 3.2z" />
        <path d="M61.2 21.4l-4.7-2.9c-1-.7-2.4-.3-3.1.7-.7 1.1-.3 2.4.7 3.1l1.6 1-1.6 1c-1.1.7-1.4 2.1-.7 3.1.4.7 1.2 1.1 1.9 1.1.4 0 .8-.1 1.2-.3l4.7-2.9c.7-.4 1.1-1.1 1.1-1.9s-.4-1.6-1.1-2z" />
        <path d="M42.7 36.4c.4 0 .8-.1 1.2-.3l6.6-4.2c1.1-.7 1.4-2.1.7-3.1-.7-1.1-2.1-1.4-3.1-.7l-6.6 4.2c-1.1.7-1.4 2.1-.7 3.1.4.7 1.1 1 1.9 1z" />
      </g>
    </svg>
  );
}

export default ContentIcon;

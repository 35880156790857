import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import FullScreenLoading from '../../../../components/common/FullScreenLoading';
import CoveButton from '../../components/CoveButton';
import NavHeader from '../../components/NavHeader';
import TabBar from '../../components/TabBar';

import { useGQLRuleInfoQuery } from '../../../../graphql/generated';
import useRouteQueryParams from '../../../../routing/useRouteQueryParams';
import RuleBacktest from './backtest/RuleBacktest';
import RuleInsights from './insights/RuleInsights';
import RuleRetroaction from './retroaction/RuleRetroaction';
import RuleTestModal from './RuleTestModal';

enum RuleInfoTab {
  INSIGHTS = 'insights',
  BACKTEST = 'backtest',
  RETROACTION = 'retroaction',
}

gql`
  query RuleInfo($id: ID!) {
    me {
      permissions
    }
    rule(id: $id) {
      name
    }
  }
`;

export default function RuleInfo() {
  const { id: ruleId } = useParams<{ id: string | undefined }>();
  const { loading, error, data } = useGQLRuleInfoQuery({
    variables: { id: ruleId! },
    skip: ruleId === undefined,
  });
  const queryParams = useRouteQueryParams();
  const navigate = useNavigate();

  let initialActiveTab = RuleInfoTab.INSIGHTS;
  // Typescript doesn't allow an easy way to coerce a string to an enum
  if (
    Object.values(RuleInfoTab).some(
      (tab) => tab === queryParams.get('active_tab'),
    )
  ) {
    initialActiveTab = queryParams.get('active_tab') as RuleInfoTab;
  }
  const [activeTab, setActiveTab] = useState<RuleInfoTab>(initialActiveTab);
  const [testModalOpen, setTestModalOpen] = useState(false);

  if (error) {
    throw error;
  }
  if (!ruleId) {
    throw new Error('Rule ID is required');
  }
  if (loading) {
    return <FullScreenLoading />;
  }

  const name = data?.rule?.name;
  // TODO: Update to enable as needed once we have backtests and retroaction complete
  const canRunBacktests = false;
  const canRunRetroaction = false;

  const onChangeTab = (key: string) => {
    setActiveTab(key as RuleInfoTab);
  };

  const activeTabTitle = (() => {
    switch (activeTab) {
      case RuleInfoTab.INSIGHTS:
        return 'Insights';
      case RuleInfoTab.BACKTEST:
        return 'Backtest';
      case RuleInfoTab.RETROACTION:
        return 'Retroaction';
    }
  })();

  return (
    <div className="flex flex-col justify-start">
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <NavHeader
          buttons={[
            {
              title: 'Rules',
              onClick: () => navigate('/dashboard/rules/proactive'),
            },
            {
              title: `Rule: ${name}`,
              onClick: () =>
                navigate(`/dashboard/rules/proactive/form/${ruleId}`),
            },
            { title: activeTabTitle },
          ]}
        />
        <div className="flex gap-2">
          <CoveButton
            title="Edit Rule"
            destination={`/dashboard/rules/proactive/form/${ruleId}`}
          />
          <CoveButton
            title="Test Rule"
            type="secondary"
            onClick={() => setTestModalOpen(true)}
          />
        </div>
      </div>
      <TabBar
        tabs={[
          {
            label: 'Insights',
            value: RuleInfoTab.INSIGHTS,
          },
          {
            label: 'Backtest',
            value: RuleInfoTab.BACKTEST,
            tooltip: canRunBacktests ? undefined : 'Backtests are coming soon!',
            disabled: !canRunBacktests,
          },
          {
            label: 'Retroaction',
            value: RuleInfoTab.RETROACTION,
            tooltip: canRunRetroaction
              ? undefined
              : 'Retroaction is coming soon!',
            disabled: !canRunRetroaction,
          },
        ]}
        initialSelectedTab={activeTab}
        onTabClick={onChangeTab}
        currentSelectedTab={activeTab}
      />
      {activeTab === RuleInfoTab.INSIGHTS && <RuleInsights ruleId={ruleId} />}
      {activeTab === RuleInfoTab.BACKTEST && <RuleBacktest />}
      {activeTab === RuleInfoTab.RETROACTION && (
        <RuleRetroaction ruleId={ruleId} />
      )}
      {testModalOpen && (
        <RuleTestModal
          ruleId={ruleId}
          onClose={() => setTestModalOpen(false)}
        />
      )}
    </div>
  );
}

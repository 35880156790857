import { gql } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import DashboardHeader from '../components/DashboardHeader';
import ProtegoDatePicker from '../components/ProtegoDatePicker';
import TabBar from '../components/TabBar';

import {
  useGQLGetAverageTimeToReviewQuery,
  useGQLManualReviewMetricsQuery,
} from '../../../graphql/generated';
import {
  getEarliestDateWithLookback,
  LookbackLength,
  WEEK,
} from '../../../utils/time';
import { TimeWindow } from '../rules/dashboard/visualization/RulesDashboardInsights';
import ManualReviewCustomCharts from './visualization/ManualReviewCustomCharts';
import ManualReviewDefaultCharts from './visualization/ManualReviewDefaultCharts';

gql`
  query ManualReviewMetrics {
    getTotalPendingJobsCount
    reportingInsights {
      totalIngestedReportsByDay {
        date
        count
      }
    }
  }
  query getAverageTimeToReview($input: TimeToActionInput!) {
    getTimeToAction(input: $input) {
      timeToAction
      queueId
    }
  }
`;

type ManualReviewAnalyticsDashboardTab = 'home' | 'custom';

export default function ManualReviewAnalyticsDashboard() {
  const [lookback, setLookback] = useState(LookbackLength.ONE_WEEK);
  const [customTimeWindow, setCustomTimeWindow] = useState({
    start: new Date(Date.now() - WEEK),
    end: new Date(),
  });
  const [activeTab, setActiveTab] =
    useState<ManualReviewAnalyticsDashboardTab>('home');

  const { loading, data } = useGQLManualReviewMetricsQuery();

  const getDataInTimeWindow = <
    T extends { readonly date: string | Date; count: number },
  >(
    arr: readonly T[] | null | undefined,
    timeWindow: TimeWindow,
  ) => {
    return arr?.filter((elemWithDate) => {
      const time = new Date(elemWithDate.date).getTime();
      return (
        time > timeWindow.start.getTime() && time < timeWindow.end.getTime()
      );
    });
  };

  const timeWindow = useMemo(() => {
    if (lookback !== LookbackLength.CUSTOM) {
      //get current time truncated to hour
      const oldestDate = getEarliestDateWithLookback(lookback);
      const oldestHour = moment.utc(oldestDate).startOf('hour').toDate();
      return {
        start: oldestHour,
        end: new Date(),
      };
    } else {
      return customTimeWindow;
    }
  }, [lookback, customTimeWindow]);

  const getTotalIngestedReportsInTimeWindow = (window: TimeWindow) => {
    const ingestedReportsInLookbackByDay = getDataInTimeWindow(
      data?.reportingInsights.totalIngestedReportsByDay,
      window,
    );
    return ingestedReportsInLookbackByDay
      ? _.sum(ingestedReportsInLookbackByDay.map((it) => it.count))
      : undefined;
  };

  const previousTimeWindow = {
    start: new Date(
      timeWindow.start.getTime() -
        (timeWindow.end.getTime() - timeWindow.start.getTime()),
    ),
    end: new Date(
      timeWindow.end.getTime() -
        (timeWindow.end.getTime() - timeWindow.start.getTime()),
    ),
  };
  const totalIngestedReportsInLookback =
    getTotalIngestedReportsInTimeWindow(timeWindow);
  const totalIngestedReportsInPreviousLookback =
    getTotalIngestedReportsInTimeWindow(previousTimeWindow);

  const { loading: timeToActionLoading, data: timeToActionData } =
    useGQLGetAverageTimeToReviewQuery({
      variables: {
        input: {
          groupBy: [],
          filterBy: {
            startDate: timeWindow.start,
            endDate: timeWindow.end,
            itemTypeIds: [],
            queueIds: [],
          },
        },
      },
    });
  const {
    loading: previousTimeToActionLoading,
    data: previousTimeToActionData,
  } = useGQLGetAverageTimeToReviewQuery({
    variables: {
      input: {
        groupBy: [],
        filterBy: {
          startDate: previousTimeWindow.start,
          endDate: previousTimeWindow.end,
          itemTypeIds: [],
          queueIds: [],
        },
      },
    },
  });

  const currentPeriodTimeToAction =
    timeToActionData?.getTimeToAction?.[0].timeToAction ?? 0;
  const previousPeriodTimeToAction =
    previousTimeToActionData?.getTimeToAction?.[0].timeToAction ?? 0;

  return (
    <div>
      <Helmet>
        <title>Manual Review Analytics</title>
      </Helmet>
      <DashboardHeader
        title="Manual Review Analytics"
        subtitle="Track user reports and monitor your moderators' decisions."
        rightComponent={
          <div className="flex items-center gap-4">
            <div className="font-semibold text-slate-500">Date Range</div>
            <ProtegoDatePicker
              selectedLookback={lookback}
              customTimeWindow={customTimeWindow}
              updateLookback={(lookback: LookbackLength) =>
                setLookback(lookback)
              }
              updateCustomTimeWindow={(timeWindow: TimeWindow) =>
                setCustomTimeWindow(timeWindow)
              }
              floatDirection="right"
            />
          </div>
        }
      />
      <TabBar<ManualReviewAnalyticsDashboardTab>
        tabs={[
          { label: 'Home', value: 'home' },
          { label: 'My Custom Dashboard', value: 'custom' },
        ]}
        initialSelectedTab={'home'}
        onTabClick={setActiveTab}
      />
      {activeTab === 'home' ? (
        <ManualReviewDefaultCharts
          lookback={lookback}
          timeWindow={timeWindow}
          loading={
            loading || timeToActionLoading || previousTimeToActionLoading
          }
          averageTimeToReviewInLookback={currentPeriodTimeToAction ?? 0}
          averageTimeToReviewInPreviousLookback={
            previousPeriodTimeToAction ?? 0
          }
          totalIngestedReportsInLookback={totalIngestedReportsInLookback}
          totalIngestedReportsInPreviousLookback={
            totalIngestedReportsInPreviousLookback
          }
          currentlyOpenJobs={data?.getTotalPendingJobsCount ?? 0}
        />
      ) : (
        <ManualReviewCustomCharts lookback={lookback} timeWindow={timeWindow} />
      )}
    </div>
  );
}

import { useState } from 'react';

import RequestDemoModal from '../../RequestDemoModal';
import { Button } from '../../ui/Button';
import { FeatureSection } from '../landing/FeatureSection';

export function CTA() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <>
      <FeatureSection
        title="Have more questions?"
        orientation="ttb"
        description="We have answers! Schedule a demo to chat with someone on our team to see how Cove can be a fit for your team."
        cta={
          <Button className="mt-6 sm:mt-0" onClick={showRequestDemoModal}>
            Schedule a demo
          </Button>
        }
        gap={false}
      />
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="CTA"
      />
    </>
  );
}

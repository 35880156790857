import { m } from 'framer-motion';
import { useState } from 'react';

import { NoCode as NoCodeGraphic } from '../../animations/ai/NoCode';
import RequestDemoModal from '../../RequestDemoModal';
import { Button } from '../../ui/Button';
import { FeatureSection } from '../landing/FeatureSection';

export function NoCode() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <FeatureSection
      label="Fully customizable"
      title="Create proprietary models without any coding or technical expertise"
      description="Our no-code, flexible UI lets you create a fully custom models that are optimized for your platform. We guide you through teaching the model how to enforce your policies in the context of your product."
      cta={
        <Button onClick={showRequestDemoModal}>Get started with Cove</Button>
      }
    >
      <m.div
        className="w-full"
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <NoCodeGraphic />
      </m.div>
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="NO_CODE"
      />
    </FeatureSection>
  );
}

import Markdown from 'react-markdown';

import Authors from '../authors';
import type { BlogPostConfig } from './types';

const markdown = `We are Cove, a new company using artificial intelligence to build the safety layer for the web. We’re incredibly excited to share what we’ve been building for the past year and a half.

With the safety risks of recent astounding advancements in AI, and with the erosion of trust in civic institutions around the world, it’s more critical than ever that we build safety into the core of our online ecosystems.We built Cove to do exactly that.

From 2015-2021, our team built systems at Meta to prevent the spread of election interference, disinformation campaigns, hate, violence, and harassment. We were deeply inspired by the drive and integrity of the people we worked with, the urgency of the problems we worked on, and impact of the solutions we collectively created. We learned from our experiences and decided to build a platform that leverages cutting-edge AI to help businesses of all sizes protect their users.

We’ve condensed the task of Trust & Safety down to its simplest forms: natural language and manual labeling. You give us your platform’s policies, we walk you through a guided labeling workflow, and then we’ll turn your policies and labels into a robust, AI-powered Trust & Safety solution designed specifically for your platform. After a simple integration, you won’t need to write a single line of code again. We process more than 1.5 billion pieces of content monthly and have helped customers reduce manual moderation costs by 80 percent.

We are backed by Thrive Capital, Sound Ventures, Common Sense Growth, Neo, Elad Gil, Scott Forstall and angels from Stripe, Figma, Notion, Dropbox, Retool, and Vanta, who invested over $5.8 million to our seed round so we can build products that enable companies to deploy world class safety tools, tailored to their specific users.

**The problems that all AI platforms face**

Today, every online platform needs robust content safety tools, but beyond giant social networks, few companies have the resources or expertise to deploy world class solutions. From dating apps and gaming services, to marketplaces and generative AI products, today’s winning and emerging platforms are social and content driven by default. Safety must be treated as a core feature to ensure platforms can protect their users from harm, improve user experience, ensure regulatory compliance, and build trust.

Deploying world class trust and safety solutions is a challenging task for several reasons:

1. **Engineering costs:** Deploying and maintaining trust and safety services at scale requires significant engineering and technical headcount investments
2. **Operational costs:** Beyond engineering investments, platforms need to hire teams of moderators to manually review content, operations teams to manage that workflow, and policy teams to create standards for what content is allowed.
3. **Human costs:** Reviewing hate speech, violence, harassment, and other harmful content puts a heavy psychological burden on moderators.
4. **Compliance costs:** Platform regulations like the Digital Services Act, Online Safety Bill, COPPA, and 18 U.S.C. §2258A imposed onerous requirements on platforms that necessitate meaningful legal and engineering investments.
5. **Change and maintenance costs:** Conversations on social platforms change in real-time. Each time a feature changes or conversations meaningfully shift, safety solutions need to be updated, triggering additional engineering requirements.

Cove harnesses AI to enable companies of any size to build world class safety solutions without writing code. We empower customers to create custom solutions tailored to their platforms, without the technical, operational, or financial burdens that make trust and safety so difficult today.

**The solution, accessible and customizable**

Trust and safety solutions have to be carefully tailored to each platform to be effective. Messages in a dating app are very different from product reviews on a fashion website or conversations in a gaming livestream, and users in different parts of the world have their own cultural norms and expectations. We enable our customers to build custom AI models, trained on their own data, that are optimized for their specific platform’s needs and users.

Growing companies can use our two no-code products: Cove AI and Cove Platform.

- **Cove AI** enables customers to use plain English descriptions to train machine learning models on their platform’s own data. The ML models can then help the customer detect content that violates their policies, and we use active learning to improve the models on an ongoing basis.
- **Cove Platform** provides the additional tooling required to build a robust, automated trust and safety solution around the ML models. This includes a logic-based rules interface, a content review platform that feeds moderator decisions back into the customers’ ML models, and tooling for measurement, experimentation, investigations, and compliance.

Cove Platform supports text, audio, image, and video content, while Cove AI currently supports text and audio. We’re working to provide AI support for images and videos soon.

**What's next**

We are excited to have the support of leading investors in the tech ecosystem on our journey. We're using the proceeds from our seed round to invest in scaling our infrastructure, building out the team (we're hiring!), and continuing to support our growing list of customers.

Our goal is to leverage AI and large language models to provide a universal safety layer for the web. We want to ensure that the Internet fulfills its promise of enabling the best in humanity without giving voice to the worst of it.`;

const SafetyLayerBlogPost = {
  slug: 'safety-layer',
  title: 'Cove, an AI-powered safety layer for the web',
  date: new Date('2023-06-12T00:00:00-07:00'),
  author: Authors['michael'],
  coverPhoto: '/graphics/safety-layer.png',
  content: <Markdown>{markdown}</Markdown>,
} satisfies BlogPostConfig;

export default SafetyLayerBlogPost;

import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import type { RefObject } from 'react';

import { cn } from '../../lib/utils';

const buttonVariants = cva(
  'cursor-pointer inline active:scale-[0.99] text-center whitespace-nowrap rounded-lg font-bold transition-all duration-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:pointer-events-none disabled:opacity-50 min-w-[130px]',
  {
    variants: {
      variant: {
        filled:
          'border-none bg-primary text-inverted hover:text-inverted hover:opacity-80 active:opacity-70',
        outlined:
          'border border-solid bg-white border-primary text-primary hover:bg-primary hover:text-inverted active:opacity-50',
        link: 'border-none bg-transparent text-primary hover:text-primary hover:opacity-75 active:opacity-50',
      },
      size: {
        sm: 'py-2.5 px-5.5 text-sm',
        md: 'py-[1.125rem] px-6',
        lg: 'py-4 px-8',
      },
    },
    compoundVariants: [
      {
        variant: 'link',
        size: 'sm',
        className: 'text-base',
      },
      {
        variant: 'link',
        size: 'md',
        className: 'text-lg',
      },
      {
        variant: 'link',
        size: 'lg',
        className: 'text-xl',
      },
    ],
    defaultVariants: {
      variant: 'filled',
      size: 'md',
    },
  },
);

type AsButton = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: 'button';
};

type AsLink = React.AnchorHTMLAttributes<HTMLAnchorElement> & { as: 'a' };

export type ButtonProps = VariantProps<typeof buttonVariants> &
  (AsButton | AsLink);

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(({ className, variant, children, size, as, ...props }, ref) => {
  const Comp = as === 'a' ? 'a' : 'button';

  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref as RefObject<HTMLButtonElement> & RefObject<HTMLAnchorElement>}
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      {...(props as any)}
    >
      <span className="inline-flex gap-2 flex-row items-center justify-center">
        {children}
      </span>
    </Comp>
  );
});

Button.displayName = 'Button';

Button.defaultProps = {
  as: 'button',
};

export { Button, buttonVariants };

import { useEffect, useRef, useState } from 'react';

import { DefaultPolicies } from '../Demo';
import DemoFooter from '../DemoFooter';

export default function DemoSelectPoliciesScreen(props: {
  goToNextScreen: () => void;
  goBack: () => void;
  selectedPolicies: string[];
  saveSelectedPolicies: (policies: string[]) => void;
}) {
  const { goToNextScreen, goBack, selectedPolicies, saveSelectedPolicies } =
    props;
  const [allPolicies, setAllPolicies] = useState<string[]>(DefaultPolicies);
  const [newCustomPolicy, setNewCustomPolicy] = useState<string | undefined>(
    undefined,
  );
  const [newCustomPolicyPillVisible, setNewCustomPolicyPillVisible] =
    useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleSelected = (policyName: string) => {
    if (selectedPolicies.includes(policyName)) {
      saveSelectedPolicies(selectedPolicies.filter((it) => it !== policyName));
    } else {
      saveSelectedPolicies([...selectedPolicies, policyName]);
    }
  };

  useEffect(() => {
    if (newCustomPolicyPillVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [newCustomPolicyPillVisible]);

  const getPolicyPill = (policyName: string) => {
    return (
      <div
        className={`px-6 border-solid border rounded-full inline-block mr-3 mb-3 cursor-pointer text-lg py-2 ${
          selectedPolicies.includes(policyName)
            ? 'text-white border-cove-blue bg-cove-blue/75'
            : 'text-slate-500 border-slate-200 hover:bg-cove-blue/20'
        }`}
        onClick={() => toggleSelected(policyName)}
      >
        {policyName}
      </div>
    );
  };

  const addNewCustomPolicy = () => {
    if (newCustomPolicy) {
      if (!allPolicies.includes(newCustomPolicy)) {
        setAllPolicies([...allPolicies, newCustomPolicy]);
        toggleSelected(newCustomPolicy);
      }
      setNewCustomPolicy(undefined);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
    setNewCustomPolicyPillVisible(false);
  };
  const placeholder = 'My custom policy';

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-start justify-center h-full max-w-3xl">
        <div className="pb-3 text-3xl font-bold">
          Which policies do you want to enforce?
        </div>
        <div className="pb-6 text-base text-start text-slate-500">
          For each policy, we'll help you create customized AI to enforce it. If
          you want to enforce a policy that isn't listed below, click “Create
          custom policy”.
        </div>
        <div className="block w-full gap-6 text-start">
          {allPolicies.map((policy) => getPolicyPill(policy))}
          <input
            ref={inputRef}
            className={`px-6 border-solid border rounded-full inline-block mr-3 mb-3 cursor-text text-lg py-2 text-slate-500 border-slate-200 focus:border-slate-200 outline-none placeholder-slate-300 ${
              newCustomPolicyPillVisible
                ? 'visible w-auto h-auto'
                : 'invisible w-0 h-0'
            }`}
            type="text"
            placeholder={placeholder}
            onChange={(event) =>
              setNewCustomPolicy(
                event.target.value.length ? event.target.value : undefined,
              )
            }
            value={newCustomPolicy}
            size={
              newCustomPolicy
                ? newCustomPolicy.length
                : // Subtract 2 because for some reason the <input> tag adds some padding on the right
                  placeholder.length - 2
            }
            onBlur={addNewCustomPolicy}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                addNewCustomPolicy();
              }
            }}
          />
        </div>
        <div
          className="px-6 inline-block cursor-pointer mt-2 py-1.5 border-none pl-0 text-base font-semibold text-cove-blue hover:text-cove-blue-hover"
          onClick={() => {
            setNewCustomPolicyPillVisible(true);
          }}
        >
          + Create custom policy
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Continue',
          onClick: goToNextScreen,
          disabled: selectedPolicies.length === 0,
        }}
        onBack={goBack}
      />
    </div>
  );
}

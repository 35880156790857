import { CalendarOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';

import { LookbackLength } from '../../../utils/time';
import { TimeWindow } from '../rules/dashboard/visualization/RulesDashboardInsights';

function lookbackToDisplayString(
  lookback: LookbackLength,
  customTimeWindow: TimeWindow,
) {
  switch (lookback) {
    case LookbackLength.CUSTOM:
      return `Custom: ${moment(customTimeWindow.start).format(
        'MM/DD',
      )} - ${moment(customTimeWindow.end).format('MM/DD')}`;
    case LookbackLength.ONE_DAY:
      return `Last day`;
    case LookbackLength.THREE_DAYS:
      return `Last three days`;
    case LookbackLength.ONE_WEEK:
      return `Last week`;
    case LookbackLength.ONE_MONTH:
      return `Last month`;
    case LookbackLength.THREE_MONTHS:
      return `Last three months`;
    case LookbackLength.SIX_MONTHS:
      return `Last six months`;
    case LookbackLength.ONE_YEAR:
      return `Last year`;
  }
}

export default function ProtegoDatePicker(props: {
  selectedLookback: LookbackLength;
  customTimeWindow: TimeWindow;
  updateLookback: (lookback: LookbackLength) => void;
  updateCustomTimeWindow: (timeWindow: TimeWindow) => void;
  floatDirection?: 'right' | 'left';
}) {
  const {
    customTimeWindow,
    selectedLookback,
    updateLookback,
    updateCustomTimeWindow,
    floatDirection = 'left',
  } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const [customInputVisible, setCustomInputVisible] = useState(false);

  return (
    <div className="relative inline-block float-left text-start">
      <Button
        className="font-medium text-black border border-gray-300 border-solid rounded-lg"
        onClick={() => setMenuVisible(!menuVisible)}
      >
        {lookbackToDisplayString(selectedLookback, customTimeWindow)}
        {menuVisible ? (
          <UpOutlined className="text-xs align-middle" />
        ) : (
          <DownOutlined className="text-xs align-middle" />
        )}
      </Button>
      {menuVisible && (
        <div
          className={`flex flex-col absolute bg-white rounded-lg p-2 mt-1 shadow z-10 ${
            floatDirection === 'right' ? 'right-0' : ''
          }`}
        >
          <div className="flex gap-2">
            {Object.values(LookbackLength).map((length, i) => (
              <Button
                className={`${
                  selectedLookback === length
                    ? 'bg-gray-200 text-gray-700'
                    : 'bg-white text-gray-500'
                } border border-gray-300 first:rounded-l-lg last:rounded-r-lg first:ml-0 last:mr-0 hover:bg-gray-100`}
                key={i}
                value={length}
                icon={
                  length === LookbackLength.CUSTOM ? (
                    <CalendarOutlined />
                  ) : undefined
                }
                onClick={() => {
                  setCustomInputVisible(length === LookbackLength.CUSTOM);
                  if (length !== LookbackLength.CUSTOM) {
                    setMenuVisible(false);
                  }
                  updateLookback(length);
                }}
              >
                {length}
              </Button>
            ))}
          </div>
          {customInputVisible && (
            <div className="flex flex-row items-center gap-2 mt-2">
              <DatePicker
                defaultValue={moment(customTimeWindow.start)}
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                onOk={(value) =>
                  updateCustomTimeWindow({
                    ...customTimeWindow,
                    start: value.toDate(),
                  })
                }
              />
              to
              <DatePicker
                defaultValue={moment(customTimeWindow.end)}
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                onOk={(value) =>
                  updateCustomTimeWindow({
                    ...customTimeWindow,
                    end: value.toDate(),
                  })
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

import { m } from 'framer-motion';

export function Routing() {
  return (
    <div className="flex flex-col gap-0 w-full max-w-3xl">
      <m.img
        className="w-full z-10"
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        src="/graphics/routing-1.png"
      />
      <m.img
        className="w-full -mt-8 sm:-mt-12"
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 1 }}
        src="/graphics/routing-2.png"
      />
    </div>
  );
}

import { useGQLStartCoveModelSignalCreationSessionMutation } from '@/graphql/generated';
import { Image, MusicNote, TextAlignLeft, VideoCameraAlt2 } from '@/icons';
import { __throw } from '@/utils/misc';
import _ from 'lodash';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CoveButton from '@/webpages/dashboard/components/CoveButton';

import { useGoToScreen, type TrainingPipelineEnabledScreen } from './types';

const ModalityTypes = ['text', 'image', 'video', 'audio'];
type ModalityType = (typeof ModalityTypes)[number];

function ModalityOption(props: {
  modality: ModalityType;
  selected: boolean;
  onSelect: () => void;
  disabled?: boolean;
}) {
  const { modality, selected, onSelect, disabled = false } = props;

  const iconStyles = `w-6 h-6 ${selected ? 'fill-primary' : 'fill-gray-200'}`;
  const icon = (() => {
    switch (modality) {
      case 'text':
        return <TextAlignLeft className={iconStyles} />;
      case 'image':
        return <Image className={iconStyles} />;
      case 'video':
        return <VideoCameraAlt2 className={iconStyles} />;
      case 'audio':
        return <MusicNote className={iconStyles} />;
    }
  })();

  return (
    <div
      className={`flex flex-row items-center justify-between gap-8 p-4 border border-solid rounded-md ${
        selected ? 'border-primary' : 'border-gray-200'
      } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={disabled ? undefined : onSelect}
    >
     <div className="flex flex-col">
       <div className="flex flex-row items-center gap-2">
         {!disabled && (
           <input
             disabled={disabled}
             type="checkbox"
             checked={selected}
             onChange={onSelect}
           />
         )}
         <div className="text-lg">{_.capitalize(modality)}</div>
       </div>
       <div className="text-primary">{disabled ? 'Coming soon' : ''}</div>
     </div>
     {icon}
    </div>
  );
}

export default function TrainingPipelineModelModalitySelection(props: {
  nextScreen: TrainingPipelineEnabledScreen;
}) {
  const { nextScreen } = props;
  const [selectedModalities, setSelectedModalities] = useState<ModalityType[]>(
    [],
  );
  const [searchParams] = useSearchParams();
  const policyId =
    searchParams.get('policyId') ?? __throw('policyId is required');

  const goToScreen = useGoToScreen();

  const [createModelCreationSession, { loading: createSessionLoading }] =
    useGQLStartCoveModelSignalCreationSessionMutation();

  const startTrainingInput = (() => {
    const policyIdentifier = { id: policyId };
    if (selectedModalities.length === 1) {
      if (selectedModalities[0] === 'text') {
        return { textModelV1: { policyIdentifier } };
      } else if (selectedModalities[0] === 'image') {
        return { imageModelV1: { policyIdentifier } };
      }
    }

    return { textImageAndVideoModelV1: { policyIdentifier } };
  })();

  return (
    <div className="flex flex-col gap-4 mx-12">
      <div className="text-lg font-semibold">
        Which type(s) of data do you want your model to run on?
      </div>
      <div>
        Select all that apply. We'll help you train your model to run on all the
        types of content you select.
      </div>
      <div className="flex flex-col gap-4">
        {ModalityTypes.filter((it) => it !== 'audio').map((modality) => (
          <ModalityOption
            key={modality}
            modality={modality}
            selected={selectedModalities.includes(modality)}
            onSelect={() =>
              setSelectedModalities((prev) => {
                if (prev.includes(modality)) {
                  return prev.filter((it) => it !== modality);
                }
                return [...prev, modality];
              })
            }
            disabled={modality === 'video' || modality === 'image'}
          />
        ))}
      </div>
      <div className="self-end">
        <CoveButton
          title="Next: Start Labeling"
          onClick={() => {
            createModelCreationSession({
              variables: {
                input: startTrainingInput,
              },
              onCompleted(data) {
                goToScreen(
                  nextScreen,
                  policyId,
                  data.startCoveModelSignalCreationSession.sessionId,
                );
              },
            });
          }}
          loading={createSessionLoading}
          disabled={selectedModalities.length === 0}
        />
      </div>
    </div>
  );
}

import Markdown from 'react-markdown';

import Authors from '../authors';
import type { BlogPostConfig } from './types';

const markdown = `
We're thrilled to announce that Cove has successfully achieved SOC 2 Type II certification. At Cove, we strive to maintain the highest security standards and understand the crucial role that trust plays in our customers' decisions to use our platform. This certification is a significant step in maintaining those standards.

The SOC 2 framework, created by American Institute of Certified Public Accountants (AICPA), establishes stringent standards for data security and controls. Unlike the snapshot provided by a SOC 2 Type I report, Type II certification assesses the effectiveness of our controls over an extended period of time. Successfully completing this rigorous audit demonstrates Cove's commitment to robust security policies and practices on an ongoing basis.

At Cove, we are dedicated to developing cutting-edge Trust & Safety tools for teams of all sizes. A critical part of Trust & Safety is data security, and achieving SOC 2 Type II certification further solidifies our commitment to safeguarding customer data.

To learn more about Cove's security practices or to request a copy of our SOC 2 Type II report, please contact us at [support@getcove.com](mailto:support@getcove.com).
`;

const Soc2Type2BlogPost = {
  slug: 'soc-2-type-2',
  title: 'SOC 2 Type II Certification',
  date: new Date('2024-08-23T00:00:00-07:00'),
  author: Authors['mason'],
  coverPhoto: '/graphics/soc2-large.png',
  content: <Markdown>{markdown}</Markdown>,
} satisfies BlogPostConfig;

export default Soc2Type2BlogPost;

import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../lib/utils';

const cardVariants = cva('bg-white border border-solid', {
  variants: {
    color: {
      default: 'border-default',
      primary: 'border-primary',
      accent: 'border-accent',
    },
    size: {
      md: 'rounded-lg2 p-3',
      lg: 'rounded-4xl p-14',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'default',
  },
});

interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
    VariantProps<typeof cardVariants> {}

export function Card({
  className,
  color,
  size,
  children,
  ...props
}: CardProps) {
  return (
    <div {...props} className={cn(cardVariants({ size, color, className }))}>
      {children}
    </div>
  );
}

import React from 'react';

function CollectionIcon(props) {
  const fill = props.fill || 'currentColor';
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'layers';

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={fill}>
        <path d="M53.5 38.8l-.8-.4 1-.6c2-1.2 3.1-3.3 3.1-5.7 0-2.3-1.3-4.4-3.3-5.6l-2.1-1.2 2.3-1.4c2-1.2 3.1-3.3 3.1-5.7 0-2.3-1.3-4.4-3.3-5.5L35.4 2.6c-2-1.1-4.5-1.1-6.4 0L10.4 13.4c-2 1.2-3.2 3.3-3.2 5.6s1.2 4.5 3.2 5.6l2.2 1.3-2.2 1.3c-2 1.2-3.2 3.3-3.2 5.6s1.2 4.5 3.2 5.6l.9.5-.9.5c-2 1.2-3.3 3.3-3.3 5.6 0 2.4 1.2 4.5 3.2 5.6l18.4 10.7c1 .6 2.1.9 3.3.9 1.2 0 2.4-.3 3.4-1L53.8 50c2-1.2 3.1-3.3 3.1-5.7-.1-2.3-1.3-4.4-3.4-5.5zM11.6 19c0-.3.1-1.2 1-1.7L31.2 6.5c.6-.4 1.4-.4 2 0l18.2 10.1c.9.5 1 1.4 1 1.7 0 .3-.1 1.2-1 1.8L33.1 31.4c-.6.4-1.4.4-2.1 0L12.6 20.7c-.9-.5-1-1.4-1-1.7zm1 12.1l4.4-2.6 11.7 6.8c1 .6 2.1.9 3.3.9 1.2 0 2.4-.3 3.4-1L47 28.1l4.3 2.4c.9.5 1 1.4 1 1.7 0 .3-.1 1.2-1 1.8L33.1 45.2c-.6.4-1.4.4-2.1 0L12.6 34.5c-.9-.5-1-1.4-1-1.7 0-.3.1-1.2 1-1.7zm38.8 15.1L33.1 57.5c-.6.4-1.4.4-2.1 0L12.6 46.8c-.9-.5-1-1.4-1-1.7 0-.3.1-1.2 1-1.7l3.1-1.8 13.1 7.6c1 .6 2.1.9 3.3.9 1.2 0 2.4-.3 3.4-1l12.9-7.9 3 1.6c.9.5 1 1.4 1 1.7 0 .3-.1 1.2-1 1.7z" />
      </g>
    </svg>
  );
}

export default CollectionIcon;

import _ from 'lodash';

export default function UserWithAvatar(props: { name: string }) {
  const { name } = props;
  const nameComponents = name.split(' ');
  const firstName = nameComponents[0];
  const lastName =
    nameComponents.length > 1
      ? nameComponents[nameComponents.length - 1]
      : undefined;

  return (
    <div className="flex items-center justify-center gap-2">
      <div className="flex justify-center items-center rounded-full border border-solid border-primary text-primary p-2 w-8 h-8 text-sm">
        {`${firstName[0].toUpperCase()}${
          lastName ? lastName[0].toUpperCase() : ''
        }`}
      </div>
      <div className="whitespace-nowrap">{`${_.capitalize(firstName)} ${
        lastName ? _.capitalize(lastName) : ''
      }`}</div>
    </div>
  );
}

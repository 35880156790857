import { CustomModels } from '../../animations/ai/CustomModels';
import { Button } from '../../ui/Button';
import { FeatureSection } from '../landing/FeatureSection';

export function BuiltForYou(props: { showRequestDemoModal: () => void }) {
  return (
    <FeatureSection
      label="Any modality"
      title="AI Models that work for all modalities"
      description="Cove AI models are trained on your platform’s content. Whether you have posts, comments, messages, reviews, product listings, images or videos, our AI models conform to your content."
      cta={
        <Button onClick={props.showRequestDemoModal}>Schedule a demo</Button>
      }
    >
      <CustomModels />
    </FeatureSection>
  );
}

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function PrivacyPolicy() {
  const tableOfContents = (
    <div className="text-start font-bold">
      Effective August 31, 2022
      <div className="p-4 pb-8">
        <div>
          1. <HashLink to="#scope">Scope of this Policy</HashLink>
        </div>
        <div>
          2. <HashLink to="#agreement">Agreement to Terms</HashLink>
        </div>
        <div>
          3. <HashLink to="#info-we-collect">Information We Collect</HashLink>
        </div>
        <div>
          4. <HashLink to="#cookies">Cookies</HashLink>
        </div>
        <div>
          5. <HashLink to="#children">Children's Privacy</HashLink>
        </div>
        <div>
          6.{' '}
          <HashLink to="#use-personal-info">
            Our Use of Personal Information
          </HashLink>
        </div>
        <div>
          7.{' '}
          <HashLink to="#disclosure-personal-info">
            Our Disclosure of Personal Information
          </HashLink>
        </div>
        <div>
          8. <HashLink to="#choices">Your Privacy Choices</HashLink>
        </div>
        <div>
          9.{' '}
          <HashLink to="#eu">
            Additional Information for Residents of the European Union (EU) and
            United Kingdom (UK)
          </HashLink>
        </div>
        <div>
          10. <HashLink to="#security">Security</HashLink>
        </div>
        <div>
          11. <HashLink to="#links">Links to Other Websites</HashLink>
        </div>
        <div>
          12.{' '}
          <HashLink to="#date">
            Effective Date and Changes to this Privacy Policy
          </HashLink>
        </div>
        <div>
          13. <HashLink to="#contact">How to Contact Us</HashLink>
        </div>
      </div>
    </div>
  );

  const scope = (
    <div className="py-4" id="scope">
      <div className="text-lg font-bold pb-2">1. Scope of this Policy</div>
      Cove Software, Inc. ("<span className="font-medium">Cove</span>,” “
      <span className="font-medium">we</span>
      ,” “<span className="font-medium">us</span>,” “
      <span className="font-medium">our</span>”) respects your privacy and
      values your trust. The purpose of this Privacy Policy is to inform you of
      the treatment and handling of personal information that we may learn about
      you from using our website at getcove.com (our “
      <span className="font-medium">Site</span>”), by use of our content
      moderation integration platform (the “
      <span className="font-medium">Platform</span>”), and/or by use of any of
      our products or services (collectively, with the Site and Platform, the “
      <span className="font-medium">Service</span>”). “
      <span className="font-medium">You</span>” or “
      <span className="font-medium">your</span>” or similar terms refer to you
      as a user of our Service.
      <br />
      <br />
      If you have any questions regarding this information or our privacy
      practices, please contact us via the methods set out in the How to Contact
      Us section at the end of this Privacy Policy.
    </div>
  );

  const agreement = (
    <div className="py-4" id="agreement">
      <div className="text-lg font-bold pb-2">2. Agreement to Terms</div>
      By using our Service, you consent to our Privacy Policy and our{' '}
      <Link to="/terms" className="underline">
        Terms of Use
      </Link>
      .{' '}
      <span className="font-medium">
        If you do not agree with the terms of this Privacy Policy or our Terms
        of Use, then you should immediately discontinue use of the Service
        without providing us any personal information.
      </span>
    </div>
  );

  const infoWeCollect = (
    <div className="py-4" id="info-we-collect">
      <div className="text-lg font-bold pb-2">3. Information We Collect</div>
      Cove collects the following categories of personal information (a)
      directly from you, such as through account registration forms, (b) from
      the device and browser that you use to access our Site and/or Platform,
      and (c) cookies and similar technologies.
      <br />
      <br />
      <span className="italic">Identifiers</span>
      <br />
      We may collect your name, email address, and account username and
      password.
      <br />
      <br />
      <span className="italic">Professional Information</span>
      <br />
      If you use the Service on behalf of a business entity or other
      organization, we may collect your business entity or organization's name,
      your position, and your professional contact details (e.g., business or
      organizational phone number, email address, and postal address). <br />
      <br />
      <span className="italic">Payment Information</span>
      <br />
      We may collect payment details, such as your credit card or bank account
      information. We use certain third parties to process payments on our
      behalf (e.g., Stripe) and your payment details will be processed by these
      third-party payment processors.
      <br />
      <br />
      <span className="italic">Device Information</span>
      <br />
      We collect device information when you visit our Site or Platform. This
      includes your computer or mobile device type, browser type, online and/or
      unique personal identifiers, IP address, and geolocation information.
      <br />
      <br />
      You are not required to provide all personal information identified in
      this Privacy Policy to use our Service or to interact with us, but certain
      functionality will not be available if you do not provide certain personal
      information. For example, if you do not provide certain personal
      information, we may not be able to respond to your requests or allow you
      to register an account.
      <br />
      <br />
      We primarily provide services to business entities and organizations and
      their employees, contractors, and agents. We provide such entities with
      content moderation services through the Platform. When these entities use
      our content moderation services, they may provide information pertaining
      to their end users as well as content those end users posted on or
      transmitted through the entities' websites or mobile applications. These
      entities are directly responsible to their users for the use and
      disclosure of any such information.
    </div>
  );

  const cookies = (
    <div className="py-4" id="cookies">
      <div className="text-lg font-bold pb-2">4. Cookies</div>
      We store certain information that gets collected automatically at our end
      through cookies and other similar technologies on our Site and Platform. A
      cookie is a small string of information that a website that you visit
      transfers to your browser for identification purposes. Cookies can be used
      to follow your activity while using a website or across websites, and that
      information helps companies understand your preferences and tendencies, as
      well as improve and personalize your website experience. Some cookies are
      necessary to operate a website, while others can be functional or
      analytical. Cookies on our Site and Platform are generally divided into
      the following categories:
      <br />
      <br />
      <ul className="list-disc">
        <li>
          <span className="italic">Strictly Necessary Cookies</span>: These are
          required for the operation of the Site and Platform. They include, for
          example, cookies that enable you to log into password-protect portions
          of the Site and Platform.
        </li>
        <li>
          <span className="italic">Functional Cookies</span>: These improve the
          functional performance of the Site and Platform and make it easier for
          you to use. For example, cookies are used to remember that you
          previously visited the Site and asked to remain logged into it. These
          cookies qualify as persistent cookies because they remain on your
          device for us to use during your next visit to the Site.
        </li>
      </ul>
      You can prevent the use of certain cookies by modifying your Internet
      browser settings, typically under the sections “Help,” “Internet Options,”
      or “Settings.” If you disable or delete certain cookies in your Internet
      browser settings, you may still access our Site and Platform, however, you
      might not be able to access or use important functions or features and you
      may, for example, be required to re-enter your log-in details.
      <br />
      <br />
      <span className="italic">Do-Not-Track Signals</span>
      <br />
      At this time, Cove does not recognize automated browser signals regarding
      tracking mechanisms, which may include “do-not-track” instructions.
    </div>
  );

  const children = (
    <div className="py-4" id="children">
      <div className="text-lg font-bold pb-2">5. Children's Privacy</div>
      The Service is not intended for users under the age of 13 and Cove does
      not knowingly collect personal information from individuals under the age
      of 13. If you are aware of, or suspect that, someone under the age of 13
      is using the Service without permission, please notify us immediately by
      contacting us as detailed below. If you have questions or concerns about
      the Internet and privacy for your child, we encourage you to check out the{' '}
      <a
        href="https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online"
        className="underline"
      >
        FTC Guidelines
      </a>{' '}
      for protecting your child's privacy online.
    </div>
  );

  const personalInfoUse = (
    <div className="py-4" id="use-personal-info">
      <div className="text-lg font-bold pb-2">
        6. Our Use of Personal Information
      </div>
      We collect and use personal information for the following purposes:
      <br />
      <br />
      <ul className="list-disc">
        <li>
          Providing the Service to you and providing services requested by, or
          reasonably anticipated within the context of our relationship with,
          you;
        </li>
        <li>Managing our relationship with you;</li>
        <li>
          Responding to your inquiries or requests and requesting feedback;
        </li>
        <li>
          Tracking use of your username and password to access our Site and/or
          Platform;
        </li>
        <li>
          Personalizing the Site and Platform (e.g., displaying your name when
          you log in);
        </li>
        <li>
          Detecting security incidents and protecting against malicious,
          deceptive, fraudulent, or illegal activity, or prosecuting those
          responsible; and
        </li>
        <li>Complying with legal and regulatory obligations.</li>
      </ul>
    </div>
  );

  const personalInfoDisclosure = (
    <div className="py-4" id="disclosure-personal-info">
      <div className="text-lg font-bold pb-2">
        7. Our Disclosure of Personal Information
      </div>
      Cove may share personal information with the following third parties:
      <br />
      <br />
      <ul className="list-disc">
        <li>
          <span className="italic">Service Providers</span>, who help us
          provide, maintain, and improve the Service, including IT service and
          hosting providers, payment processors, and image, text, audio and
          video analysis providers.
        </li>
        <li>
          <span className="italic">Advisers and financial institutions</span>,
          including auditors, notaries, business continuity support services
          providers, and legal, tax, and risk and compliance advisors.
        </li>
        <li>
          <span className="italic">
            With third parties in connection with a change to the control or
            financial status of the company
          </span>
          , including a corporate restructuring, sale, acquisition, financing,
          reorganization, bankruptcy, receivership, transfer, assignment of
          assets, or business merger or divestiture. Personal information and
          other information may be shared in the diligence process with
          counterparties and others assisting with the transaction and
          transferred to a successor or affiliate as part of that transaction.
        </li>
        <li>
          <span className="italic">
            Government bodies, dispute resolution organizations, law enforcement
            agencies or third parties{' '}
          </span>
          in connection with (a) responding to a subpoena, search warrant, or
          other lawful request for information we receive; (b) cooperating in a
          law enforcement or similar investigation; or (c) otherwise protecting
          our rights, as applicable.
        </li>
      </ul>
    </div>
  );

  const choices = (
    <div className="py-4" id="choices">
      <div className="text-lg font-bold pb-2">8. Your Privacy Choices</div>
      If you no longer want to receive our newsletter or other marketing
      communications, you may unsubscribe at any time by following the
      unsubscribe options in the communication itself. Please note that you
      cannot unsubscribe from certain correspondence from us, including messages
      relating directly to your account.
      <br />
      <br />
      To update certain information associated with your account or to delete
      your account, go to your{' '}
      <Link to="/dashboard/settings/account" className="underline">
        Account Settings
      </Link>
      , or you can contact us at the information below in the{' '}
      <HashLink to="#contact" className="underline">
        How to Contact Us
      </HashLink>{' '}
      section.
    </div>
  );

  const eu = (
    <div className="py-4" id="eu">
      <div className="text-lg font-bold pb-2">
        9. Additional Information for Residents of the European Union (EU) and
        United Kingdom (UK)
      </div>
      This section provides additional information for residents of the EU and
      UK.
      <br />
      <br />
      <div style={{ fontStyle: 'italic' }}>Data Transfers</div>
      As a US company, our operations are conducted in the United States and in
      order to provide the Service, personal information will be processed in
      the United States, where laws regarding processing of personal information
      may be less stringent than the laws in your country. We provide
      appropriate protections for cross-border transfers as required by
      applicable law for international data transfers. With respect to transfers
      originating from the European Economic Area, and where applicable, we
      implement the standard contractual clauses approved by the European
      Commission.
      <br />
      <br />
      <div style={{ fontStyle: 'italic' }}>
        Retention of Your Personal Information
      </div>
      We retain personal information for as long as necessary to fulfill the
      purposes for which the information was collected; as needed to address
      tax, corporate compliance, employment, litigation, and other legal rights
      and obligations; and as otherwise required or permitted by law. Content
      that is submitted by our customers for content moderation purposes is
      typically retained for the duration of our contract with our customer. We
      retain our customer's contact details and customer service correspondence
      for the duration of our contract with our customers plus 3 years. We
      retain contact details like name and email address for marketing purposes
      for 3 years after your last contact with us or you last engagement with
      our content. We may retain, use, and disclose deidentified and aggregate
      data to improve our Service, develop new products or services, and for
      other business purposes.
      <br />
      <br />
      <div style={{ fontStyle: 'italic' }}>
        Legal Basis for Processing Your Personal Information
      </div>
      Where required, we have several different legal grounds on which we
      collect and process your personal information for the purposes set out in
      the{' '}
      <HashLink to="#use-personal-info" className="underline">
        Our Use of Personal Information
      </HashLink>{' '}
      section above, including: (i) as may be necessary to perform a contract
      with you; (ii) as necessary to comply with a legal obligation; (iii)
      consent (where consent has been provided as appropriate under applicable
      law); and (iv) as necessary for our legitimate interests (such as when we
      act to maintain our business generally, including maintaining the safety
      and security of the Service).
      <br />
      <br />
      <div style={{ fontStyle: 'italic' }}>Automated Decision-Making</div>
      We do not use automated decision-making without human intervention,
      including profiling, in a way that produces legal effects concerning you
      or that otherwise significantly affects you.
      <br />
      <br />
      <div style={{ fontStyle: 'italic' }}>Your Rights</div>
      As available and except as limited under applicable law, individuals in
      the EU and UK have the rights described below:
      <br />
      <br />
      <ul className="list-disc">
        <li>
          <span className="italic">Access.</span> You have the right to request
          a copy of the personal information we are processing about you, which
          we will provide to you in electronic form.
        </li>
        <li>
          <span className="italic">Rectification.</span> You have the right to
          require that any incomplete or inaccurate personal information that we
          process about you is amended.
        </li>
        <li>
          <span className="italic">Deletion.</span> You have the right to
          request that we delete personal information that we process about you,
          unless, for example, we are required to retain such information to
          comply with a legal obligation or to establish, exercise, or defend a
          legal claim.
        </li>
        <li>
          <span className="italic">Restriction.</span> You have the right to
          request that we restrict our processing of your personal information
          where: (i) you believe such data to be inaccurate; (ii) our processing
          is unlawful; or (iii) we no longer need to process such data for a
          particular purpose, but where we are not able to delete the data due
          to a legal or other obligation or because you do not want us to delete
          it.
        </li>
        <li>
          <span className="italic">Portability.</span> You have the right to
          request that we transmit the personal information we hold with respect
          to you to another data controller.
        </li>
        <li>
          <span className="italic">Objection.</span> Where the legal
          justification for our processing of your personal information is our
          legitimate interest, you have the right to object to such processing
          on grounds relating to your situation. We will abide by your request
          unless we have compelling legitimate grounds for the processing which
          override your interests and rights, or if we need to continue to
          process the data to establish, exercise, or defend a legal claim.
        </li>
        <li>
          <span className="italic">Withdrawing Consent.</span> If you have
          consented to our processing of your personal information, you have the
          right to withdraw your consent at any time, free of charge. Please
          note that if you withdraw your consent, this will not affect the
          lawfulness of our use and processing of your information based on your
          consent before the point in time when you withdraw your consent or
          processing of your information based on other legal bases.
        </li>
      </ul>
      Some rights may be limited, and we may need to retain certain personal
      information, as required or permitted by applicable law. To inquire about
      or exercise the rights listed above, please contact us at{' '}
      <a href="mailto:support@getcove.com" className="underline">
        support@getcove.com
      </a>{' '}
      with the subject line “EU Privacy” so that we can get your email to the
      right team. We will respond to you request consistent with applicable law.
      <br />
      <br />
      In addition, We primarily provide services to business entities and
      organizations and their employees, contractors, and agents. We provide
      such entities with content moderation services through the Platform. When
      these entities use our content moderation services, they may provide
      information pertaining to their end users as well as content those end
      users posted on or transmitted through the entities' websites or mobile
      applications. These entities are directly responsible to their users for
      the use and disclosure of any such information.
      <br />
      <br />
      If you feel that your request or concern was not satisfactorily resolved
      by us, you have the right to lodge a complaint with your local data
      protection authority.
    </div>
  );

  const security = (
    <div className="py-4" id="security">
      <div className="text-lg font-bold pb-2">10. Security</div>
      We maintain reasonable technical and organizational measures to protect
      personal information from loss, misuse, alteration, or unintentional
      destruction. We have implemented various security measures to protect both
      the personal information and the general information that we receive from
      you through the Service. Whenever you give out personal information online
      there is a risk that third parties may intercept and use that information.
      Although we seek to protect your personal information and privacy, we
      cannot guarantee the security of any information you disclose online. To
      the extent permitted under applicable law, we assume no liability or
      responsibility for disclosure of your information due to errors in
      transmission, unauthorized access by third parties, or other causes beyond
      our control.
      <br />
      <br />
      You play an important role in keeping your information secure. You should
      not share your username or password with anyone. If you have reason to
      believe that your account is no longer secure, please contact us
      immediately at the contact information in the{' '}
      <HashLink to="#contact" className="underline">
        How to Contact Us
      </HashLink>{' '}
      section below
    </div>
  );

  const links = (
    <div className="py-4" id="links">
      <div className="text-lg font-bold pb-2">11. Links to Other Websites</div>
      For your convenience, we may link to third-party sites and services, or
      otherwise display third-party content through our Site and Platform to
      provide increased value to our visitors. We have no control over these
      linked sites, each of which has separate privacy and data collection
      policies and practices independent from us. As such we have no
      responsibility or liability for these independent policies or actions and
      are not responsible for the privacy practices or the content of any such
      websites. Please note that these other sites may send their own cookies to
      users, collect data, or solicit personal information, and we encourage you
      to review their policies before engaging with these third-party sites.
    </div>
  );

  const date = (
    <div className="py-4" id="date">
      <div className="text-lg font-bold pb-2">
        12. Effective Date and Changes to this Privacy Policy
      </div>
      This Privacy Policy is effective as of the date at the top of this policy.
      Cove has the discretion to update this Privacy Policy at any time. When we
      do, we will revise the effective date at the top of this page. We
      encourage users to frequently check this page for any changes and to stay
      informed about how we are helping to protect the personal information we
      collect, especially before you provide information, and particularly
      personal information, directly to us through the Service. In the event of
      a material change to this Privacy Policy, we will provide a message
      through our website or via email informing you of the change.
    </div>
  );

  const contact = (
    <div className="py-4" id="contact">
      <div className="text-lg font-bold pb-2">13. How to Contact Us</div>
      If you have any questions about this Privacy Policy or our Service, please
      contact us at{' '}
      <a href="mailto:support@getcove.com" className="underline">
        support@getcove.com
      </a>
      .
    </div>
  );

  return (
    <div className="flex flex-col p-10 sm:py-16 sm:px-28 justify-self-center text-start">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="text-2xl font-bold mb-12 mt-16 text-center">
        Cove Software, Inc. Privacy Policy
      </div>
      {tableOfContents}
      {scope}
      {agreement}
      {infoWeCollect}
      {cookies}
      {children}
      {personalInfoUse}
      {personalInfoDisclosure}
      {choices}
      {eu}
      {security}
      {links}
      {date}
      {contact}
    </div>
  );
}

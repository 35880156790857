import { GQLIntegration } from '../../../graphql/generated';
import AmazonRekognitionLogo from '../../../images/AmazonRekognitionLogo.png';
import HiveLogo from '../../../images/HiveLogo.png';
import HiveLogoWithBackground from '../../../images/HiveLogoWithBackground.png';
import OpenAILogo from '../../../images/OpenAILogo.png';
import OpenAILogoWithBackground from '../../../images/OpenAILogoWithBackground.png';
import PerspectiveLogo from '../../../images/PerspectiveLogo.png';
import PerspectiveLogoWithBackground from '../../../images/PerspectiveLogoWithBackground.png';
import SpectrumLabsLogo from '../../../images/SpectrumLabsLogo.png';
import SpectrumLabsLogoWithBackground from '../../../images/SpectrumLabsLogoWithBackground.png';
import { IntegrationConfig } from './IntegrationsDashboard';

export const INTEGRATION_CONFIGS: IntegrationConfig[] = [
  {
    name: GQLIntegration.Hive,
    title: 'Hive',
    logo: HiveLogo,
    logoWithBackground: HiveLogoWithBackground,
    url: 'https://hivemoderation.com/',
    requiresInfo: true,
  },
  {
    name: GQLIntegration.Perspective,
    title: 'Perspective',
    logo: PerspectiveLogo,
    logoWithBackground: PerspectiveLogoWithBackground,
    url: 'https://www.perspectiveapi.com/',
    requiresInfo: false,
  },
  {
    name: GQLIntegration.SpectrumLabs,
    title: 'Spectrum Labs',
    logo: SpectrumLabsLogo,
    logoWithBackground: SpectrumLabsLogoWithBackground,
    url: 'https://www.spectrumlabsai.com/',
    requiresInfo: true,
  },
  {
    name: GQLIntegration.OpenAi,
    title: 'OpenAI',
    logo: OpenAILogo,
    logoWithBackground: OpenAILogoWithBackground,
    url: 'https://openai.com/',
    requiresInfo: true,
  },
  {
    name: GQLIntegration.AmazonRekognition,
    title: 'AWS Rekognition',
    logo: AmazonRekognitionLogo,
    logoWithBackground: AmazonRekognitionLogo,
    url: 'https://aws.amazon.com/rekognition/',
    requiresInfo: true,
  },
];

import { ItemTypeKind } from '@protego-api/types';
import _ from 'lodash';

import { GQLScalarType, type GQLFieldType } from '../../../graphql/generated';
import { assertUnreachable } from '../../../utils/misc';
import type { FieldState } from './ItemTypeFormCustomField';

export const ApiRoutes = ['Items API', 'Reports API'] as const;
export const RequestLanguages = ['NodeJS', 'Python', 'PHP', 'Curl'] as const;

export type ApiRoute = (typeof ApiRoutes)[number];
export type RequestLanguage = (typeof RequestLanguages)[number];

type RequestParameters = {
  apiRoute: ApiRoute;
  requestLanguage: RequestLanguage;
  itemType: {
    id?: string;
    fields: FieldState[];
  };
  defaultUserItemTypeId?: string;
};

export function generateRequestCode(opts: RequestParameters) {
  const { apiRoute, requestLanguage, itemType, defaultUserItemTypeId } = opts;
  switch (requestLanguage) {
    case 'Curl':
      return generateCurlRequest(apiRoute, itemType, defaultUserItemTypeId);
    case 'Python':
      return generatePythonRequest(apiRoute, itemType, defaultUserItemTypeId);
    case 'NodeJS':
      return generateNodeJsRequest(apiRoute, itemType, defaultUserItemTypeId);
    case 'PHP':
      return generatePhpRequest(apiRoute, itemType, defaultUserItemTypeId);
  }
}

export function generateItemData(
  apiRoute: ApiRoute,
  itemType: { id?: string; fields: FieldState[] },
  defaultUserItemTypeId?: string,
): JsonValue {
  const itemData = _.zipObject(
    itemType.fields.map((field) => field.name),
    itemType.fields.map((field) =>
      getFakeStringForScalarType({
        fieldType: field.type,
        keyScalarType: field.container?.keyScalarType ?? undefined,
        valueScalarType: field.container?.valueScalarType,
      }),
    ),
  );

  switch (apiRoute) {
    case 'Items API':
      return {
        items: [
          {
            id: getFakeStringForScalarType({ fieldType: 'ID' }),
            typeId:
              itemType.id ?? getFakeStringForScalarType({ fieldType: 'ID' }),
            data: itemData,
          },
        ],
      };
    case 'Reports API':
      return {
        reporter: {
          kind: ItemTypeKind.USER,
          id: getFakeStringForScalarType({ fieldType: 'ID' }),
          typeId:
            defaultUserItemTypeId ??
            getFakeStringForScalarType({ fieldType: GQLScalarType.Id }),
        },
        reportedAt: getFakeStringForScalarType({
          fieldType: GQLScalarType.Datetime,
        }),
        reportedItem: {
          id: getFakeStringForScalarType({ fieldType: 'ID' }),
          typeId:
            itemType.id ?? getFakeStringForScalarType({ fieldType: 'ID' }),
          data: itemData,
        },
        reportedForReason: {
          policyId: getFakeStringForScalarType({
            fieldType: GQLScalarType.PolicyId,
          }),
          reason: 'Lorem ipsum dolor...',
        },
        reportedItemThread: [
          {
            id: getFakeStringForScalarType({ fieldType: GQLScalarType.Id }),
            typeId:
              itemType.id ?? getFakeStringForScalarType({ fieldType: 'ID' }),
            data: {
              text: "Hey what's up?",
            },
          },
          {
            id: getFakeStringForScalarType({ fieldType: GQLScalarType.Id }),
            typeId:
              itemType.id ?? getFakeStringForScalarType({ fieldType: 'ID' }),
            data: {
              text: 'Not much, you?',
            },
          },
          {
            id: getFakeStringForScalarType({ fieldType: GQLScalarType.Id }),
            typeId:
              itemType.id ?? getFakeStringForScalarType({ fieldType: 'ID' }),
            data: {
              text: "I'm good!",
            },
          },
        ],
      };
    default:
      assertUnreachable(apiRoute);
  }
}

type JsonValue =
  | string
  | number
  | boolean
  | null
  | JsonValue[]
  | { [k: string]: JsonValue };

export function translateJSONObjectToPHP(json: JsonValue): string {
  function translateValue(value: JsonValue, indentLevel: number = 0): string {
    const indent = '    '; // 4 spaces for indentation
    const currentIndent = indent.repeat(indentLevel);
    const nextIndent = indent.repeat(indentLevel + 1);

    if (Array.isArray(value)) {
      const arrayElements = value.map(
        (element) => `${nextIndent}${translateValue(element, indentLevel + 1)}`,
      );
      return '[\n' + arrayElements.join(',\n') + `\n${currentIndent}]`;
    } else if (typeof value === 'object' && value !== null) {
      const objectElements = Object.keys(value).map(
        (key) =>
          `${nextIndent}'${key}' => ${translateValue(
            value[key],
            indentLevel + 1,
          )}`,
      );
      return '[\n' + objectElements.join(',\n') + `\n${currentIndent}]`;
    } else if (typeof value === 'string') {
      return `'${value.replace(/'/g, "\\'")}'`;
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      return value.toString();
    } else if (value === null) {
      return 'null';
    }

    assertUnreachable(value);
  }

  return translateValue(json);
}

export function generateCurlRequest(
  apiRoute: ApiRoute,
  itemType: {
    id?: string;
    fields: FieldState[];
  },
  defaultUserItemTypeId?: string,
) {
  const data = generateItemData(apiRoute, itemType, defaultUserItemTypeId);

  switch (apiRoute) {
    case 'Items API':
      return `curl --request POST --url https://getcove.com/api/v1/items/async --header 'x-api-key: APIKEY' --header 'Content-Type: application/json' --data '${JSON.stringify(
        data,
        null,
        4,
      )}'`;
    case 'Reports API':
      return `curl --request POST --url https://getcove.com/api/v1/report --header 'x-api-key: APIKEY' --header 'Content-Type: application/json' --data '${JSON.stringify(
        data,
        null,
        4,
      )}'`;
  }
}

export function generatePythonRequest(
  apiRoute: ApiRoute,
  itemType: {
    id?: string;
    fields: FieldState[];
  },
  defaultUserItemTypeId?: string,
) {
  const data = generateItemData(apiRoute, itemType, defaultUserItemTypeId);

  switch (apiRoute) {
    case 'Items API': {
      return `import requests

headers = {
  'x-api-key': 'APIKEY',
  'Content-Type': 'application/json'
}
data = ${JSON.stringify(data, null, 4)
        .replace(/null/g, 'None')
        .replace(/true/g, 'True')
        .replace(/false/g, 'False')}
response = requests.post(
  'https://getcove.com/api/v1/items/async',
  headers=headers,
  json=data
)
response_dict = response.json()`;
    }
    case 'Reports API': {
      return `import requests

headers = {
  'x-api-key': 'APIKEY',
  'Content-Type': 'application/json'
}

data = ${JSON.stringify(data, null, 4)}

response = requests.post(
  'https://getcove.com/api/v1/report',
  headers=headers,
  json=data
)
response_dict = response.json()`;
    }
  }
}

export function generateNodeJsRequest(
  apiRoute: ApiRoute,
  itemType: {
    id?: string;
    fields: FieldState[];
  },
  defaultUserItemTypeId?: string,
) {
  const data = generateItemData(apiRoute, itemType, defaultUserItemTypeId);

  switch (apiRoute) {
    case 'Items API': {
      return `const body = 
  ${JSON.stringify(data, null, 4)};

const response = await fetch(
  "https://getcove.com/api/v1/items/async",
  {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      "x-api-key": "APIKEY",
      "Content-Type": "application/json"
    },
  }
);
console.log(response.status);
      `;
    }
    case 'Reports API': {
      return `const body = ${JSON.stringify(data, null, 4)}

const response = await fetch(
  "https://getcove.com/api/v1/report",
  {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      "x-api-key": "APIKEY",
      "Content-Type": "application/json"
    },
  }
);
console.log(response.status);`;
    }
  }
}

export function generatePhpRequest(
  apiRoute: ApiRoute,
  itemType: {
    id?: string;
    fields: FieldState[];
  },
  defaultUserItemTypeId?: string,
) {
  const data = translateJSONObjectToPHP(
    generateItemData(apiRoute, itemType, defaultUserItemTypeId),
  );

  switch (apiRoute) {
    case 'Items API': {
      return `<?php
require_once('vendor/autoload.php');

$client = new GuzzleHttpClient();

$headers = [
  'x-api-key' => 'APIKEY',
  'Content-Type' => 'application/json'
];

$body = ${data};

$response = $client->request('POST', 'https://getcove.com/api/v1/items/async', [
  'headers' => $headers,
  'json' => $body,
]);

echo $response->getBody();`;
    }
    case 'Reports API': {
      return `<?php
require_once('vendor/autoload.php');

$client = new GuzzleHttpClient();

$headers = [
  'x-api-key' => 'APIKEY',
  'Content-Type' => 'application/json'
];

$body = ${data};
  

$response = $client->request('POST', 'https://getcove.com/api/v1/report', [
  'headers' => $headers,
  'json' => $body,
]);

echo $response->getBody();`;
    }
  }
}

// TODO: strengthen typing by mapping input type to output type
function getFakeStringForScalarType(field: {
  fieldType: GQLFieldType;
  keyScalarType?: GQLScalarType;
  valueScalarType?: GQLScalarType;
}): any {
  const { fieldType, keyScalarType, valueScalarType } = field;
  switch (fieldType) {
    case 'AUDIO':
      return `https://url.com/${Math.floor(1000 * Math.random())}.mp3`;
    case 'BOOLEAN':
      return Math.random() < 0.5;
    case 'DATETIME':
      return new Date().toLocaleTimeString();
    case 'GEOHASH':
      // Geohashes are always 6 characters long, so we just hardcode this one
      // for example purposes. NB: not all 6 character strings are valid geohashes.
      return 'gbsuv7';
    case 'ID':
    case 'POLICY_ID':
    case 'USER_ID': {
      let result = '';
      const chars = 'abcdef0123456789';
      for (let i = 0; i < 10; i++) {
        result += chars[Math.floor(Math.random() * chars.length)];
      }
      return result;
    }
    case 'IMAGE':
      return `https://url.com/${Math.floor(1000 * Math.random())}.png`;
    case 'VIDEO':
      return `https://url.com/${Math.floor(1000 * Math.random())}.mp4`;
    case 'NUMBER':
      return Math.floor(100 * Math.random());
    case 'RELATED_ITEM':
      const names = [
        'John Smith',
        'Jane Doe',
        'Frodo Baggins',
        'Harry Potter',
        'Snow White',
      ];
      return {
        id: getFakeStringForScalarType({ fieldType: 'ID' }) as string,
        typeId: getFakeStringForScalarType({ fieldType: 'ID' }) as string,
        name: names[Math.floor(Math.random() * names.length)],
      };
    case 'STRING':
      return 'Lorem ipsum dolor';
    case 'URL':
      return `https://url.com/some-path/${Math.floor(100 * Math.random())}`;
    case 'ARRAY': {
      if (valueScalarType == null) {
        throw Error("Can't have an array without a value type");
      }
      return Array(3)
        .fill(3)
        .map(() => getFakeStringForScalarType({ fieldType: valueScalarType }));
    }
    case 'MAP': {
      if (keyScalarType == null || valueScalarType == null) {
        throw Error("Can't have a map without a key and value type");
      }
      return Array(3)
        .fill(3)
        .map(() => ({
          [getFakeStringForScalarType({
            fieldType: keyScalarType,
          }) as string]: getFakeStringForScalarType({
            fieldType: valueScalarType,
          }),
        }));
    }
  }
}

import { LoadingOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useState } from 'react';

import CoveModal from '../../../components/CoveModal';

import { useGQLGenerateDefaultPolicyDefinitionLazyQuery } from '../../../../../graphql/generated';
import MarkdownTextInput from '../../../policies/MarkdownTextInput';
import DemoFooter from '../DemoFooter';

export const defaultPolicyTexts = {
  Hate: `Do not post content targeting a person or group of people (including all groups except those who are considered non-protected groups described as having carried out violent crimes or sexual offenses or representing less than half of a group) on the basis of their aforementioned protected characteristic(s) or immigration status with:
    <ul>
      <li>Violent speech or support in written or visual form</li>
      <li>Dehumanizing speech or imagery in the form of comparisons, generalizations, or unqualified behavioral statements (in written or visual form) to or about:</li>
      <ul>
        <li>Insects (including but not limited to: cockroaches, locusts)</li>
        <li>Animals in general or specific types of animals that are culturally perceived as intellectually or physically inferior (including but not limited to: Black people and apes or ape-like creatures; Jewish people and rats; Muslim people and pigs; Mexican people and worms)</li>
        <li>Filth (including but not limited to: dirt, grime)</li>
        <li>Bacteria, viruses, or microbes</li>
        <li>Disease (including but not limited to: cancer, sexually transmitted diseases)</li>
        <li>Feces (including but not limited to: shit, crap)</li>
        <li>Subhumanity (including but not limited to: savages, devils, monsters, primitives)</li>
        <li>Sexual predators (including but not limited to: Muslim people having sex with goats or pigs)</li>
        <li>Violent criminals (including but not limited to: terrorists, murderers, members of hate or criminal organizations)</li>
        <li>Other criminals (including but not limited to “thieves,” “bank robbers,” or saying “All [protected characteristic or quasi-protected characteristic] are 'criminals'”).</li>
        <li>Certain objects (women as household objects or property or objects in general; Black people as farm equipment; transgender or non-binary people as “it”)</li>
        <li>Statements denying existence (including but not limited to: "[protected characteristic(s) or quasi-protected characteristic] do not exist", "no such thing as [protected charactic(s) or quasi-protected characteristic]" )</li>
        <li>Harmful stereotypes historically linked to intimidation, exclusion, or violence on the basis of a protected characteristic, such as Blackface; Holocaust denial; claims that Jewish people control financial, political, or media institutions; and references to Dalits as menial laborers</li>
      </ul>
    </ul>`,
  Violence: `Do not post threats that could lead to death (and other forms of high-severity violence) and admission of past violence targeting people or places where threat is defined as any of the following:
  <ul>
    <li>Statements of intent to commit high-severity violence. This includes content where a symbol represents the target and/or includes a visual of an armament or method to represent violence.</li>
    <li>Calls for high-severity violence including content where no target is specified but a symbol represents the target and/or includes a visual of an armament or method that represents violence.</li>
    <li>Statements advocating for high-severity violence.</li>
    <li>Aspirational or conditional statements to commit high-severity violence.</li>
    <li>Statements admitting to committing high-severity violence except when shared in a context of redemption, self-defense or when committed by law enforcement, military or state security personnel.</li>
    <li>Content that asks for, offers, or admits to offering services of high-severity violence (for example, hitmen, mercenaries, assassins, female genital mutilation) or advocates for the use of these services</li>
  </ul>
Admissions, statements of intent or advocacy, calls to action, or aspirational or conditional statements to kidnap or abduct a target or that promotes, supports or advocates for kidnapping or abduction

Content that depicts kidnappings or abductions if it is clear the content is not being shared by a victim or their family as a plea for help, or shared for informational, condemnation or awareness raising purposes

Threats that lead to serious injury (mid-severity violence) and admission of past violence toward private individuals, unnamed specified persons, minor public figures, high-risk persons or high-risk groups where threat is defined as any of the following:
<ul>
  <li>Statements of intent to commit violence, or</li>
  <li>Statements advocating violence, or</li>
  <li>Calls for mid-severity violence including content where no target is specified but a symbol represents the target, or</li>
  <li>Aspirational or conditional statements to commit violence, or</li>
  <li>Statements admitting to committing mid-severity violence except when shared in a context of redemption, self-defense, fight-sports context or when committed by law enforcement, military or state security personnel.</li>
</ul>

Content about other target(s) apart from private individuals, minor public figures, high-risk persons or high-risk groups and any credible:
<ul>
  <li>Statements of intent to commit violence, or</li>
  <li>Calls for action of violence, or</li>
  <li>Statements advocating for violence, or</li>
  <li>Aspirational or conditional statements to commit violence</li>
  <li>Threats that lead to physical harm (or other forms of lower-severity violence) towards private individuals (self-reporting required) or minor public figures where threat is defined as any of the following:</li>
</ul>

Private individuals (name and/or face match are required) or minor public figures that includes:
<ul>
  <li>Statements of intent, calls for action, advocating, aspirational or conditional statements to commit low-severity violence</li>
  <li>Instructions on how to make or use weapons if there is evidence of a goal to seriously injure or kill people through:</li>
  <ul>
    <li>Language explicitly stating that goal</li>
    <li>Unless when shared in a context of recreational self defense, for military training purposes, commercial video games, or news coverage (posted by a Page or with a news logo).</li>
    <li>Providing instructions on how to make or use explosives, unless there is clear context that the content is for a non-violent purpose (for example, part of commercial video games, clear scientific/educational purpose, fireworks or specifically for fishing)</li>
  </ul>
</ul>

Any content containing statements of intent, calls for action, conditional or aspirational statements, or advocating for violence due to voting, voter registration or the administration or outcome of an election

Statements of intent or advocacy, calls to action, or aspirational or conditional statements to bring or take up armaments to locations (including but not limited to places of worship, educational facilities, polling places or locations used to count votes or administer an election) or locations where there are temporary signals of a heightened risk of violence or offline harm. This may be the case, for example, when there is a known protest and counter-protest planned or violence broke out at a protest in the same city within the last 7 days. This includes a visual of an armament or method that represents violence that targets these locations.

Statements of intent or advocacy, calls to action, or aspirational or conditional statements to forcibly enter locations (including but not limited to places of worship, educational facilities, polling places or locations used to count votes or administer an election) where there are temporary signals of a heightened risk of violence or offline harm. This may be the case, for example, when there is a known protest and counter-protest planned or violence broke out at a protest in the same city within the last 7 days.`,
  Harassment: `Do not harass other users, which includes:
  <ul>
    <li>Repeated contact which is:</li>
    <ul>
      <li>Unwanted, or</li>
      <li>Sexually harassing, or</li>
      <li>Directed at a large number of individuals with no prior solicitation.</li>
    </ul>
    <li>Calls for self-injury or suicide of a specific person, or group of individuals.</li>
    <li>Attacks based on their experience of sexual assault, sexual exploitation, sexual harassment, or domestic abuse.</li>
    <li>Statements of intent to engage in a sexual activity or advocating to engage in a sexual activity.</li>
    <li>Severe sexualized commentary.</li>
    <li>Derogatory sexualized photoshop or drawings</li>
    <li>Attacks through derogatory terms related to sexual activity (for example: whore, slut).</li>
    <li>Claims that a violent tragedy did not occur.</li>
    <li>Claims that individuals are lying about being a victim of a violent tragedy or terrorist attack, including claims that they are:</li>
    <ul>
      <li>Acting or pretending to be a victim of a specific event, or</li>
      <li>Paid or employed to mislead people about their role in the event.</li>
    </ul>
    <li>Threats to release an individual's private phone number, residential address, email address or medical records.</li>
    <li>Calls for, or statements of intent to engage in, bullying and/or harassment.</li>
    <li>Content that degrades or expresses disgust toward individuals who are depicted in the process of, or right after, menstruating, urinating, vomiting, or defecating</li>
    <li>Everyone is protected from the following, but for adult public figures, they must be purposefully exposed to:</li>
    <ul>
      <li>Calls for death and statements in favor of contracting or developing a medical condition.</li>
      <li>Celebration or mocking of death or medical condition.</li>
      <li>Claims about sexually transmitted infections.</li>
      <li>Derogatory terms related to female gendered cursing.</li>
      <li>Statements of inferiority about physical appearance.</li>
    </ul>
  </ul>`,
  'Sexual content': `Do not post content depicting sexual activity, including:
    <ul>
      <li>Explicit sexual activity and stimulation</li>
      <ul>
        <li>Explicit sexual intercourse or oral sex, defined as mouth or genitals entering or in contact with another person's genitals or anus</li>
        <li>Explicit stimulation of genitalia or anus, defined as stimulating genitalia or anus or inserting objects, including sex toys, into genitalia or anus, where the contact with the genitalia or anus is directly visible.</li>
      </ul>
      <li>Implied sexual activity and stimulation, except in cases of medical or health context, advertisements, and recognized fictional images or with indicators of fiction:</li>
      <ul>
        <li>Implied sexual intercourse or oral sex, defined as mouth or genitals entering or in contact with another person's genitals or anus, when the genitalia and/or the activity or contact is not directly visible.</li>
        <li>Implied stimulation of genitalia or anus, defined as stimulating genitalia or anus or inserting objects, including sex toys, into or above genitalia or anus, when the genitalia and/or the activity or contact is not directly visible.</li>
      </ul>
      <li>Other activities, except in cases of medical or health context, advertisements, and recognized fictional images or with indicators of fiction, including but not limited to:</li>
      <ul>
        <li>Erections</li>
        <li>Presence of by-products of sexual activity.</li>
        <li>Sex toys placed upon or inserted into mouth.</li>
        <li>Stimulation of naked human nipples.</li>
        <li>Squeezing female breasts, defined as a grabbing motion with curved fingers that shows both marks and clear shape change of the breasts. We allow squeezing in breastfeeding contexts.</li>
      </ul>
      <li>Fetish content that involves:</li>
      <ul>
        <li>Acts that are likely to lead to the death of a person or animal.</li>
        <li>Dismemberment.</li>
        <li>Cannibalism.</li>
        <li>Feces, urine, spit, snot, menstruation or vomit.</li>
        <li>Bestiality.</li>
      </ul>
    </ul>`,
  Spam: `Do not
  <ul>
    <li>Post either manually or automatically, at very high frequencies.</li>
    <li>Require or claim that users are required to engage with content before they are able to view or interact with promised content.</li>
    <li>Encourage the use of apps or websites under false pretenses, such as:</li>
    <ul>
      <li>Offering false or non-existent services or functionality</li>
      <li>Failing to direct to promised content (e.g. “Click here for a discount code at Nordstrom”; false play buttons)</li>
      <li>The deceptive or misleading use of URLs, defined as:</li>
      <ul>
        <li>Misleading content: Content contains a link that promises one type of content but delivers something substantially different.</li>
        <li>Deceptive redirect behavior: Websites that require an action (e.g. captcha, watch ad, click here) in order to view the expected landing page content and the domain name of the URL changes after the required action is complete.</li>
        <li>Deceptive landing page functionality: Websites that have a misleading user interface, which results in accidental traffic being generated (e.g. pop-ups/unders, clickjacking, etc.).</li>
        <li>Typosquatting: An external website pretends to be a reputable brand or service by using a name, domain or content that features typos, misspellings or other means to impersonate well-known brands using a landing page similar to another, trusted site to mislead visitors (e.g. www.faceb00k.com, www.face_book.com).</li>
        <li>And other behaviors that are substantially similar to the above.</li>
      </ul>
    </ul>
  </ul>`,
  'Drug sales': `Do not post content that:
  <ul>
    <li>Attempts to buy, sell, trade, co-ordinate the trade of, donate, gift or asks for non-medical drugs.</li>
    <li>Admits to buying, trading or co-ordinating the trade of non-medical drugs by the poster of the content by themselves or through others.</li>
    <li>Admits to personal use without acknowledgment of or reference to recovery, treatment, or other assistance to combat usage. This content may not speak positively about, encourage use of, coordinate or provide instructions to make or use non-medical drugs.</li>
    <li>Coordinates or promotes (by which we mean speaks positively about, encourages the use of, or provides instructions to use or make) non-medical drugs.</li>
    <li>Pharmaceutical drugs (drugs that require a prescription or medical professionals to administer)</li>
  </ul>
  
  Do not post content that:
  <ul>
    <li>Attempts to buy, sell or trade pharmaceutical drugs except when:</li>
    <ul>
      <li>Listing the price of vaccines in an explicit education or discussion context.</li>
      <li>Offering delivery when posted by legitimate healthcare e-commerce businesses.</li>
    </ul>
    <li>Attempts to donate or gift pharmaceutical drugs</li>
    <li>Asks for pharmaceutical drugs except when content discusses the affordability, accessibility or efficacy of pharmaceutical drugs in a medical context</li>
  </ul>`,
  'Weapon sales': `Do not post content that:
  <ul>
    <li>Attempts to buy, sell, or trade, firearms, firearm parts, ammunition, explosives, or lethal enhancements except when posted by a Page, Group or Instagram profile representing legitimate brick-and-mortar entities, including retail businesses, websites, brands or government agencies (e.g. police department, fire department) or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</li>
    <li>Attempts to donate or gift firearms, firearm parts, ammunition, explosives, or lethal enhancements except when posted in the following contexts:</li>
    <ul>
      <li>Donating, trading in or buying back firearms and ammunition by a Page, Group or Instagram profile representing legitimate brick-and-mortar entities, including retail businesses, websites, brands or government agencies, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</li>
      <li>An auction or raffle of firearms by legitimate brick-and-mortar entities, including retail businesses, government-affiliated organizations or non-profits, or private individuals affiliated with or sponsored by legitimate brick-and-mortar entities.</li>
    </ul>
    <li>Asks for firearms, firearm parts, ammunition, explosives, or lethal enhancements</li>
    <li>Sells, gifts, exchanges, transfers, coordinates, promotes (by which we mean speaks positively about, encourages the use of) or provides access to 3D printing or computer-aided manufacturing instructions for firearms or firearms parts regardless of context or poster.</li>
    <li>Attempts to buy, sell, trade, donate or gift bladed weapons.</li>
  </ul>`,
  Terrorism: `Do not post Praise, Substantive Support, and Representation of various Dangerous Organizations. These concepts apply to the organizations themselves, their activities, and their members. These concepts do not proscribe peaceful advocacy for particular political outcomes.

  Praise, defined as any of the below:

  <ul>
    <li>Speak positively about a designated entity or event;</li>
    <li>Give a designated entity or event a sense of achievement;</li>
    <li>Legitimizing the cause of a designated entity by making claims that their hateful, violent, or criminal conduct is legally, morally, or otherwise justified or acceptable;</li>
    <li>Aligning oneself ideologically with a designated entity or event.</li>
  </ul>

  Substantive Support, defined as any of the below:

  <ul>
    <li>Any act which improves the financial status of a designated entity • including funnelling money towards, or away from a designated entity;</li>
    <li>Any act which provides material aid to a designated entity or event;</li>
    <li>Putting out a call to action on behalf of a designated entity or event;</li>
    <li>Recruiting on behalf of a designated entity or event;</li>
    <li>Channeling information or resources, including official communications, on behalf of a designated entity or event</li>
  </ul>

  Representation, defined as stating that you are a member of a designated entity, or are a designated entity;`,
  'Sexual exploitation': `Do not post instances where content consists of any form of non-consensual sexual touching, necrophilia, or forced stripping, including:  
  <ul>
    <li>Depictions (including real photos/videos except in a real-world art context), or</li>
    <li>Sharing, offering, asking for or threatening to share imagery, or</li>
    <li>Descriptions, unless shared by or in support of the victim/survivor, or</li>
    <li>Advocacy (including aspirational and conditional statements), or</li>
    <li>Statements of intent, or</li>
    <li>Calls for action, or</li>
    <li>Admitting participation, or</li>
    <li>Mocking victims of any of the above.</li>
    <li>Content shared by a third party that identifies victims or survivors of sexual assault when reported by the victim or survivor.</li>
  </ul>

  Content that attempts to exploit people by any of the following:
  <ul>
    <li>Sextortion: Coercing money, favors or intimate imagery from people with threats to expose their intimate imagery or intimate information</li>
    <li>Sharing, threatening, stating an intent to share, offering or asking for non-consensual intimate imagery that fulfills all of the 3 following conditions:</li>
    <ul>
      <li>Imagery is non-commercial or produced in a private setting.</li>
      <li>Person in the imagery is (near) nude, engaged in sexual activity or in a sexual pose.</li>
      <li>Lack of consent to share the imagery is indicated by meeting any of the signals:</li>
      <ul>
        <li>Vengeful context (such as, caption, comments or page title).</li>
        <li>Independent sources (such as, law enforcement record) including entertainment media (such as, leak of images confirmed by media).</li>
        <li>A visible match between the person depicted in the image and the person who has reported the content to us.</li>
        <li>The person who reported the content to us shares the same name as the person depicted in the image.</li>
      </ul>
    </ul>
    <li>Secretly taken non-commercial imagery of a real person's commonly sexualized body parts (breasts, groin, buttocks, or thighs) or of a real person engaged in sexual activity. This imagery is commonly known as "creepshots" or "upskirts" and includes photos or videos that mock, sexualize or expose the person depicted in the imagery.</li>
    <li>Threatening or stating an intent to share private sexual conversations that meets the following criteria:</li>
    <ul>
      <li>Lack of consent is indicated by:</li>
      <ul>
        <li>Vengeful context and/or threatening context, or</li>
        <li>A visible match between the person depicted in the image and the person who has reported the content to us.</li>
        <li>The person who reported the content to us shares the same name as the person depicted in the image.</li>
      </ul>
    </ul>
  </ul>`,
  'Self harm & suicide': `Do not post:
  <ul>
    <li>Content that promotes, encourages, coordinates, or provides instructions for</li>
    <ul>
      <li>Suicide.</li>
      <li>Self-injury.</li>
      <li>Eating disorders.</li>
      
    </ul>
    <li>Content that depicts graphic self-injury imagery.</li>
    <li>Content depicting a person who engaged in a suicide attempt or death by suicide.</li>
    <li>Content that focuses on depiction of ribs, collar bones, thigh gaps, hips, concave stomach, or protruding spine or scapula when shared together with terms associated with eating disorders.</li>
    <li>Content that contains instructions for drastic and unhealthy weight loss when shared together with terms associated with eating disorders.</li>
    <li>Content that mocks victims or survivors of suicide, self-injury or eating disorders who are either publicly known or implied to have experienced suicide or self-injury.</li>
  </ul>`,
  Grooming: `Do not post content that constitutes or facilitates inappropriate interactions with children, such as:
  <ul>
    <li>Arranging or planning real-world sexual encounters with children</li>
    <li>Purposefully exposing children to sexually explicit language or sexual material</li>
    <li>Engaging in implicitly sexual conversations in private messages with children</li>
    <li>Obtaining or requesting sexual material from children in private messages</li>
    <li>Exploitative intimate imagery and sextortion</li>
  </ul>

  Do not post content that attempts to exploit children by:
  <ul>
    <li>Coercing money, favors or intimate imagery with threats to expose intimate imagery or information.</li>
    <li>Sharing, threatening or stating an intent to share private sexual conversations or intimate imagery.</li>
  </ul>

  Do not post content (including photos, videos, real-world art, digital content, and verbal depictions) that sexualizes children.`,
  Profanity: `Do not post profanity and obscene language, even when it's obscure, misspelled or vague, such as when using symbols or emojis`,
  Privacy: `Do not post content that shares or solicits any of the following private information, either on platform or through external links:

  Personally identifiable information about yourself or others
  <ul>
    <li>Personal identification numbers and identity documents: identifying individuals by name and government-issued numbers, including:</li>
    <ul>
      <li>National identification numbers (for example Social Security Numbers (SSN), Passport Numbers, National Insurance/Health Service Numbers, Personal Public Service Numbers (PPS), Individual Taxpayer Identification Numbers (ITIN)).</li>
      <li>Government IDs of law enforcement, military, or security personnel.</li>
      <li>Records or official documentation of civil registry information (for example, marriage, birth, death, name change or gender recognition documents, except student IDs).</li>
      <li>Immigration and work status documents (for example, green cards, work permits, visas, or immigration papers)</li>
      <li>Driver's licenses or license plates, except when license plates are shared to facilitate finding missing vehicles, people or animals</li>
      <li>Credit Privacy Numbers (CPNs)</li>
    </ul>
    <li>Digital identity: Passwords, pins or codes (such as passwords of email addresses, social media accounts and accounts of streaming services) that authenticate access to an online identity</li>
  </ul>

  Personal Contact Information
  <ul>
    <li>Personal contact information of others, including personal phone numbers and personal email addresses (including work email addresses), except when made public by the individual or when shared or solicited to promote charitable causes, facilitate finding missing people, animals, or owners of missing objects, or contact a business or service providers (unless it is established that the personal contact information is shared without the consent of the individual).</li>
  </ul>

  Financial information.
  <ul>
    <li>Personal financial information about yourself or others, including:</li>
    <ul>
      <li>Non-public financial records or statements.</li>
      <li>Bank account numbers with security or pin codes.</li>
      <li>Digital payment method information with log in details, security or pin codes.</li>
      <li>Credit or debit card information with validity dates or security pins or codes.</li>
    </ul>
    <li>Financial information about businesses or organizations, except when originally shared by the organization itself (including subsequent shares with the original context intact) or shared through public reporting requirements (for example as required by stock exchanges or regulatory agencies), including:</li>
    <ul>
      <li>Non-public financial records or statements</li>
      <li>Bank account numbers accompanied by security or pin codes.</li>
      <li>Digital payment method information accompanied by log in details, security or pin codes.</li>
    </ul>
  </ul>

  Residential information
  <ul>
    <li>Private residential addresses of others (except when the residence is an official residence or embassy provided to a high-ranking public official):</li>
    <ul>
      <li>Full private residential addresses of others, including building name, GPS pins or pins on a map identifying the address (even if the pins are in an off-platform link), except when shared to promote charitable causes, facilitate finding missing people, animals, or owners of missing objects, or contact a business or service providers</li>
      <li>Partial private residential addresses of others when shared in the context of organizing protests or surveillance of the resident and the location of the residence is identified by street, city or neighborhood, postal code, or GPS pins.</li>
    </ul>
    <li>Location of safe houses: content that exposes information about safe houses by sharing any of the below, except when the safe house is actively promoting information about their facility:</li>
    <ul>
      <li>Actual address (Note: "Post Box only" is allowed.)</li>
      <li>Images of the safe house.</li>
      <li>Identifiable city/neighborhood of the safe house.</li>
      <li>Information exposing the identity of the safe house residents.</li>
    </ul>
  </ul>
  
  Medical information

  <ul>
    <li>Content sharing medical, psychological, biometric, or genetic hereditary information of others when it is clear that the information comes from medical records or other official documents, including when displayed visually or shared through audio or video.</li>
  </ul>

  Information obtained from hacked sources`,
  'Fraud and Deception': `Do not post content that provides instructions on, engages in, promotes, coordinates, encourages, facilitates, recruits for, or admits to the offering or solicitation of any of the following activities:
  <ul>
    <li>Deceiving others to generate a financial or personal benefit to the detriment of a third party or entity through:</li>
    <ul>
      <li>Investment or financial scams:</li>
      <ul>
        <li>Loan scams</li>
        <li>Advance fee scams.</li>
        <li>Gambling scams.</li>
        <li>Ponzi or pyramid schemes.</li>
        <li>Money or cash flips or money muling.</li>
        <li>Investment scams with promise of high rates of return.</li>
      </ul>
      <li>Inauthentic identity scams:</li>
      <ul>
        <li>Charity scams.</li>
        <li>Romance or impersonation scams.</li>
        <li>Establishment of false businesses or entities.</li>
      </ul>
      <li>Product or rewards scams:</li>
      <ul>
        <li>Grant and benefits scams.</li>
        <li>Tangible, spiritual or illuminati scams.</li>
        <li>Insurance scams, including ghost broking.</li>
        <li>Fake jobs, work from home or get-rich-quick scams.</li>
        <li>Debt relief or credit repair scams.</li>
      </ul>
    </ul>
    <li>Engaging and co-ordinating with others to fraudulently generate a financial or personal benefit at a loss for a third party, such as people, businesses or organisations through:</li>
    <ul>
      <li>Fake documents or financial instruments by creating, selling, or buying of:</li>
      <ul>
        <li>Fake or forged documents.</li>
        <li>Fake or counterfeit currency or vouchers.</li>
        <li>Fake or forged educational and professional certificates.</li>
        <li>Money laundering</li>
      </ul>
      <li>Stolen information, goods, or services by:</li>
      <ul>
        <li>Credit card fraud and goods or property purchases with stolen financial information</li>
        <li>Trading, selling or buying of:</li>
        <ul>
          <li>Personal Identifiable Information.</li>
          <li>Fake and misleading user reviews or ratings.</li>
          <li>Credentials for subscription services.</li>
          <li>Coupons.</li>
          <li>Future exam papers or answer sheets.</li>
        </ul>
      </ul>
      <li>Betting manipulation (for example match fixing).</li>
      <li>Manipulation of measuring devices such as electricity or water meters in order to bypass their authorised or legal use.</li>
    </ul>
  </ul>`,
  Phishing: `
  <strong>Overview</strong>
  <br />You are strictly prohibited from transmitting any messages that are intended for or resemble social engineering attacks, including but not limited to simulated phishing attempts, credential harvesting, and other deceptive activities designed to manipulate individuals into divulging sensitive information.
  <br />
  <br />
  <strong>Definitions</strong>
  <ul>
    <li><strong>Phishing:</strong> The practice of sending fraudulent communications that appear to come from a legitimate source, typically to steal sensitive data such as login information or financial details.</li>
    <li><strong>Social Engineering:</strong> The psychological manipulation of people into performing actions or divulging confidential information.</li>
    <li><strong>Simulated Phishing:</strong> Security testing activities that mimic phishing attacks to assess and improve security awareness among users.</li>
  </ul>
  <strong>Prohibited Activities</strong>
  <br />
  The following activities are strictly prohibited on our platform:
  <ul>
    <li><strong>Simulated Phishing Attacks:</strong> Sending messages that are intended to mimic phishing attacks for the purpose of testing security awareness or any other reason.
    <ul>
      <li><strong>Example:</strong> Sending an email that appears to be from a trusted source, asking users to click on a link and enter their login credentials to assess their susceptibility to phishing.</li>
    </ul>
    </li>
    <li><strong>Credential Harvesting:</strong> Any attempt to collect or steal user credentials through deceptive means.
    <ul>
      <li><strong>Example:</strong> Creating a fake login page that mimics a legitimate service and sending a link to users, prompting them to enter their username and password.</li>
    </ul>
    </li>
    <li><strong>Deceptive Requests for Sensitive Information:</strong> Sending messages that deceive users into providing personal, financial, or confidential information.
    <ul>
      <li><strong>Example:</strong> Sending a message posing as a bank representative, asking users to provide their account details for verification purposes.</li>
    </ul>
    </li>
    <li><strong>Impersonation:</strong>Pretending to be someone else, especially a trusted entity, to gain unauthorized access to information or systems.
    <ul>
      <li><strong>Example:</strong> Sending a message that appears to come from a company’s IT department, asking employees to reset their passwords by clicking on a provided link.</li>
    </ul>
    </li>
  </ul>
  <strong>Allowed Activities</strong>
  <br />
  The following activities are permitted, provided they do not involve deception or the intent to manipulate users into compromising their security:
  <ul>
    <li><strong>Legitimate Security Notifications:</strong> Sending genuine alerts and notifications about security incidents or required actions, without any intent to deceive.
    <ul>
      <li><strong>Example:</strong> Sending an official email from your company’s security team notifying users of a recent breach and advising them to change their passwords through the official company portal.</li>
    </ul>
    </li>
    <li><strong>User Education:</strong> Providing educational content on how to recognize and avoid phishing and social engineering attacks.
    <ul>
      <li><strong>Example:</strong> Sending an informative newsletter with tips on how to identify phishing emails and protect against online scams.</li>
    </ul>
    </li>
    <li><strong>Transactional Communications:</strong> Sending messages related to legitimate transactions or interactions, such as purchase confirmations, appointment reminders, and support ticket updates.
    <ul>
      <li><strong>Example:</strong> Sending a confirmation email after a user makes a purchase on your platform, detailing the order and providing customer support information.</li>
    </ul>
    </li>
    <li><strong>Surveys and Feedback Requests:</strong> Sending requests for user feedback or surveys, provided they are clearly identified as such and do not request sensitive information.
    <ul>
      <li><strong>Example:</strong> Sending a customer satisfaction survey after a service interaction, ensuring it is branded and authenticated as coming from your legitimate service.</li>
    </ul>
    </li>
  </ul>`,
};

export default function DemoInputPolicyScreen(props: {
  goToNextScreen: () => void;
  goBack: () => void;
  policyName: string;
}) {
  const { goToNextScreen, goBack, policyName } = props;
  const [definition, setDefinition] = useState(
    defaultPolicyTexts[policyName as keyof typeof defaultPolicyTexts] ??
      'Do not post content that...',
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [generatePolicyDefinition, { loading: generatedPolicyLoading }] =
    useGQLGenerateDefaultPolicyDefinitionLazyQuery({
      variables: { input: { policyName } },
      onCompleted(data) {
        switch (data.generatePolicyText.__typename) {
          case 'GeneratedPolicyTextCompletionSuccess':
            setDefinition(data.generatePolicyText.policyText);
            break;
          case 'GeneratedPolicyTextCompletionError':
            setErrorMessage(`Error generating policy text. Please try again.`);
            break;
        }
      },
      onError(e) {
        setErrorMessage('Error generating policy text. Please try again.');
      },
    });

  const errorModal = (
    <CoveModal
      title="Error"
      visible={errorMessage != null}
      onClose={() => setErrorMessage(undefined)}
      footer={[
        {
          title: 'OK',
          onClick: () => setErrorMessage(undefined),
        },
      ]}
    >
      {errorMessage}
    </CoveModal>
  );

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-start justify-center h-full max-w-2xl text-start">
        <div className="pb-6 text-3xl font-bold">
          What is your <span className="italic">internal</span> definition of{' '}
          {policyName}
        </div>
        <div className="pb-4 text-base text-slate-500">
          We're going to train your AI model the same way you might train your
          human moderators, which means your AI model needs a detailed, nuanced
          understanding of your {policyName} policy.
        </div>
        <MarkdownTextInput
          text={
            generatedPolicyLoading
              ? 'Generating policy definition...'
              : definition ?? ''
          }
          setText={setDefinition}
          title={policyName}
        />
        <div
          className={`flex flex-row items-center self-end justify-center my-4 ${
            generatedPolicyLoading
              ? 'text-slate-300'
              : 'text-cove-blue cursor-pointer hover:text-cove-blue-hover'
          }`}
          onClick={() => {
            if (!generatedPolicyLoading) {
              setDefinition('Generating policy definition...');
              generatePolicyDefinition();
            }
          }}
        >
          {generatedPolicyLoading ? (
            <LoadingOutlined spin className="mr-2" />
          ) : (
            <ThunderboltOutlined className="mr-2" />
          )}
          {generatedPolicyLoading
            ? 'Generating...'
            : 'Generate Policy Definition'}
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: `Create ${policyName} AI model`,
          onClick: goToNextScreen,
          disabled: definition.length === 0 || generatedPolicyLoading,
        }}
        onBack={goBack}
      />
      {errorModal}
    </div>
  );
}

import { Typography, type TypographyProps } from "./ui/Typography";

type FormattedDateProps = TypographyProps & { date: Date };

export function FormattedDate({ date, ...props }: FormattedDateProps) {
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "short",
    year: "numeric",
    day: "2-digit",
  });

  return <Typography {...props}>{formatter.format(date)}</Typography>;
}

import { Select } from 'antd';

import { selectFilterByLabelOption } from '@/webpages/dashboard/components/antDesignUtils';

import { RuleFormLeafCondition } from '../../../../rules/types';
import { ManualReviewQueueRoutingStaticTokenField } from '../../ManualReviewQueueRoutingStaticField';

const { Option } = Select;

export default function ManualReviewQueueRuleConditionMediaMatchingValues(props: {
  condition: RuleFormLeafCondition;
  editing: boolean;
  onUpdateMediaMatchingValues: (values: string[]) => void;
}) {
  const { condition, editing, onUpdateMediaMatchingValues } = props;

  const mediaBanks = [
    'Nazi Symbols Bank',
    'War Graphic Violence Bank',
    'Terrorism Graphic Violence Bank',
    'Male Nudity Bank',
    'Female Nudity Bank',
    'Child Sexual Abuse Material Bank',
    'Sex Trafficking Bank',
    'Weapons and Dangerous Goods Bank',
    'Drugs and Alcohol Bank',
  ];
  return (
    <div className="flex flex-col items-start">
      {editing ? (
        <Select
          placeholder="Select media bank(s)"
          defaultValue={condition.matchingValues?.strings}
          value={condition.matchingValues?.strings}
          onChange={(values) => onUpdateMediaMatchingValues([...values])}
          allowClear
          showSearch
          filterOption={selectFilterByLabelOption}
          dropdownMatchSelectWidth={false}
        >
          {mediaBanks.map((bank) => (
            <Option key={bank} value={bank} label={bank}>
              {bank}
            </Option>
          ))}
        </Select>
      ) : (
        <ManualReviewQueueRoutingStaticTokenField
          tokens={condition.matchingValues?.strings ?? []}
        />
      )}
    </div>
  );
}

import { gql } from '@apollo/client';
import { Select } from 'antd';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import FullScreenLoading from '../../components/common/FullScreenLoading';
import CoveButton from '../dashboard/components/CoveButton';
import CoveModal from '../dashboard/components/CoveModal';
import DashboardHeader from '../dashboard/components/DashboardHeader';
import RowMutations from '../dashboard/components/RowMutations';
import {
  ColumnProps,
  DateRangeColumnFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
} from '../dashboard/components/table/filters';
import {
  boolSort,
  stringSort,
  userRoleSort,
} from '../dashboard/components/table/sort';
import Table from '../dashboard/components/table/Table';
import UserWithAvatar from '../dashboard/components/UserWithAvatar';

import {
  GQLUserPermission,
  GQLUserRole,
  namedOperations,
  useGQLApproveUserMutation,
  useGQLDeleteUserMutation,
  useGQLManageUsersQuery,
  useGQLRejectUserMutation,
  useGQLUpdateRoleMutation,
} from '../../graphql/generated';
import { userHasPermissions } from '../../routing/permissions';
import { titleCaseEnumString } from '../../utils/string';
import { getRoleDescription } from './ManageUsersFormUtils';
import ManageUsersInviteUserSection from './ManageUsersInviteUserSection';

const { Option } = Select;

export enum ManageUsersModalState {
  DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  EDIT_USER = 'EDIT_USER',
  ACTION_FAILED = 'ACTION_FAILED',
}

gql`
  query ManageUsers {
    myOrg {
      id
      name
      hasNCMECReportingEnabled
      users {
        id
        firstName
        lastName
        email
        role
        createdAt
        approvedByAdmin
        rejectedByAdmin
      }
    }
    me {
      id
      email
      firstName
      lastName
      permissions
    }
  }

  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }

  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input)
  }

  mutation ApproveUser($id: ID!) {
    approveUser(id: $id)
  }

  mutation RejectUser($id: ID!) {
    rejectUser(id: $id)
  }
`;

export default function ManageUsers() {
  // Lowest level permission required to manage users
  const requiredPermissions = [GQLUserPermission.ManageOrg];

  const [modalState, setModalState] = useState<
    ManageUsersModalState | undefined
  >(undefined);
  const [selectedUser, setSelectedUser] = useState<
    { id: string; name: string; role: GQLUserRole } | undefined
  >(undefined);
  const [selectedRole, setSelectedRole] = useState<GQLUserRole | undefined>(
    undefined,
  );
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGQLManageUsersQuery();
  const users = data?.myOrg?.users;
  const hasNCMECReportingEnabled = data?.myOrg?.hasNCMECReportingEnabled;

  const hideModal = () => setModalState(undefined);

  const mutationCallbacks = {
    onError: () =>
      showModal({ newModalState: ManageUsersModalState.ACTION_FAILED }),
    onCompleted: () => {
      hideModal();
      refetch();
    },
  };

  const [deleteUser, { loading: deleteUserLoading }] =
    useGQLDeleteUserMutation(mutationCallbacks);
  const [updateRole, { loading: updateRoleLoading }] =
    useGQLUpdateRoleMutation(mutationCallbacks);
  const [approveUser, { loading: approveUserLoading }] =
    useGQLApproveUserMutation(mutationCallbacks);
  const [rejectUser, { loading: rejectUserLoading }] =
    useGQLRejectUserMutation(mutationCallbacks);

  const showModal = useCallback(
    (opts: {
      newModalState: ManageUsersModalState;
      userId?: string;
      event?: MouseEvent;
    }) => {
      const { newModalState, userId, event } = opts;
      // This ensures that the row's onClick isn't called because
      // the row is the parent component
      event?.stopPropagation();
      const user = users?.find((it) => it.id === userId);
      if (user) {
        setSelectedUser({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          role: user.role!,
        });
      }
      setModalState(newModalState);
    },
    [users],
  );

  const onDeleteUser = () => {
    deleteUser({
      variables: {
        id: selectedUser!.id,
      },
      refetchQueries: [namedOperations.Query.ManageUsers],
    });
  };

  const onEditUser = (role: GQLUserRole) => {
    updateRole({
      variables: {
        input: {
          id: selectedUser!.id,
          role,
        },
      },
      refetchQueries: [namedOperations.Query.ManageUsers],
    });
  };

  const mutations = useCallback(
    (id: string) => {
      return (
        <RowMutations
          onEdit={(event) =>
            showModal({
              newModalState: ManageUsersModalState.EDIT_USER,
              userId: id,
              event,
            })
          }
          onDelete={(event) =>
            showModal({
              newModalState: ManageUsersModalState.DELETE_CONFIRMATION,
              userId: id,
              event,
            })
          }
          // Assertion safe, see onDeleteUser above
          canDelete={id !== data?.me?.id}
          deleteDisabledTooltipTitle={
            'You cannot delete your own account. ' +
            'If you are leaving your company and need to ' +
            'delete the account, ensure that another user in your ' +
            'organization is assigned the Admin role, and request ' +
            'that they delete your account.'
          }
        />
      );
    },
    [data?.me?.id, showModal],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Filter: (props: ColumnProps) =>
          DefaultColumnFilter({
            columnProps: props,
            accessor: 'name',
            placeholder: 'Jane Smith',
          }),
        filter: 'text',
        sortType: stringSort,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: (props: ColumnProps) =>
          DefaultColumnFilter({
            columnProps: props,
            accessor: 'email',
            placeholder: 'jane@mywebsite.com',
          }),
        filter: 'text',
        sortType: stringSort,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Filter: (props: ColumnProps) =>
          SelectColumnFilter({
            columnProps: props,
            accessor: 'role',
            placeholder: 'Filter by role',
          }),
        filter: 'includes',
        sortType: userRoleSort,
      },
      {
        Header: 'Approval Status',
        accessor: 'approvalStatus',
        Filter: (props: ColumnProps) =>
          SelectColumnFilter({
            columnProps: props,
            accessor: 'approvalStatus',
            placeholder: 'Filter by status',
          }),
        filter: 'includes',
        sortType: boolSort,
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Filter: (props: ColumnProps) =>
          DateRangeColumnFilter({
            columnProps: props,
            accessor: 'dateCreated',
            placeholder: '',
          }),
        filter: 'dateRange',
        sortDescFirst: true,
        sortType: stringSort,
      },
      {
        Header: '',
        accessor: 'mutations', // accessor is the "key" in the data
        canSort: false,
      },
    ],
    [],
  );

  const dataValues = useMemo(
    () =>
      users
        ?.filter((user: any) => !user.rejectedByAdmin)
        .map((user: any) => ({
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role: titleCaseEnumString(user.role),
          approvalStatus: Boolean(user.approvedByAdmin)
            ? 'Approved'
            : 'Pending',
          id: user.id,
          dateCreated: new Date(Number(user.createdAt))
            .toISOString()
            .split('T')[0],
        })),
    [users],
  );

  const tableData = useMemo(
    () =>
      dataValues?.map((values: any) => ({
        mutations: mutations(values.id),
        name: <UserWithAvatar name={values.name} />,
        email: <div>{values.email}</div>,
        role: <div>{values.role}</div>,
        approvalStatus:
          values.approvalStatus === 'Approved' ? (
            <div>Approved</div>
          ) : (
            <div className="flex gap-2">
              <CoveButton
                title="Approve"
                type="green"
                size="small"
                onClick={async () =>
                  approveUser({
                    variables: { id: values.id },
                  })
                }
                loading={approveUserLoading}
              />
              <CoveButton
                title="Reject"
                type="danger"
                size="small"
                onClick={async () =>
                  rejectUser({
                    variables: { id: values.id },
                  })
                }
                loading={rejectUserLoading}
              />
            </div>
          ),
        dateCreated: <div className="flex min-w-20">{values.dateCreated}</div>,
        values,
      })),
    [
      dataValues,
      mutations,
      approveUserLoading,
      rejectUserLoading,
      approveUser,
      rejectUser,
    ],
  );

  if (loading) {
    return <FullScreenLoading />;
  }

  if (error) {
    throw error;
  }
  const permissions = data!.me?.permissions;
  if (!permissions || !userHasPermissions(permissions, requiredPermissions)) {
    navigate('/settings');
  }

  const {
    title = '',
    body = null,
    buttons = [],
  } = (() => {
    switch (modalState) {
      case ManageUsersModalState.ACTION_FAILED:
        return {
          title: 'Something went wrong',
          body: (
            <div>
              We encountered an issue trying to process your request. Please try
              again.
            </div>
          ),
          buttons: [],
        };
      case ManageUsersModalState.DELETE_CONFIRMATION:
        return {
          title: 'Delete User',
          body: <div>Are you sure you want to delete this user?</div>,
          buttons: [
            {
              title: 'Cancel',
              onClick: hideModal,
              type: 'secondary' as const,
            },
            {
              title: 'Delete',
              onClick: onDeleteUser,
              type: 'danger' as const,
              loading: deleteUserLoading,
            },
          ],
        };
      case ManageUsersModalState.EDIT_USER:
        return {
          title: 'Edit Role',
          body: (
            <div className="max-w-[600px]">
              <div className="flex flex-col items-start">
                <div className="font-bold mb-2">Select Role</div>
                <Select
                  className="!w-full"
                  value={selectedRole ?? selectedUser?.role}
                  onSelect={(value) => setSelectedRole(value)}
                  dropdownMatchSelectWidth={false}
                >
                  {Object.values(GQLUserRole)
                    // If the org doesn't have NCMEC reporting enabled, don't show the CHILD_SAFETY_MODERATOR role
                    .filter((role) =>
                      !hasNCMECReportingEnabled
                        ? role !== GQLUserRole.ChildSafetyModerator
                        : true,
                    )
                    .map((roleType) => (
                      <Option
                        key={roleType}
                        value={roleType}
                        label={titleCaseEnumString(roleType)}
                      >
                        {titleCaseEnumString(roleType)}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="divider !mb-6 !mt-8" />
              <div className="font-bold text-2xl">Roles</div>
              <div className="h-4" />
              <div className="flex flex-col text-start">
                {Object.values(GQLUserRole)
                  // If the org doesn't have NCMEC reporting enabled, don't show the CHILD_SAFETY_MODERATOR role
                  .filter((role) =>
                    !hasNCMECReportingEnabled
                      ? role !== GQLUserRole.ChildSafetyModerator
                      : true,
                  )
                  .map((role) => (
                    <div key={role} className="flex flex-col">
                      <div className="text-base font-bold">
                        {titleCaseEnumString(role)}
                      </div>
                      <div className="pt-1 pb-4">
                        {getRoleDescription(role)}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ),
          buttons: [
            {
              title: 'Cancel',
              onClick: hideModal,
              type: 'secondary' as const,
            },
            {
              title: 'Save Role',
              onClick: () => onEditUser(selectedRole!),
              loading: updateRoleLoading,
              disabled: !selectedRole || selectedUser?.role === selectedRole,
            },
          ],
        };
      default:
        return {};
    }
  })();

  const modal = (
    <CoveModal
      visible={modalState !== undefined}
      onClose={hideModal}
      title={title}
      footer={buttons}
    >
      {body}
    </CoveModal>
  );

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Users</title>
      </Helmet>
      <DashboardHeader title="Users" subtitle="" />
      {/* @ts-ignore */}
      <Table columns={columns} data={tableData} />
      <div className="divider my-9" />
      <ManageUsersInviteUserSection />
      {modal}
    </div>
  );
}

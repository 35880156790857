import {
  AddFiles,
  Clipboard,
  EyeSlash,
  GridAlt,
  KeywordResearchFilled,
  Tap,
} from '@/icons';

import { FeatureTable } from '../../FeatureTable';

export function More() {
  return (
    <FeatureTable
      title="A whole lot more"
      items={[
        {
          title: 'Custom policies',
          description:
            'Use your nuanced policies to train AI models that enforce your policies just like you would.',
          icon: AddFiles,
        },
        {
          title: 'Signals library',
          description:
            'Combine your Cove AI models with behavioral signals, regex matching, or 3rd party AI models like Perspective and AWS Rekognition.',
          icon: GridAlt,
        },
        {
          title: 'Custom actions',
          description:
            'Enforce your policies through your unique moderation actions that fit your platform’s needs.',
          icon: Tap,
        },
        {
          title: 'Investigation tool',
          description:
            'Conduct investigations into a piece of content’s or user’s history, past violations, and any other surrounding context.',
          icon: KeywordResearchFilled,
        },
        {
          title: 'Reporting & Compliance',
          description:
            'Generate transparency reports and export data for regulatory agencies and compliance requirements.',
          icon: Clipboard,
        },
        {
          title: 'Moderator Safety',
          description:
            'Designed to protect moderators from exposure to harmful content through tools like blurring, grayscale, and more.',
          icon: EyeSlash,
        },
      ]}
    />
  );
}

import { Form, Select } from 'antd';

import { selectFilterByLabelOption } from '@/webpages/dashboard/components/antDesignUtils';

import { ConditionLocation, RuleFormLeafCondition } from '../../../types';

const { Option } = Select;

export default function RuleFormConditionMediaMatchingValues(props: {
  condition: RuleFormLeafCondition;
  location: ConditionLocation;
  onUpdateMatchingValues: (
    matchingValues: RuleFormLeafCondition['matchingValues'],
  ) => void;
}) {
  const { condition, location, onUpdateMatchingValues } = props;
  const { conditionSetIndex, conditionIndex } = location;

  const mediaBanks = [
    'Nazi Symbols Bank',
    'War Graphic Violence Bank',
    'Terrorism Graphic Violence Bank',
    'Male Nudity Bank',
    'Female Nudity Bank',
    'Child Sexual Abuse Material Bank',
    'Sex Trafficking Bank',
    'Weapons and Dangerous Goods Bank',
    'Drugs and Alcohol Bank',
  ];
  return (
    <Form.Item
      className="!mb-0 !pl-4 !align-middle"
      name={[conditionSetIndex, conditionIndex, 'media_bank']}
      initialValue={condition.matchingValues}
    >
      {/* Needs to be wrapped in a div for the state to work properly */}
      <div className="flex flex-col items-start">
        <Select
          placeholder="Select media bank(s)"
          defaultValue={condition.matchingValues?.strings}
          value={condition.matchingValues?.strings}
          onChange={(values) => onUpdateMatchingValues({ strings: values })}
          allowClear
          showSearch
          filterOption={selectFilterByLabelOption}
          dropdownMatchSelectWidth={false}
        >
          {mediaBanks.map((bank) => (
            <Option key={bank} value={bank} label={bank}>
              {bank}
            </Option>
          ))}
        </Select>
      </div>
    </Form.Item>
  );
}

import { m } from 'framer-motion';

import { FeatureSection } from '../landing/FeatureSection';

export function Team() {
  return (
    <FeatureSection
      orientation="rtl"
      title="Our Team"
      description={`      
Our talented team brings experience from category-defining companies like Meta, Amazon, Twitter, Shopify, and Etsy. They’ve pushed the boundaries of Trust & Safety innovation and bring diverse perspectives that are critical for us to keep the internet safe for everyone.

We believe these tools should be accessible to the people who write content policies, not just engineers. With Cove, teams can build Trust & Safety with both agility and thoughtfulness, and they can focus their engineering resources on core product experiences.

That's the only way we can ensure that the Internet fulfills its promise.
    `.trim()}
    >
      <m.img
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ amount: 0.25, once: true }}
        transition={{ duration: 1 }}
        src="/team.png"
        alt="Our team"
        className="w-full max-w-[32rem]"
      />
    </FeatureSection>
  );
}

import { m } from 'framer-motion';

import { cn } from '../../../../lib/utils';
import { Typography } from '../../../ui/Typography';
import { Highlight } from './Highlight';

export function ViolencePolicy() {
  return (
    <div>
      <m.img
        initial={{ scale: 0.95, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 100, transition: { duration: 0.4 } }}
        viewport={{ once: true }}
        className="block lg:hidden absolute home-shadow-lg top-8 right-8 scale-100 rounded-lg overflow-hidden bg-[#FFFAF1] origin-top-left w-80"
        src="/graphics/violence-policy.svg"
      />
      <m.div
        initial={{ scale: 0.55, opacity: 0 }}
        whileInView={{
          scale: 0.95,
          opacity: 100,
          transition: { duration: 0.4 },
        }}
        viewport={{ once: true }}
        className="hidden lg:block absolute home-shadow-lg top-8 right-8 rounded-lg overflow-hidden bg-[#FFFAF1] w-80 origin-top-left"
      >
        <div className="font-semibold text-xs text-center py-2 text-inverted bg-[#7FB8FC]">
          Editing Violence Policy
        </div>
        <Typography
          variant="body"
          size="sm"
          as="div"
          className={cn('py-3 px-5 !leading-loose', 'text-xs')}
        >
          Do not post threats that could lead to death (and other forms of
          high-severity violence) and admission of past violence targeting
          people or places where threat is defined as any of the following:
          <ul className="list-disc my-0 pl-8 py-2">
            <li>
              Statements of intent to{' '}
              <Highlight>commit high-severity violence.</Highlight> This
              includes content where a symbol represents the target and/or
              includes a{' '}
              <Highlight>
                visual of an armament or method to represent violence.
              </Highlight>
            </li>
            <li>
              Calls for high-severity violence including content where{' '}
              <Highlight>no target is specified</Highlight> but a symbol
              represents the target and/or
            </li>
          </ul>
        </Typography>
      </m.div>
    </div>
  );
}

import { useGQLUserAndOrgQuery } from '@/graphql/generated';
import _ from 'lodash';
import Papa from 'papaparse';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import CoveSelect from '@/components/common/CoveSelect';
import FullScreenLoading from '@/components/common/FullScreenLoading';

type LabelType = 'violates' | 'does_not_violate' | 'borderline' | 'unlabeled';

const updateStoredData = (
  fileName: string,
  csvData: Record<string, unknown>[],
) => {
  const storedData = localStorage.getItem('labelingData');
  const parsedData = storedData ? JSON.parse(storedData) : {};
  parsedData[fileName] = { csvData };
  localStorage.setItem('labelingData', JSON.stringify(parsedData));
};

const getAllStoredData = (): Record<
  string,
  {
    labels: Record<string, LabelType>;
    csvData: Record<string, unknown>[];
  }
> => {
  const storedData = localStorage.getItem('labelingData');
  return storedData ? JSON.parse(storedData) : {};
};

export default function SampleViewer() {
  const { data, loading } = useGQLUserAndOrgQuery();
  const userEmail = data?.me?.email;

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [selectedJobName, setSelectedJobName] = useState<string | undefined>(
    undefined,
  );
  const [csvData, _setCsvData] = useState<
    { id: string; [key: string]: unknown }[]
  >([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dropdownOptions, setDropdownOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const resetState = useCallback(() => {
    setSelectedJobName(undefined);
    setCsvData([]);
    setFileName(undefined);
    setSelectedIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCsvData = useCallback(
    (data: { id: string; [key: string]: unknown }[]) => {
      _setCsvData(data);
    },
    [],
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const items: Record<string, unknown>[] = [];
          for (const item of results.data) {
            if (
              typeof item === 'object' &&
              item !== null &&
              'id' in item &&
              typeof item.id === 'string'
            ) {
              items.push(_.omit(item, '_1', '_2', '""'));
            }
          }

          const newCsvData = items as { id: string; [key: string]: unknown }[];
          console.log({ newCsvData });
          const newFileName = file.name;
          const newSelectedJobName = file.name;
          const newSelectedIndex = 0;
          const newDropdownOptions = [
            ...dropdownOptions,
            { value: file.name, label: file.name },
          ];

          setCsvData(newCsvData);
          setFileName(newFileName);
          setSelectedJobName(newSelectedJobName);
          setSelectedIndex(newSelectedIndex);
          setDropdownOptions(newDropdownOptions);

          updateStoredData(newFileName, newCsvData);
        },
      });
    }
  };

  const storedData = getAllStoredData();
  const storedJobNames = Object.keys(storedData);
  useEffect(() => {
    setDropdownOptions(
      storedJobNames.map((jobName) => ({
        value: jobName,
        label: jobName,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (
    !userEmail?.includes('@getcove.com') &&
    !userEmail?.includes('@example.com')
  ) {
    return <Navigate replace to="/" />;
  }

  const jobSelector = (
    <CoveSelect
      mode="single"
      placeholder="Select or upload job"
      value={selectedJobName}
      onSelect={(value) => {
        if (value === 'upload') {
          setSelectedJobName(undefined);
          fileInputRef.current?.click();
        } else if (value === 'delete') {
          localStorage.removeItem('labelingData');
          resetState();
        } else {
          // Set state variables for existing job
          const jobData = storedData[value];
          if (jobData) {
            setCsvData(
              jobData.csvData as { id: string; [key: string]: unknown }[],
            );
            setSelectedIndex(0);
            setSelectedJobName(value);
          } else {
            alert('Job data not found. Reupload csv file.');
          }
        }
      }}
      onDeselect={resetState}
      options={[
        ...dropdownOptions,
        { value: 'upload', label: 'Upload New File' },
        ...(dropdownOptions.length > 0
          ? [{ value: 'delete', label: 'Delete All' }]
          : []),
      ].filter(
        (option): option is { value: string; label: string } => option !== null,
      )}
    />
  );

  const contentSection = (
    <div className="columns-2 ">
      {csvData.map((itemData, index) => {
        return (
          <ItemDisplay key={itemData.id} itemData={itemData} index={index} />
        );
      })}
    </div>
  );

  return (
    <div className="p-8">
      <h1>Ethan's CSV Viewer Deluxe</h1>
      {jobSelector}
      <input
        type="file"
        ref={fileInputRef}
        // Hidden because we're using the CoveSelect to trigger the file upload,
        // we just need to set the ref here so that the file input is created
        style={{ display: 'none' }}
        accept=".csv"
        onChange={handleFileUpload}
      />
      {csvData.length > 0 && (
        <div className="mt-4">
          <h3>
            {selectedIndex >= csvData.length
              ? 'All done!'
              : `Labeling Item ${selectedIndex + 1} of ${csvData.length}`}
          </h3>
          <div>{contentSection}</div>
        </div>
      )}
    </div>
  );
}
function ItemDisplay(props: {
  itemData: {
    [key: string]: unknown;
    id: string;
  };
  index: number;
}) {
  const itemDiv = Object.entries(props.itemData)
    .sort(([key_a, _], [key_b, __]) =>
      key_a === 'images' ? -1 : key_b === 'images' ? 1 : 0,
    )
    .map(([key, value]) =>
      key.length > 0 ? (
        <div key={key} className="mb-4">
          <strong>{key}:</strong>
          {key === 'images' ? (
            // NB: this div is needed to make sure the image renders below
            // the label instead of next to it
            <div>
              <img src={value as string} className="h-64" alt="listing_image" />
            </div>
          ) : (
            <div className="mt-1 ml-4">
              {(value as string)
                .replace(/\\n/g, '\n')
                .replace(/\\t/g, '\t')
                .replace(/&quot;/g, '"')
                .replace(/&#39;/g, "'")
                .replace(/\\/g, '')}
            </div>
          )}
        </div>
      ) : null,
    );

  const labelMap = {
    '0': 'does_not_violate',
    '1': 'violates',
    '0.5': 'borderline',
  };
  return (
    <div key={props.index} className="mb-4">
      <pre className="p-4 overflow-auto bg-gray-100 rounded-md text-wrap">
        <div className="mb-4">
          <strong>Item #{props.index}</strong>
        </div>
        <div className="mb-4">
          <strong>
            Current Label:{' '}
            {labelMap[props.itemData['label'] as '0' | '1' | '0.5'] ||
              'unlabeled'}
          </strong>
        </div>
        {itemDiv}
      </pre>
    </div>
  );
}

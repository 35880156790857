import { m } from "framer-motion";

import { AnimatedPills } from "./AnimatedPills";
import { ViolencePolicy } from "./ViolencePolicy";

export function CustomModels() {
  return (
    <div className="h-[400px] scale-75 md:scale-100 origin-left w-[624px] relative">
      <m.img
        initial={{ y: 10, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 100,
          transition: { duration: 0.6, delay: 0.4 },
        }}
        viewport={{ once: true }}
        className="absolute bottom-4 left-4 w-[22rem] -translate-y-6 -translate-x-8"
        src="/graphics/violence.svg"
      />
      <AnimatedPills />
      <ViolencePolicy />
    </div>
  );
}

import {
  EyeSlashFilled,
  Graph,
  KeywordResearch,
  LayersAlt1Filled,
  MergeFilled,
  TimeSettings1Filled,
} from '@/icons';
import { domAnimation, LazyMotion } from 'framer-motion';
import { useState } from 'react';

import { InvestigationTool } from '../components/animations/moderation/InvestigationTool';
import { Needs } from '../components/animations/moderation/Needs';
import { Routing } from '../components/animations/moderation/Routing';
import { SingleTool } from '../components/animations/moderation/SingleTool';
import { FAQ } from '../components/FAQ';
import { FeatureTable } from '../components/FeatureTable';
import RequestDemoModal from '../components/RequestDemoModal';
import { FeatureSection } from '../components/sections/landing/FeatureSection';
import { Button } from '../components/ui/Button';

const faqQuestions = [
  {
    question: 'Can I use the Moderator Console by itself?',
    answer:
      'Yes, all our products can be used individually or as part of a larger suite based on the solutions you need. ',
  },
  {
    question: 'Can I customize actions and decisions per review queue?',
    answer:
      'Yes, you can customize which moderators can access a particular queue, which content ends up in that queue, and the actions that can be taken in that queue.',
  },
  {
    question:
      'Do my decisions get fed back into my Cove AI Models as labeled data?',
    answer:
      "Yes! If you've built custom AI models through Cove, your moderators' decisions can be fed back into your models so they constantly improve.",
  },
];

export default function ModerationConsole() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <LazyMotion features={domAnimation}>
      <FeatureSection
        size="md"
        className="!pt-40"
        title="A <span>single powerful tool</span> for your entire content review workflow"
        description="A clean and context-rich moderator console designed with safety in mind. We make the review process easy and comprehensive, with everything you need when reviewing suspicious content and users."
        cta={
          <Button onClick={showRequestDemoModal}>
            Get started with Moderator Console
          </Button>
        }
      >
        <SingleTool />
      </FeatureSection>

      <FeatureSection
        label="Dynamic review queues"
        title="Route reports to the relevant review queue with ease"
        description="Configure your own review queues, decide who can access each queue, and easily route reports to the right queue with custom rules. You have full control over the moderator experience."
        cta={
          <Button onClick={showRequestDemoModal}>
            Learn more about review queues
          </Button>
        }
      >
        <Routing />
      </FeatureSection>

      <FeatureSection
        label="Dig deeper"
        title="Empower context-rich decision making with all the relevant data"
        description="Making accurate moderation decisions requires seeing relevant surrounding context. When moderators review a piece of content in Cove, they can see all the context they need, including its full enforcement history, the history of the content's author, and other relevant information related to the content."
        cta={<Button onClick={showRequestDemoModal}>Request a demo</Button>}
      >
        <InvestigationTool />
      </FeatureSection>

      <FeatureSection
        label="Seamless metrics"
        title="Get detailed breakdowns of moderator performance"
        description="See real-time metrics about how your moderators are performing, what decisions they're making, how efficiently they're working, what content is getting reported, and more."
        cta={
          <Button onClick={showRequestDemoModal}>
            Explore Cove's moderation metrics
          </Button>
        }
      >
        <Needs />
      </FeatureSection>

      <FeatureTable
        title="A comprehensive moderation solution"
        items={[
          {
            icon: TimeSettings1Filled,
            title: 'Quick setup',
            description:
              'Everything you need in a moderation console in one place – go from 0-1 in minutes with minimal set up.',
          },
          {
            icon: MergeFilled,
            title: 'Dynamic routing & triaging',
            description:
              'Create simple rules to route reports into the right queues to manage moderator bandwidth and minimize Time To Resolution',
          },
          {
            icon: LayersAlt1Filled,
            title: 'Custom actions',
            description:
              'Each queue can be customized to limit the scope of actions that can be taken.',
          },
          {
            icon: EyeSlashFilled,
            title: 'Safety settings',
            description:
              "Personalized blur and grayscale settings minimize moderators' exposure to harmful content.",
          },
          {
            icon: Graph,
            title: 'Moderator notes',
            description:
              'Moderators can leave notes on tickets to enable seamless communication as content is escalated or triaged.',
          },
          {
            icon: KeywordResearch,
            title: 'Context-rich investigations',
            description:
              'Conduct in-depth investigations by reviewing the entire history and surrounding context around suspicious entities.',
          },
        ]}
      />

      <FAQ title="Frequently Asked Questions" questions={faqQuestions} />

      <FeatureSection
        title="Ready to start using Moderator Console?"
        orientation="ttb"
        description="Schedule a demo to chat with someone on our team to see how Cove’s Moderator Console can be a fit for your team."
        cta={
          <Button className="mt-6 sm:mt-0" onClick={showRequestDemoModal}>
            Schedule a demo
          </Button>
        }
        gap={false}
      />
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="MODERATION_CONSOLE"
      />
    </LazyMotion>
  );
}

import { m } from 'framer-motion';

export function PurpleBlur() {
  return (
    <m.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="absolute z-10 -top-1/2 -left-1/2 w-[200%] h-[150%] bg-[radial-gradient(circle_closest-side,theme('colors.primary/0.1')_0%,theme('colors.primary/0')_90%)]"
    ></m.div>
  );
}

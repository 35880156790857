import { m } from 'framer-motion';

export function NoCode() {
  return (
    <m.div
      className="relative w-full h-[440px] sm:h-[636px] max-w-2xl"
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 100 }}
      viewport={{ once: true, amount: 'some' }}
      transition={{ duration: 0.5, delay: 0.5 }}
    >
      <m.img
        className="absolute w-full invisible sm:visible"
        animate={{ y: [109, 109, 370, 370, 241, 241, 109] }}
        transition={{ repeat: Infinity, duration: 7, delay: 0.75 }}
        src="/graphics/auto-delete-threshold.png"
      />
      <m.img
        className="absolute w-full visible sm:invisible"
        animate={{ y: [77, 77, 258, 258, 168, 168, 77] }}
        transition={{ repeat: Infinity, duration: 7, delay: 0.75 }}
        src="/graphics/auto-delete-threshold.png"
      />
      <img
        alt="Manual Review Threshold"
        className="absolute w-full mt-[348px] sm:mt-[500px]"
        src="/graphics/manual-review-threshold.png"
      />
      <img
        alt="Violating Content Examples"
        className="flex w-full max-w-xl h-full pl-20 sm:pl-36"
        src="/graphics/no-code.png"
      />
    </m.div>
  );
}

import { domAnimation, LazyMotion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { FAQ } from '../components/FAQ';
import RequestDemoModal from '../components/RequestDemoModal';
import { BuiltForYou } from '../components/sections/ai/BuiltForYou';
import { CTA } from '../components/sections/ai/CTA';
import { CustomModels } from '../components/sections/ai/CustomModels';
import { NoCode } from '../components/sections/ai/NoCode';
import { Policies } from '../components/sections/ai/Policies';

const faqQuestions = [
  {
    question: "What makes Cove's models special or unique?",
    answer: (
      <>
        Cove's models have four unique qualities that differentiate them from
        other AI models:
        <ul className="list-disc">
          <li>
            <strong>Customization</strong>: Cove's models are trained on{' '}
            <em>your policies and your data</em>.
          </li>
          <li>
            <strong>Cutting Edge</strong>: Our models are built using the latest
            transformer models and large language models (LLMs).
          </li>
          <li>
            <strong>Cost</strong>: We've distilled LLMs down to much cheaper,
            faster models that still retain the same power and magic.
          </li>
          <li>
            <strong>Self-improving</strong>: Cove's models will constantly learn
            based on your feedback and your moderation decisions.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Are the models proprietary?',
    answer: (
      <>
        The models you create are <em>yours and yours alone.</em> We don’t share
        your custom models - or your data - with others.
      </>
    ),
  },
  {
    question: 'How much training data is required?',
    answer:
      "While we can work with any amount of training data, we recommend 1 million samples, which gives the best performance. If you don't have that much data, we'll work with what you have.",
  },
  {
    question: 'How do Cove’s AI Models compare to OpenAI / Chat GPT?',
    answer: (
      <>
        Cove's models are built on the same foundational technology as OpenAI's
        GPT models, but with a few critical differences:
        <ul className="list-disc">
          <li>
            <strong>Cost</strong>: Cove costs 100x less than GPT-4! See our{' '}
            <Link className="text-primary" to="../blog/openai">
              blog post
            </Link>{' '}
            for details.
          </li>
          <li>
            <strong>Consistency</strong>: OpenAI's models are{' '}
            <em>generative</em>, so they deliberately have randomized,
            probabilistic outputs. Cove's models are <em>classifiers</em>, so
            they're consistent and reliable.
          </li>
          <li>
            <strong>Usability</strong>: OpenAI's models have necessary safety
            guardrails that prevent you from running toxic content through
            GPT-4. Our models are specifically designed to process toxic content
            and classify it.
          </li>
        </ul>
      </>
    ),
  },
  {
    question:
      'Do I need an in-house AI expert to train my Cove Custom AI Models?',
    answer:
      'No, zero technical knowledge is required to train models. In fact, we recommend the models are trained by policy writers, or by in-house trust and safety experts who have a deep understanding of how the policies should be enforced on the content',
  },
  {
    question: 'Do the models get better over time?',
    answer:
      'Yes! We can retrain your models as your moderators make decisions.',
  },
];

export default function AI() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <LazyMotion features={domAnimation}>
      <CustomModels />
      <NoCode />
      <BuiltForYou showRequestDemoModal={showRequestDemoModal} />
      <Policies />
      <FAQ title="Frequently Asked Questions" questions={faqQuestions} />
      <CTA />
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="AI_MODELS"
      />
    </LazyMotion>
  );
}

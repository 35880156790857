import { makeEnumLike } from '@protego-api/types';
import { useOutletContext } from 'react-router-dom';

const TrainingPipelineSteps = [
  'SelectModality',
  'UploadData',
  'SelectPolicies',
  'InputPolicy',
  'LabelSamples',
  'LabelingComplete',
  'EvaluateModel',
  'PolicyCompleted',
  'TrainingPipelineCompleted',
] as const;

export const TrainingPipelineStep = makeEnumLike(TrainingPipelineSteps);
export type TrainingPipelineStepType = keyof typeof TrainingPipelineStep;

export const screenOrder = [
  TrainingPipelineStep.SelectModality,
  TrainingPipelineStep.LabelSamples,
  TrainingPipelineStep.EvaluateModel,
  TrainingPipelineStep.LabelingComplete,
  TrainingPipelineStep.PolicyCompleted,
] as const;
export type TrainingPipelineEnabledScreen = (typeof screenOrder)[number];

export type GoToScreen = (
  step: TrainingPipelineEnabledScreen,
  policyId: string,
  sessionId?: string,
  jobId?: string,
) => void;

export function useGoToScreen() {
  return useOutletContext<GoToScreen>();
}

import type { CSSProperties, SVGProps } from 'react';

import { Card } from '../../../../components/Card';

import { cn } from '../../../../lib/utils';

export interface SuiteCardProps {
  style?: CSSProperties;
  type: 'input' | 'output';
  label: string;
  icon: React.JSXElementConstructor<SVGProps<SVGSVGElement>>;
}

export function SuiteCard({ type, style, icon: Icon, label }: SuiteCardProps) {
  const isInput = type === 'input';

  return (
    <Card
      color={isInput ? 'primary' : 'accent'}
      style={style}
      className="home-shadow-md relative gap-0 lg:gap-2 min-w-0 min-h-0 flex-col flex justify-center items-center"
    >
      <Icon
        className={cn('w-5 h-5 mt-2 md:mt-0 md:w-8 md:h-8 flex-shrink-0', {
          'text-primary': isInput,
          'text-accent': !isInput,
        })}
        width="100%"
        height="100%"
      />

      <div className="text-xs scale-75 sm:text-sm text-center leading-snug sm:scale-75 md:scale-100">
        {label}
      </div>
    </Card>
  );
}

import { domAnimation, LazyMotion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Careers } from '../components/sections/about/Careers';
import { Hero } from '../components/sections/about/Hero';
import { Investors } from '../components/sections/about/Investors';
import { Team } from '../components/sections/about/Team';

export default function About() {
  const [searchParams] = useSearchParams();
  const to = searchParams.get('to');
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (to && to === 'careers' && ref.current) {
      // For some reason, just calling scrollIntoView without the setTimeout
      // doesn't work, so I got this solution at https://stackoverflow.com/a/71181885
      setTimeout(
        () => ref.current!.scrollIntoView({ behavior: 'smooth' }),
        100,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LazyMotion features={domAnimation}>
      <Hero />
      <Team />
      <Investors />
      <div ref={ref}>
        <Careers />
      </div>
    </LazyMotion>
  );
}

import { GQLUserPenaltySeverity } from '../graphql/generated';

export const UserPenaltySeverityOrder = [
  GQLUserPenaltySeverity.None,
  GQLUserPenaltySeverity.Low,
  GQLUserPenaltySeverity.Medium,
  GQLUserPenaltySeverity.High,
  GQLUserPenaltySeverity.Severe,
];

export function getSeverityColor(severity: GQLUserPenaltySeverity) {
  switch (severity) {
    case GQLUserPenaltySeverity.None:
      return 'border-cove-blue text-cove-blue';
    case GQLUserPenaltySeverity.Low:
    case GQLUserPenaltySeverity.Medium:
      return 'border-cove-orange text-cove-orange';
    case GQLUserPenaltySeverity.High:
    case GQLUserPenaltySeverity.Severe:
      return 'border-cove-alert-red text-cove-alert-red';
  }
}

import {
  useGQLBulkActionExecutionMutation,
  useGQLBulkActionsFormDataQuery,
} from '@/graphql/generated';
import { stripTypename } from '@/graphql/inputHelpers';
import NotionUserCommsCheckboxes from '@/webpages/dashboard/mrt/manual_review_job/NotionUserCommsCheckboxes';
import { ItemIdentifier } from '@protego-api/types';
import { Select } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { selectFilterByLabelOption } from '@/webpages/dashboard/components/antDesignUtils';
import CoveButton from '@/webpages/dashboard/components/CoveButton';
import CoveModal from '@/webpages/dashboard/components/CoveModal';
import PolicyDropdown from '@/webpages/dashboard/components/PolicyDropdown';

const { Option } = Select;

export default function ItemAction(props: {
  itemIdentifier: ItemIdentifier;
  title?: string;
}) {
  const { itemIdentifier, title = 'Action on this Item' } = props;

  const { data: queryData } = useGQLBulkActionsFormDataQuery();
  const [bulkAction, { loading }] = useGQLBulkActionExecutionMutation({
    onCompleted: () => () => {
      setModalBody('Actions submitted successfully');
      setShowModal(true);
    },
    onError: () => () => {
      setModalBody('Error submitting actions. Please try again.');
      setShowModal(true);
    },
  });

  const [selectedPolicyIds, setSelectedPolicyIds] = useState<string[]>([]);
  const [selectedActionIds, setSelectedActionIds] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState<string>('');

  const eligibleActions = (queryData?.myOrg?.actions ?? []).filter((it) =>
    it.itemTypes.map((it) => it.id).includes(itemIdentifier.typeId),
  );
  const policies = queryData?.myOrg?.policies;
  if (eligibleActions.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-start mb-2">
        <div className="font-semibold text-base">{title}</div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="flex flex-col items-start">
          <div>
            <Select
              className="w-40"
              mode="multiple"
              placeholder="Select action"
              dropdownMatchSelectWidth={false}
              filterOption={selectFilterByLabelOption}
              onChange={(actionIds: string[]) =>
                setSelectedActionIds(actionIds)
              }
              dropdownRender={(menu) => {
                if (eligibleActions.length === 0) {
                  return (
                    <div>
                      <div className="text-cove-alert-red">
                        No actions available
                      </div>
                      {menu}
                    </div>
                  );
                }
                return menu;
              }}
            >
              {_.orderBy(eligibleActions, ['name']).map((action) => (
                <Option key={action.id} value={action.id} label={action.name}>
                  {action.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div>
            <PolicyDropdown
              className="w-40"
              policies={policies ? policies.map((p) => stripTypename(p)) : []}
              onChange={(policyIds) => {
                if (Array.isArray(policyIds)) {
                  setSelectedPolicyIds(policyIds.map((id) => id.toString()));
                } else {
                  // NB: This cast is required because of a longstanding typescript
                  // issue. See https://github.com/microsoft/TypeScript/issues/17002 for
                  // more details.
                  const policyId = policyIds satisfies
                    | string
                    | readonly string[] as string;
                  setSelectedPolicyIds([policyId]);
                }
              }}
              selectedPolicyIds={selectedPolicyIds}
              multiple={
                queryData?.myOrg?.allowMultiplePoliciesPerAction ?? false
              }
            />
          </div>
        </div>
        <CoveButton
          title="Submit Actions"
          size="small"
          onClick={async () =>
            bulkAction({
              variables: {
                input: {
                  itemTypeId: itemIdentifier.typeId,
                  actionIds: selectedActionIds,
                  itemIds: [itemIdentifier.id],
                  policyIds: selectedPolicyIds,
                },
              },
            })
          }
          loading={loading}
        />
      </div>
      {/* If the org is Notion and an action has been selected that Notion may want to send user comms for, show a default-off checkbox */}
      <NotionUserCommsCheckboxes selectedActionIds={selectedActionIds} />
      <CoveModal visible={showModal} onClose={() => setShowModal(false)}>
        {modalBody}
      </CoveModal>
    </div>
  );
}

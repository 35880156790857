import { SpinnerSolid } from '@/icons';

export default function DemoSecondaryButton(props: {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}) {
  const { title, onClick, disabled, loading } = props;
  return (
    <div
      className={`flex items-center justify-center w-fit px-6 py-3 rounded text-lg font-semibold cursor-pointer ${
        disabled
          ? 'bg-slate-100 text-slate-300'
          : loading
          ? 'bg-slate-100 text-slate-500'
          : 'bg-transparent border border-solid border-slate-500 hover:border-slate-400 text-slate-500 hover:text-slate-400'
      }`}
      onClick={() => {
        if (!disabled && !loading) {
          onClick();
        }
      }}
    >
     {loading ? (
       <SpinnerSolid className="animate-spin fill-slate-500 w-4 mr-2" />
     ) : null}
     {title}
    </div>
  );
}

import type { ReactNode } from 'react';

import { Logo } from './Logo';
import { SystemStatus } from './SystemStatus';
import { Typography } from './ui/Typography';

interface LinkProps {
  url: string;
  children: ReactNode;
}

function Link({ url, children }: LinkProps) {
  return (
    <li>
      <a
        href={url}
        className="hover:text-default text-subtle decoration-inherit flex flex-row items-center gap-2 py-2 transition-all duration-300"
      >
        {children}
      </a>
    </li>
  );
}

interface LinkListProps {
  title: string;
  children: ReactNode;
}

function LinkList({ title, children }: LinkListProps) {
  return (
    <div className="flex flex-col gap-2 lg:px-4">
      <Typography variant="title" size="sm">
        {title}
      </Typography>
      <ul className="list-none ps-0 my-0">{children}</ul>
    </div>
  );
}
export function Footer() {
  return (
    <footer className="border-primary/10 border-t border-solid border-x-0 border-b-0">
      <div className="container flex flex-col lg:flex-row justify-between gap-16 p-16">
        <div className="flex flex-col items-start gap-5">
          <Logo width={131} height={36} />

          <Typography variant="body" color="subtle" size="sm">
            Copyright {new Date().getFullYear()} &copy; Cove Software, Inc.{' '}
            <br />
            All rights reserved.
          </Typography>

          <SystemStatus />
        </div>

        <div className="flex flex-col lg:flex-row items-start justify-start gap-8">
          <LinkList title="Products">
            <Link url="/ai">Custom AI Models</Link>
            <Link url="/enforcement">Automated Enforcement</Link>
            <Link url="/console">Moderator Console</Link>
            <Link url="/compliance">Compliance Toolkit</Link>
          </LinkList>

          <LinkList title="Company">
            <Link url="/about">About</Link>
            <Link url="/blog">Blog</Link>
            <Link url="/about?to=careers">
              Careers{' '}
              <span className="text-primary mt-px text-sm font-semibold">
                We're hiring!
              </span>
            </Link>
            <Link url="https://docs.getcove.com">Docs</Link>
          </LinkList>

          <LinkList title="Legal">
            <Link url="/terms">Terms</Link>
            <Link url="/privacy">Privacy</Link>
          </LinkList>
        </div>
      </div>
    </footer>
  );
}

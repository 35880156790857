import { CloudUpload, Table } from '@/icons';
import { useRef, useState } from 'react';

import DemoFooter from '../DemoFooter';

export default function DemoSpotTestInputScreen(props: {
  policyName: string;
  goToNextScreen: () => void;
  goBack: () => void;
}) {
  const { policyName, goToNextScreen, goBack } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<{ name: string; type: string } | undefined>(
    undefined,
  );

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col items-center h-full max-w-2xl justify-center my-24">
        <div className="flex flex-col w-full max-w-4xl gap-4">
          <div className="text-2xl font-semibold">
            Test your {policyName} model
          </div>
          <div className="pb-4 text-base text-slate-500">
            Run a spot test with your own dataset to see how the model scores
            your data.
          </div>
        </div>
        <div className="flex items-center justify-center w-full">
          <input
            ref={fileInputRef}
            className="hidden"
            type="file"
            accept=".csv"
            onChange={(e) => {
              const file = e.target.files?.[0];
              setFile(file);
            }}
          />
          <div
            className="flex flex-col items-center justify-center border border-dashed rounded-lg cursor-pointer border-cove-blue bg-cove-blue/20 w-full mt-3 p-24"
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
          >
            {file ? (
              <div className="flex gap-2 items-center justify-center">
                <Table className="fill-cove-blue w-7" />
                <div>{file.name}</div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <CloudUpload className="fill-cove-blue w-7" />
                <div className="text-base text-cove-blue">
                  upload or drag a .csv with up to 10,000 samples
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Continue',
          onClick: goToNextScreen,
        }}
        onBack={goBack}
      />
    </div>
  );
}

import { Checkbox, Label } from '@/cove-ui';
import { Input } from 'antd';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import CoveButton from '../dashboard/components/CoveButton';

import LogoPurple from '../../images/LogoPurple.png';

/**
 * Login form component
 */
export default function LoginSSO() {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [remember, setRemember] = useState(true);

  return (
    <div className="flex flex-col h-screen p-8 mb-0 bg-slate-100">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex flex-col items-start justify-center border-none sm:border sm:border-solid border-slate-200 rounded-xl shadow-none sm:shadow h-full w-full sm:h-[560px] sm:w-[460px] m-0 p-0 sm:m-9 sm:px-12 gap-2">
          <Link to="/" className="flex items-center justify-center w-full my-2">
            <img src={LogoPurple} alt="Logo" width="55" height="70" />
          </Link>
          <div className="py-5 text-2xl font-bold">
            Sign in to your Cove account
          </div>
          <>
            <div className="font-semibold">Email</div>
            <Input onChange={(e) => setEmail(e.target.value)} />
          </>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="remember-me"
              defaultChecked
              checked={remember}
              onCheckedChange={setRemember}
            />
            <Label htmlFor="remember-me">Keep me signed in</Label>
          </div>
          <CoveButton title="Next" disabled={!email?.length} />
        </div>
      </div>
    </div>
  );
}

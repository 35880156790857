import { ChevronDown, Close, Menu } from '@/icons';
import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useGQLLogoutMutation,
  useGQLNavigationBarAuthInfoQuery,
} from '../../../graphql/generated';
import { cn } from '../lib/utils';
import { Logo } from './Logo';
import RequestDemoModal from './RequestDemoModal';
import { Button } from './ui/Button';

gql`
  query NavigationBarAuthInfo {
    me {
      id
      email
      firstName
      lastName
      readMeJWT
    }
  }
`;

export function Navigation(props: { className?: string }) {
  const { className } = props;
  const [isScrolling, setScrolling] = useState(false);
  const { pathname } = useLocation();
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);

  const { loading, error, data } = useGQLNavigationBarAuthInfoQuery();

  const [logout, { client }] = useGQLLogoutMutation({
    onCompleted: (_) => {
      client.stop();
      client.resetStore().then(() => window.location.reload());
    },
  });
  const loggedInUser = data?.me;
  const isLoggedIn = loggedInUser != null;

  useEffect(() => {
    function handleScrolling() {
      setScrolling(window.scrollY > 0);
    }

    window.addEventListener('scroll', () => {
      handleScrolling();
    });

    setScrolling(window.scrollY > 0);

    return () => {
      window.removeEventListener('scroll', handleScrolling);
    };
  }, []);

  function closeNav() {
    const menu = document.getElementById('menu') as HTMLInputElement;

    if (menu.checked) {
      menu.checked = false;
    }
  }

  if (error || loading) {
    return (
      <nav
        className={cn(
          'fixed lg:inset-x-0 inset-x-4 border border-solid lg:container rounded-lg2 border-clear transition-all duration-300 top-2 z-50 p-5',
          {
            'border-solid border-default bg': isScrolling,
          },
          className,
        )}
      >
        <div className="flex flex-row items-center justify-between sm:pl-4">
          <a
            className="flex text-inherit decoration-inherit hover:text-primary"
            href="/"
          >
            <Logo />
          </a>
        </div>
      </nav>
    );
  }

  const loggedInButtons = [
    <Button key="demo" size="sm" as="a" href="/dashboard">
      My Dashboard
    </Button>,
    <Button
      key="signin"
      onClick={async () => logout()}
      variant="outlined"
      size="sm"
    >
      Log Out
    </Button>,
  ];

  const loggedOutButtons = [
    <Button key="demo" onClick={showRequestDemoModal} size="sm">
      Schedule a demo
    </Button>,
    <Button key="signin" variant="outlined" size="sm" as="a" href="/login">
      Sign in
    </Button>,
  ];

  const products = [
    <a
      key={'Custom AI Models'}
      className={cn(
        {
          'text-primary': pathname === '/ai',
          'text-inherit': pathname !== '/ai',
        },
        'hover:text-primary',
      )}
      href="/ai"
    >
      Custom AI Models
    </a>,
    <a
      key={'Automated Enforcement'}
      className={cn(
        {
          'text-primary': pathname === '/enforcement',
          'text-inherit': pathname !== '/enforcement',
        },
        'hover:text-primary',
      )}
      href="/enforcement"
    >
      Automated Enforcement
    </a>,
    <a
      key={'Moderator Console'}
      className={cn(
        {
          'text-primary': pathname === '/console',
          'text-inherit': pathname !== '/console',
        },
        'hover:text-primary',
      )}
      href="/console"
    >
      Moderator Console
    </a>,
    <a
      key={'Compliance Toolkit'}
      className={cn(
        {
          'text-primary': pathname === '/compliance',
          'text-inherit': pathname !== '/compliance',
        },
        'hover:text-primary',
      )}
      href="/compliance"
    >
      Compliance Toolkit
    </a>,
  ];

  const productsMenu = (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsProductMenuOpen(true)}
      onMouseLeave={() => setIsProductMenuOpen(false)}
    >
      <div className="group flex gap-2 my-0 lg:my-2 items-center">
        <button
          className={cn(
            {
              'lg:text-primary': [
                '/ai',
                '/enforcement',
                '/compliance',
                '/console',
              ].includes(pathname),
            },
            'bg-transparent border-none group-hover:text-primary font-bold px-0',
          )}
        >
          Products
        </button>
        <div className="w-3.5 h-min">
          <ChevronDown className="hidden lg:flex group-hover:fill-primary" />
        </div>
      </div>
      {/* Show all the products in the mobile menu */}
      <div className="flex flex-col gap-6 mt-6 ml-4 lg:hidden">{products}</div>
      {/* Hide all the products in the desktop menu behind a hover */}
      {isProductMenuOpen && (
        <>
          <div
            className={cn(
              'hidden lg:flex flex-col absolute shadow rounded-lg whitespace-nowrap p-6 gap-4 mt-0',
              {
                'bg-white': !isScrolling,
                bg: isScrolling,
              },
            )}
          >
            {products}
          </div>
        </>
      )}
    </div>
  );

  return (
    <nav
      className={cn(
        'fixed lg:inset-x-0 inset-x-4 border lg:container rounded-lg2 border-clear transition-all duration-300 top-2 z-50 p-5',
        {
          'border-solid border-default bg': isScrolling,
        },
        className,
      )}
    >
      <div className="flex flex-row items-center justify-between sm:pl-4">
        <a
          className="flex text-inherit decoration-inherit hover:text-primary"
          href="/"
        >
          <Logo />
        </a>

        <input type="checkbox" id="menu" className="hidden peer" />

        <label
          htmlFor="menu"
          className={cn(
            'relative w-8 h-8',
            'flex justify-center items-center lg:hidden peer-checked:[&>#closed]:rotate-45 peer-checked:[&>#closed]:opacity-0',
            'peer-checked:[&>#opened]:rotate-0 peer-checked:[&>#opened]:opacity-100',
            'text-subtle',
            'lg:hidden',
          )}
        >
          <Menu
            id="closed"
            className="absolute transition-all duration-150 center"
          />
          <Close
            id="opened"
            className="absolute transition-all duration-150 -rotate-45 opacity-0 center"
          />
        </label>

        <div
          className={cn(
            'flex justify-between items-center lg:flex-row flex-1',
            'flex-col absolute -inset-x-px translate-y-full bg rounded-b-md home-shadow-md',
            'peer-checked:bottom-2 peer-checked:opacity-100 peer-checked:pointer-events-auto',
            'bottom-5 opacity-0 transition-all duration-300 border-x border-b border-t-0 border-default',
            'pointer-events-none',
            'lg:relative lg:!shadow-none lg:transform-none lg:border-none lg:bg-clear lg:bottom-auto lg:right-auto lg:pointer-events-auto lg:opacity-100',
            {
              'border-solid': isScrolling,
            },
          )}
        >
          <ul
            onClick={closeNav}
            className="flex flex-col items-start justify-start flex-1 w-full gap-8 p-10 my-0 font-bold text-left list-none lg:flex-row lg:justify-center lg:p-0 lg:items-center"
          >
            <li>{productsMenu}</li>
            <li>
              <a
                className={cn(
                  {
                    'text-primary': pathname === '/about',
                    'text-inherit': pathname !== '/about',
                  },
                  'hover:text-primary',
                )}
                href="/about"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                className="text-inherit decoration-inherit hover:text-primary"
                href={`https://docs.getcove.com/?auth_token=${
                  loggedInUser?.readMeJWT ?? ''
                }`}
              >
                Docs
              </a>
            </li>
            <li>
              <a
                className="text-inherit decoration-inherit hover:text-primary"
                href="/about?to=careers"
              >
                Careers
              </a>
            </li>
            <li>
              <a
                className={cn(
                  {
                    'text-primary': pathname.startsWith('/blog'),
                    'text-inherit': !pathname.startsWith('/blog'),
                  },
                  'hover:text-primary',
                )}
                href="/blog"
              >
                Blog
              </a>
            </li>
          </ul>

          <div className="flex lg:border-none lg:w-auto border-0 border-t border-solid border-default flex-row p-4 lg:!p-0 w-full justify-center items-center gap-4">
            {isLoggedIn ? loggedInButtons : loggedOutButtons}
          </div>
        </div>
        <RequestDemoModal
          visible={requestDemoModalVisible}
          onClose={() => setRequestDemoModalVisible(false)}
          entrypoint="NAVIGATION"
        />
      </div>
    </nav>
  );
}

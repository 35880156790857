import { m } from 'framer-motion';

export function Graph() {
  return (
    <m.img
      className="max-w-3xl w-3/5 pt-2"
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 100 }}
      viewport={{ once: true, amount: 0.25 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      src="/graphics/moderation-graph.png"
    />
  );
}

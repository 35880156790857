import { gql } from '@apollo/client';
import { useRef, useState } from 'react';

import { useGQLFetchSignedUrlLazyQuery } from '../../../../../graphql/generated';
import LogoGradient from '../../../../../images/LogoGradient.png';
import DemoFooter from '../DemoFooter';

gql`
  query fetchSignedUrl($filename: String!, $fileType: String!) {
    getSignedS3Url(filename: $filename, fileType: $fileType) {
      url
    }
  }
`;

export default function DemoFileUploadScreen(props: {
  goToNextScreen: () => void;
  goBack: () => void;
}) {
  const { goToNextScreen, goBack } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<{ name: string; type: string } | undefined>(
    undefined,
  );

  // TODO: Remove when this stuff actually gets used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchSignedUrl, { data, loading, error }] =
    useGQLFetchSignedUrlLazyQuery({});

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col items-center justify-center h-full max-w-2xl">
        <img alt="logo" src={LogoGradient} width={75} />
        <div className="py-6 text-3xl font-bold">Upload a content sample</div>
        <div className="pb-4 text-base text-slate-500">
          Upload a .csv file with at least 500,000 samples of content so we can
          create your custom AI models.
        </div>
        <div className="pb-4 text-base text-slate-500">
          The file should just contain individual text strings, separated by
          commas, with an optional column header.
        </div>
        <input
          ref={fileInputRef}
          className="hidden"
          type="file"
          accept=".csv"
          onChange={(e) => {
            const file = e.target.files?.[0];
            setFile(file);
          }}
        />
        <div
          className="flex flex-col items-center justify-center py-32 border border-dashed rounded-lg cursor-pointer border-cove-blue bg-cove-blue/20 w-full mt-3"
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
        >
          {file ? (
            <>
              <div>{`File name: ${file.name}`}</div>
              <div>{`File type: ${file.type}`}</div>
            </>
          ) : (
            <div className="text-base text-cove-blue">
              Click to upload (max file size 1GB)
            </div>
          )}
        </div>
        <div className="pt-4 font-semibold text-slate-500">
          Images and videos coming soon!
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Continue',
          onClick: goToNextScreen,
        }}
        onBack={goBack}
      />
    </div>
  );
}

import { gql } from '@apollo/client';
import { useState } from 'react';

import DemoTextInput from '../components/DemoTextInput';

import { useGQLSaveCustomerInfoMutation } from '../../../../../graphql/generated';
import LogoAndWordmarkAndAIDarkPurple from '../../../../../images/LogoAndWordmarkAndAIDarkPurple.png';
import DemoFooter from '../DemoFooter';

gql`
  mutation SaveCustomerInfo($input: CustomerInfoInput!) {
    saveCustomerInfo(input: $input)
  }
`;

export default function DemoCoverPageScreen(props: {
  goToNextScreen: () => void;
}) {
  const { goToNextScreen } = props;
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [company, setCompany] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [saveCustmerInfo] = useGQLSaveCustomerInfoMutation({
    onError: (error) => {
      console.log(error);
      setIsSubmitting(false);
    },
    onCompleted: () => {
      goToNextScreen();
      setIsSubmitting(false);
    },
  });

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col items-center justify-center w-full h-full grow">
        <img alt="logo" src={LogoAndWordmarkAndAIDarkPurple} width={300} />
        <div className="my-8 text-3xl text-slate-400">
          Custom Trust & Safety AI - in your own words
        </div>
        <div className="flex flex-col gap-4 my-4">
          <div className="flex gap-4">
            <DemoTextInput placeholder="First name" onChange={setFirstName} />
            <DemoTextInput placeholder="Last name" onChange={setLastName} />
          </div>
          <DemoTextInput placeholder="Company" onChange={setCompany} />
          <DemoTextInput placeholder="Email address" onChange={setEmail} />
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Get Started',
          disabled:
            !firstName?.length ||
            !lastName?.length ||
            !company?.length ||
            !email?.length,
          loading: isSubmitting,
          onClick: async () => {
            setIsSubmitting(true);
            saveCustmerInfo({
              variables: {
                input: {
                  firstName: firstName!,
                  lastName: lastName!,
                  company: company!,
                  email: email!,
                },
              },
            });
          },
        }}
      />
    </div>
  );
}

import { m } from 'framer-motion';

import { FeatureSection } from '../landing/FeatureSection';

export function Investors() {
  return (
    <FeatureSection
      gap={false}
      centered
      orientation="ttb"
      title="Backed by industry leaders"
      description="We’re backed by industry-defining investors & a group of world-class founders and operators like Elad Gil, Scott Forstall, and others from Meta, Stripe, Figma, Notion, Webflow, Dropbox, Retool, Opendoor."
    >
      <m.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ amount: 0.25, once: true }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="flex flex-row items-center gap-6 sm:gap-20 flex-wrap justify-center mt-4 sm:mt-0"
      >
        <img src="/brands/thrive.png" alt="Thrive" className="w-32" />
        <img src="/brands/sound.png" alt="Sound" className="w-36" />
        <img src="/brands/neo.png" alt="Neo" className="w-16" />
        <img src="/brands/csm.png" alt="CSM" className="w-32" />
      </m.div>
    </FeatureSection>
  );
}

import { LoadingOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { useState } from 'react';

import { selectFilterByLabelOption } from '../components/antDesignUtils';
import CoveModal from '../components/CoveModal';

import {
  GQLUserPenaltySeverity,
  useGQLAddPoliciesMutation,
  useGQLGenerateDefaultPolicyDefinitionLazyQuery,
  useGQLUpdatePolicyMutation,
} from '../../../graphql/generated';
import { titleCaseEnumString } from '../../../utils/string';
import { UserPenaltySeverityOrder } from '../../../utils/userPenalty';
import MarkdownTextInput from './MarkdownTextInput';
import { Policy } from './PoliciesDashboard';

const { Option } = Select;

export type PolicyInputModalInfo = {
  onClose: () => void;
  existingPolicy?: Policy;
  parent?: { id: string; name: string };
};

export default function PolicyInputModal(props: {
  modalInfo: PolicyInputModalInfo;
}) {
  const { parent, onClose, existingPolicy } = props.modalInfo;
  const [policyName, setPolicyName] = useState<string | undefined>(
    existingPolicy?.name,
  );
  const [policyText, setPolicyText] = useState<string | undefined>(
    existingPolicy?.policyText,
  );
  const [enforcementGuidelines, setEnforcementGuidelines] = useState<
    string | undefined
  >(existingPolicy?.enforcementGuidelines);
  const [policyPenalty, setPolicyPenalty] = useState<GQLUserPenaltySeverity>(
    existingPolicy?.penalty ?? GQLUserPenaltySeverity.None,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const [addPolicy, { loading: addPolicyLoading }] = useGQLAddPoliciesMutation({
    onCompleted: onClose,
    onError: () => setErrorMessage('Error saving policy. Please try again.'),
  });

  const [updatePolicy, { loading: updatePolicyLoading }] =
    useGQLUpdatePolicyMutation();

  const [generatePolicyDefinition, { loading: generatedPolicyLoading }] =
    useGQLGenerateDefaultPolicyDefinitionLazyQuery({
      variables: { input: { policyName: policyName! } },
      onCompleted(data) {
        switch (data.generatePolicyText.__typename) {
          case 'GeneratedPolicyTextCompletionSuccess':
            setPolicyText(data.generatePolicyText.policyText);
            break;
          case 'GeneratedPolicyTextCompletionError':
            setErrorMessage(`Error generating policy text. Please try again.`);
            break;
        }
      },
      onError(e) {
        setErrorMessage('Error generating policy text. Please try again.');
      },
      fetchPolicy: 'no-cache',
    });

  const onCloseModal = () => {
    setPolicyName(undefined);
    onClose();
  };

  const nameAndPenaltySection = (
    <div className="flex items-center gap-4 mt-6">
      <div className="flex flex-col items-start gap-2">
        <div className="font-semibold">Name</div>
        <Input
          className="rounded w-96"
          value={policyName}
          onChange={(event) => setPolicyName(event.target.value)}
        />
      </div>
      <div className="flex flex-col items-start gap-2">
        <div className="font-semibold">User Penalty</div>
        <Select
          className="w-44"
          placeholder="Select penalty severity"
          dropdownMatchSelectWidth={false}
          value={policyPenalty}
          onChange={(value) => {
            setPolicyPenalty(value);
          }}
          allowClear
          showSearch
          filterOption={selectFilterByLabelOption}
        >
          {UserPenaltySeverityOrder?.map((severity) => (
            <Option
              key={severity}
              value={severity}
              label={titleCaseEnumString(severity)}
            >
              {titleCaseEnumString(severity)}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );

  const policyTextSection = (
    <div className="flex flex-col justify-center w-[740px] items-start text-start mt-6 gap-2">
      <div className="flex justify-between w-full items-center">
        <div className="font-semibold">Definition</div>
        <div
          className={`flex flex-row items-center justify-end text-sm ${
            generatedPolicyLoading
              ? 'text-slate-300'
              : 'text-cove-blue cursor-pointer hover:text-cove-blue-hover'
          }`}
          onClick={() => {
            if (!generatedPolicyLoading) {
              setPolicyText('Generating policy template...');
              generatePolicyDefinition();
            }
          }}
        >
          {generatedPolicyLoading ? (
            <LoadingOutlined spin className="mr-2" />
          ) : (
            <ThunderboltOutlined className="mr-2" />
          )}
          {generatedPolicyLoading
            ? 'Generating Policy Text...'
            : 'Generate Policy Text'}
        </div>
      </div>
      <MarkdownTextInput
        text={policyText}
        setText={setPolicyText}
        textSize="small"
        disabled={generatedPolicyLoading}
      />
    </div>
  );

  const enforcementGuidelinesSection = (
    <div className="flex flex-col justify-center w-[740px] items-start text-start gap-2 mt-6">
      <div className="flex items-center gap-2 my-0 py-0">
        <div className="font-semibold">Enforcement Guidelines</div>
        <div className="text-sm text-slate-400 h-full font-normal">
          optional
        </div>
      </div>
      <MarkdownTextInput
        text={enforcementGuidelines}
        setText={setEnforcementGuidelines}
        textSize="small"
      />
    </div>
  );

  const errorModal = (
    <CoveModal
      title="Error"
      visible={errorMessage != null}
      onClose={() => setErrorMessage(undefined)}
      footer={[
        {
          title: 'OK',
          onClick: () => setErrorMessage(undefined),
        },
      ]}
    >
      {errorMessage}
    </CoveModal>
  );

  return (
    <CoveModal
      title={existingPolicy ? 'Update Policy' : 'Add Policy'}
      visible={true}
      onClose={onCloseModal}
      footer={[
        {
          title: 'Save',
          type: 'primary',
          disabled: generatedPolicyLoading,
          loading: addPolicyLoading || updatePolicyLoading,
          onClick: async () => {
            if (policyName == null || policyName?.length === 0) {
              setErrorMessage('Please enter a policy name.');
              return;
            }

            if (existingPolicy?.id) {
              await updatePolicy({
                variables: {
                  input: {
                    id: existingPolicy.id,
                    policyText,
                    enforcementGuidelines,
                    name: policyName,
                    penalty: policyPenalty,
                    parentId: parent?.id ?? undefined,
                  },
                },
                onCompleted: onClose,
                onError: () =>
                  setErrorMessage('Error saving policy. Please try again.'),
              });
            } else {
              await addPolicy({
                variables: {
                  policies: [
                    {
                      policyText,
                      enforcementGuidelines,
                      name: policyName,
                      penalty: policyPenalty,
                      parentId: parent?.id ?? undefined,
                    },
                  ],
                },
                onCompleted: onClose,
                onError: () =>
                  setErrorMessage('Error saving policy. Please try again.'),
              });
            }
          },
        },
      ]}
    >
      <div className="flex items-center justify-between text-base text-slate-400">
        {parent
          ? `${existingPolicy ? 'Add' : 'Update'} Sub-Policy under ${
              parent.name
            }`
          : 'This will be a top-level policy'}
      </div>
      {nameAndPenaltySection}
      {policyTextSection}
      {enforcementGuidelinesSection}
      {errorModal}
    </CoveModal>
  );
}

import { cn } from '../../../lib/utils';
import { HorizontalDashes } from '../../animations/HorizontalDashes';
import { HeroImage } from '../../animations/landing/HeroImage';
import { VerticalDashes } from '../../animations/VerticalDashes';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

export function Hero(props: { showRequestDemoModal: () => void }) {
  return (
    <section
      className={cn(
        'relative',
        'overflow-hidden',
        'max-w-[82rem] mx-auto',
        'mx-auto mb-16 lg:mb-12 grid grid-rows-[auto,auto,auto,6rem] grid-cols-1 lg:grid-cols-12 [&_:not(.dash)]:col-[3/-3]',
        'mask-none sm:mask-ellipse sm:mask-from-60% sm:reach-closest-corner',
      )}
    >
      <VerticalDashes className="mask-[linear-gradient(0deg,rgba(0,0,0,0.1)_0%,black_20%,black_80%,rgba(0,0,0,0.1)_100%)] hidden lg:block col-start-1 -mr-px justify-self-end row-span-full dash" />
      <VerticalDashes className="mask-[linear-gradient(0deg,rgba(0,0,0,0.1)_0%,black_20%,black_80%,rgba(0,0,0,0.1)_100%)] hidden lg:block col-start-2 -mr-px justify-self-end row-span-full dash" />
      <VerticalDashes className="mask-[linear-gradient(0deg,rgba(0,0,0,0.1)_0%,black_20%,black_80%,rgba(0,0,0,0.1)_100%)] hidden lg:block col-start-[-2] row-span-full dash" />
      <VerticalDashes className="mask-[linear-gradient(0deg,rgba(0,0,0,0.1)_0%,black_20%,black_80%,rgba(0,0,0,0.1)_100%)] hidden lg:block col-start-[-3] row-span-full dash" />
      <HorizontalDashes className="mask-[linear-gradient(90deg,rgba(0,0,0,0.1)_0%,black_20%,black_80%,rgba(0,0,0,0.1)_100%)] hidden lg:block row-start-2 col-span-full dash" />
      <HorizontalDashes className="mask-[linear-gradient(90deg,rgba(0,0,0,0.1)_0%,black_20%,black_80%,rgba(0,0,0,0.1)_100%)] hidden lg:block row-start-4 col-span-full dash" />
      <div className="flex flex-col pt-48 items-center gap-6 px-6 lg:px-0 z-30 relative pb-10">
        <Typography variant="display" size="lg" align="center">
          A complete suite of AI-powered
          <br />
          <span className="bg-gradient-to-r from-primary to-ternary to-pink text-clear bg-clip-text">
            Trust & Safety tools
          </span>
        </Typography>
        <Typography
          align="center"
          size="lg"
          color="subtle"
          className="px-4 lg:px-14"
        >
          Meet the new standard for modern Trust & Safety. Move faster, reduce
          spend, and maintain full control without writing code. Easily
          customize AI models and workflows to fit your needs.
        </Typography>
        <Button onClick={props.showRequestDemoModal} size="lg">
          Schedule a demo
        </Button>
      </div>
      <div className="flex flex-col @container items-center gap-6 px-16 sm:px-36 lg:px-0">
        <div className="w-full z-50">
          <HeroImage />
        </div>

        <div
          className={cn(
            'bg-graph-paper w-[calc(300vw)] h-[calc(300vh)] absolute -left-[calc(3*var(--cell-width))]',
            '-top-[calc(9*var(--cell-height))] sm:-top-[calc(5*var(--cell-height))]',
            '[--cell-width:calc(100cqw/4)] sm:[--cell-width:calc(100cqw/8)]',
            // 2x the width-to-height ratio of our main SVG
            '[--cell-height:calc(var(--cell-width)*1.2223674422)]',
            'mask-from-20% sm:mask-from-60% mask-ellipse reach-closest-corner',
            'z-10',
          )}
          style={{ backgroundSize: 'var(--cell-width) var(--cell-height)' }}
        />
      </div>
      <div />
    </section>
  );
}

import {
  useGQLGetSamplingJobResultsQuery,
  useGQLPoliciesQuery,
} from '@/graphql/generated';
import { __throw } from '@/utils/misc';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import FullScreenLoading from '@/components/common/FullScreenLoading';
import CoveButton from '@/webpages/dashboard/components/CoveButton';

import TrainingPipelineLabelingProgressComponent from './TrainingPipelineLabelingProgressComponent';

export default function TrainingPipelineLabelingCompleteScreen() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const policyId = searchParams.get('policyId') ?? '';
  const jobId = searchParams.get('jobId') ?? __throw('jobId is required');

  const {
    data: samplingJobResultsData,
    loading: samplingJobResultsLoading,
    stopPolling,
  } = useGQLGetSamplingJobResultsQuery({
    variables: {
      jobId,
    },
    pollInterval: 5000,
    onCompleted: (response) => {
      if (response.getSamplingJobResults.__typename === 'SamplingJobFailure') {
        stopPolling();
      } else if (
        response.getSamplingJobResults.__typename === 'SamplingJobNotFoundError'
      ) {
        stopPolling();
      }
    },
  });

  const { data: policiesData, loading: policiesLoading } =
    useGQLPoliciesQuery();
  if (samplingJobResultsLoading || policiesLoading) {
    return <FullScreenLoading />;
  }

  if (
    samplingJobResultsData?.getSamplingJobResults?.__typename !==
    'SamplingJobSuccess'
  ) {
    return <div>Error</div>;
  }

  const samplingJobCount =
    samplingJobResultsData?.getSamplingJobResults?.samples?.length ?? 0;
  const policy = policiesData?.myOrg?.policies.find(
    (policy) => policy?.id === policyId,
  );

  return (
    <div className="flex flex-col items-center mt-8 text-center">
      <div className="p-8 bg-gray-100 rounded-lg">
        <TrainingPipelineLabelingProgressComponent
          currentIndex={samplingJobCount}
          total={samplingJobCount}
          size="large"
        />
      </div>
      <div className="pt-8 text-2xl font-semibold">Labeling Complete!</div>
      <div className="w-5/12 py-4">
        We're training your{' '}
        <span className="font-bold text-indigo-400">
          Custom{policy ? ` ${policy.name}` : ''} AI Model
        </span>
        <br />
        This can take up to a few hours. We'll notify you once it's ready, or
        you can check back in on the{' '}
        <Link to="/dashboard/policies">Policies Page</Link>.
      </div>
      <div className="mt-4">
        <CoveButton
          title="Train another custom model"
          onClick={() => {
            navigate('/dashboard/policies');
          }}
        />
      </div>
    </div>
  );
}

import TrainingPipelinePrimaryButton from './components/TrainingPipelinePrimaryButton';
import TrainingPipelineSecondaryButton from './components/TrainingPipelineSecondaryButton';

export default function TrainingPipelineFooter(props: {
  primaryButton: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
  };
  secondaryButtons?: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
  }[];
  cancelButton?: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
  };
  onBack?: () => void;
}) {
  const { primaryButton, secondaryButtons, cancelButton, onBack } = props;
  return (
    <div className="sticky bottom-0 z-50 flex items-center justify-between w-full py-6 border border-b-0 border-l-0 border-r-0 border-solid px-14 border-slate-200 bg-slate-50">
      {!onBack ? null : (
        <div
          className="text-lg font-semibold cursor-pointer hover:text-black/70"
          onClick={onBack}
        >
          Go back
        </div>
      )}
      <div className="flex gap-2 last:ml-auto">
        {cancelButton ? (
          <TrainingPipelineSecondaryButton
            title={cancelButton.title}
            onClick={cancelButton.onClick}
            disabled={cancelButton.disabled}
            destructive
          />
        ) : null}
        {secondaryButtons && secondaryButtons.length > 0 ? (
          <div className="flex flex-row gap-2">
            {secondaryButtons?.map((it) => (
              <TrainingPipelineSecondaryButton
                key={it.title}
                title={it.title}
                onClick={it.onClick}
                disabled={it.disabled}
                loading={it.loading}
              />
            ))}
          </div>
        ) : null}
        <TrainingPipelinePrimaryButton
          title={primaryButton.title}
          onClick={primaryButton.onClick}
          disabled={primaryButton.disabled}
          loading={primaryButton.loading}
        />
      </div>
    </div>
  );
}

import { ChevronDown, ChevronUp } from '@/icons';
import { m } from 'framer-motion';
import { useState } from 'react';

import { FeatureSection } from './sections/landing/FeatureSection';
import { Typography } from './ui/Typography';

interface QuestionProps {
  question: string;
  answer: React.ReactNode | string;
}

function Question({ question, answer }: QuestionProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <m.div
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ amount: 0.5, once: true }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <div className="font-bold text text-left text-lg sm:text-2xl w-5/6">
          {question}
        </div>
        <ChevronUp className={`h-6 w-6 ${isExpanded ? 'hidden' : 'visible'}`} />
        <ChevronDown
          className={`h-6 w-6 ${isExpanded ? 'visible' : 'hidden'}`}
        />
      </div>
      {isExpanded ? (
        <Typography color="subtle" className="mt-4">
          {answer}
        </Typography>
      ) : null}
    </m.div>
  );
}

interface FAQProps {
  title: string;
  questions: QuestionProps[];
}

export function FAQ({ title, questions }: FAQProps) {
  return (
    <FeatureSection gap={false} orientation="ttb" title={title}>
      <div className="px-0 sm:px-8 mt-14 flex flex-col w-full">
        {questions.map((q, i) => (
          <div key={i} className="flex flex-col">
            <div className="flex w-full h-px bg-slate-200 my-6" />
            <Question key={i} {...q} />
            <div
              className={`${
                i === questions.length - 1 ? 'visible mt-6' : 'invisible mt-0'
              } flex w-full h-px bg-slate-200 `}
            />
          </div>
        ))}
      </div>
    </FeatureSection>
  );
}

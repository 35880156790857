import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { useState } from 'react';

import DemoPrimaryButton from '../components/DemoPrimaryButton';

import DemoFooter from '../DemoFooter';
// import DemoSetThresholdsDragAndDropComponent from './set_thresholds/DemoSetThresholdsDragAndDropComponent';
import DemoSetThresholdsExplanationComponent from './set_thresholds/DemoSetThresholdsExplanationComponent';

type ComparisonStep =
  | 'SelectAlternateProvider'
  | 'SelectThresholds'
  | 'Results';

const ComparisonOptions = [
  'Hive',
  'Spectrum Labs',
  'Perspective',
  'OpenAI',
  'Clarifai',
  'Sightengine',
];

const entries = [
  {
    text: "You're a loser. Don't ever talk to me again.",
    type: 'SAMPLE' as const,
    score: 0.98,
    result: 'Delete',
  },
  {
    text: 'you should go die',
    type: 'SAMPLE' as const,
    score: 0.96,
    result: 'Delete',
  },
  {
    text: 'kill. ur. self.',
    type: 'SAMPLE' as const,
    score: 0.93,
    result: 'Delete',
  },
  {
    text: "Why don't you just disappear?? No one would even miss you",
    type: 'SAMPLE' as const,
    score: 0.91,
    result: 'Delete',
  },
  {
    text: 'ur worthless lol',
    type: 'SAMPLE' as const,
    score: 0.86,
    result: 'Delete',
  },
  {
    text: 'Automatically Delete',
    type: 'THRESHOLD' as const,
    score: 0,
  },
  {
    text: 'you suck lol',
    type: 'SAMPLE' as const,
    score: 0.79,
    result: 'NeedsReview',
  },
  {
    text: 'Check urself before u wreck urself',
    type: 'SAMPLE' as const,
    score: 0.7,
    result: 'NeedsReview',
  },
  {
    text: "This man's rizz game is weakkkk",
    type: 'SAMPLE' as const,
    score: 0.64,
    result: 'NeedsReview',
  },
  {
    text: 'have you considered going to the optometrist lately? cuz you clearly have no vision...',
    type: 'SAMPLE' as const,
    score: 0.62,
    result: 'NeedsReview',
  },
  {
    text: 'Enqueue for Review',
    type: 'THRESHOLD' as const,
    score: 0,
  },
  {
    text: 'That ending was INSANE... cannot believe that happened',
    type: 'SAMPLE' as const,
    score: 0.54,
    result: 'Approved',
  },
  {
    text: 'The Patriots SUCK this year - did you see that game?',
    type: 'SAMPLE' as const,
    score: 0.41,
    result: 'Approved',
  },
  {
    text: 'Haha all good man, it happens!',
    type: 'SAMPLE' as const,
    score: 0.33,
    result: 'Approved',
  },
];

const Chart = (props: {
  entries: { color: string; value: number; label: string }[];
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-around h-24 py-4 my-4 border-t-0 border-b-0 border-l border-r-0 border-gray-400 border-dashed">
        {props.entries.map((entry) => (
          <div className="flex flex-row items-center" key={entry.value}>
            <div
              className="h-2 rounded-r-lg"
              style={{
                backgroundColor: entry.color,
                width: `${3 * entry.value}px`,
              }}
            />
            <div className="ml-2">{entry.value}</div>
          </div>
        ))}
      </div>{' '}
      <div className="flex flex-row space-x-2">
        {props.entries.map((entry) => (
          <ChartKeyEntry
            key={entry.value}
            color={entry.color}
            text={entry.label}
          />
        ))}
      </div>
    </div>
  );
};

const ChartKeyEntry = (props: { color: string; text: string }) => (
  <div className="flex flex-row items-center">
    <div
      className="w-2 h-2 mr-2 rounded-full"
      style={{ backgroundColor: props.color }}
    />
    <div>{props.text}</div>
  </div>
);

const ResultPill = (props: { type: 'Approved' | 'NeedsReview' | 'Delete' }) => {
  const { type } = props;
  switch (type) {
    case 'Approved': {
      return (
        <div className="flex items-center justify-center p-2 mx-2 text-xs font-bold rounded-full text-emerald-600 bg-emerald-100">
          Approved
        </div>
      );
    }
    case 'NeedsReview': {
      return (
        <div className="flex items-center justify-center p-2 mx-2 text-xs font-bold rounded-full text-amber-600 whitespace-nowrap bg-amber-200">
          Human-Reviewed
        </div>
      );
    }
    case 'Delete': {
      return (
        <div className="flex items-center justify-center p-2 mx-2 text-xs font-bold text-red-600 bg-red-100 rounded-full">
          Auto-deleted
        </div>
      );
    }
  }
};

export default function DemoModelComparisonScreen(props: {
  goToNextScreen: () => void;
  goBack: () => void;
  policyName: string;
  totalContent: number;
}) {
  const { goBack, policyName } = props;

  const [state, setState] = useState<{
    currentComparisonStep: ComparisonStep;
    selectedComparisonOption: (typeof ComparisonOptions)[number] | undefined;
    selectedApiKey: string | undefined;
    thresholds: { top: number; bottom: number };
  }>({
    currentComparisonStep: 'SelectAlternateProvider',
    selectedComparisonOption: undefined,
    selectedApiKey: undefined,
    thresholds: {
      top: entries.findIndex((it) => it.type === 'THRESHOLD'),
      bottom:
        entries.length -
        entries.reverse().findIndex((it) => it.type === 'THRESHOLD'),
    },
  });
  const { currentComparisonStep, selectedComparisonOption, selectedApiKey } =
    state;
  const setCurrentComparisonStep = (currentComparisonStep: ComparisonStep) =>
    setState({ ...state, currentComparisonStep });
  const setSelectedComparisonOption = (
    selectedComparisonOption: (typeof ComparisonOptions)[number] | undefined,
  ) => setState({ ...state, selectedComparisonOption });
  const setSelectedApiKey = (selectedApiKey: string | undefined) =>
    setState({ ...state, selectedApiKey });
  // const setThresholds = (thresholds: { top: number; bottom: number }) =>
  //   setState({ ...state, thresholds });

  const thresholdDragAndDropComponent = currentComparisonStep !==
    'SelectAlternateProvider' && (
    <div className="w-full py-8 pt-16">
      <div className="p-3 pl-[280px] -mt-2 mb-6 text-slate-700 font-semibold border border-solid border-slate-400 rounded-sm bg-slate-200">
        <ArrowDownOutlined className="mr-1" /> Most likely to violate your{' '}
        {policyName} policy
      </div>
      {/* <DemoSetThresholdsDragAndDropComponent
        entries={entries}
        thresholds={thresholds}
        setThresholds={setThresholds}
        totalContent={totalContent}
      /> */}
      <div className="p-3 pl-[280px] mt-4 text-slate-700 font-semibold border border-solid border-slate-400 rounded-sm bg-slate-200">
        <ArrowUpOutlined /> Least likely to violate your {policyName} policy
      </div>
    </div>
  );

  const alternativeProviderComponent = (
    <div
      className={`flex flex-col items-center text-start ${
        currentComparisonStep === 'SelectAlternateProvider' ? 'pt-48' : 'pt-0'
      }`}
    >
      <div className="flex flex-col m-8 items-start min-h-screen max-w-[60%] w-auto">
        <div className="w-full">
          <div className="text-2xl font-semibold">
            Evaluate your {policyName} model against alternatives
          </div>
          <br />
          <div className="pb-6 text-base text-slate-500">
            Let's compare your Cove {policyName} AI moderator to alternatives
          </div>
          <div className="flex flex-col items-start justify-center w-full gap-4">
            <div className="flex items-center justify-start w-full">
              <div className="text-slate-500 w-44">Compare Cove to:</div>
              <Select
                className="text-slate-500"
                placeholder="Search for an alternative"
                value={selectedComparisonOption}
                onSelect={(value) => setSelectedComparisonOption(value)}
                dropdownMatchSelectWidth={false}
              >
                {ComparisonOptions.map((option) => (
                  <div key={option}>{option}</div>
                ))}
              </Select>
            </div>
            {selectedComparisonOption && (
              <div className="flex items-center justify-start w-full">
                <div className="text-slate-500 w-44">
                  Your {selectedComparisonOption} API Key:
                </div>
                <Input
                  className="flex items-center justify-center px-4 py-2 rounded-sm text-slate-500 w-fit"
                  value={selectedApiKey}
                  onChange={(event) => setSelectedApiKey(event.target.value)}
                />
              </div>
            )}
          </div>
          {currentComparisonStep === 'SelectAlternateProvider' ? (
            <div className="pt-6">
              <DemoPrimaryButton
                title="Next"
                disabled={
                  selectedApiKey === undefined || selectedApiKey.length === 0
                }
                onClick={() => setCurrentComparisonStep('SelectThresholds')}
              />
            </div>
          ) : null}
        </div>
        {currentComparisonStep === 'SelectThresholds' ? (
          <div className="flex flex-col py-10">
            <div className="pb-2 text-xl font-bold">
              Step 1: Set your {policyName} thresholds
            </div>
            <div className="pb-4 text-slate-500">
              To compare apples to apples, you first need to set the same two
              thresholds for {selectedComparisonOption}'s {policyName} AI model.
            </div>
            <div className="w-full pb-2">
              <DemoSetThresholdsExplanationComponent
                setTotalContent={(_: number) => {}}
              />
            </div>
            {selectedComparisonOption === 'Hive' ? (
              <div className="text-slate-500">
                Note: Hive's only scores are 3, 2, 1, and 0.
              </div>
            ) : selectedComparisonOption === 'Spectrum Labs' ? (
              <div className="text-slate-500">
                Note: Spectrum Lab's only scores are High, Low, and Not Detected
              </div>
            ) : null}
            {thresholdDragAndDropComponent}
            <DemoPrimaryButton
              title="Next"
              onClick={() => setCurrentComparisonStep('Results')}
            />
          </div>
        ) : null}
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Close',
          onClick: () => setCurrentComparisonStep('Results'),
          disabled: currentComparisonStep !== 'Results',
        }}
        onBack={goBack}
      />
    </div>
  );

  const resultsComponent = (
    <div className="flex flex-col items-center text-start">
      <div className="flex flex-col m-8 items-start min-h-screen max-w-[60%] w-auto">
        <div className="flex flex-col w-auto">
          <div className="text-2xl font-semibold">
            Evaluate {policyName} model performance
          </div>
          <br />
          <div className="pb-6 text-base font-bold">
            Step 2: Compare Results
          </div>
          <div className="flex flex-col w-full p-6 bg-gray-100 border border-gray-200 border-solid rounded-md">
            <span className="font-semibold pb-6">
              Cove has <span className="text-cove-blue">15% more</span>{' '}
              automatically deleted and{' '}
              <span className="text-cove-blue">25% fewer</span> manually
              reviewed than {selectedComparisonOption}
            </span>
            <div className="flex flex-row">
              <div className="flex flex-col w-1/2">
                <div className="font-semibold text-slate-500">AUTO-DELETED</div>
                <Chart
                  entries={[
                    { color: '#6aa9f6', value: 60, label: 'Cove' },
                    {
                      color: '#94A3B8',
                      value: 40,
                      label: selectedComparisonOption!,
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col w-1/2">
                <div className="text-slate-500">HUMAN-REVIEWED</div>
                <Chart
                  entries={[
                    { color: '#6aa9f6', value: 20, label: 'Cove' },
                    {
                      color: '#94A3B8',
                      value: 60,
                      label: selectedComparisonOption!,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <table className="w-full mt-8">
            <thead>
              <tr>
                <th className="py-2 text-lg font-semibold text-start">
                  Here’s how examples are handled by Cove vs.{' '}
                  {selectedComparisonOption}
                </th>
                <th className="text-lg font-semibold text-slate-500">Cove</th>
                <th className="text-lg font-semibold text-slate-500">
                  {selectedComparisonOption}
                </th>
              </tr>
            </thead>
            <tbody>
              {entries
                .filter((it) => it.type !== 'THRESHOLD')
                .map((entry, i) => (
                  <tr key={i}>
                    <td className="py-2">
                      <div className="p-2 bg-gray-100 border border-gray-200 border-solid rounded-md">
                        {entry.text}
                      </div>
                    </td>
                    <td>
                      <ResultPill
                        type={
                          entry.result! as 'Delete' | 'NeedsReview' | 'Approved'
                        }
                      />
                    </td>
                    <td>
                      <ResultPill
                        type={
                          Math.random() > 0.7
                            ? 'Approved'
                            : Math.random() > 0.85
                            ? 'Delete'
                            : 'NeedsReview'
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Close',
          onClick: goBack,
          disabled: currentComparisonStep !== 'Results',
        }}
        onBack={goBack}
      />
    </div>
  );

  const currentComponent = (() => {
    switch (currentComparisonStep) {
      case 'Results':
        return resultsComponent;
      case 'SelectAlternateProvider':
      case 'SelectThresholds':
        return alternativeProviderComponent;
    }
  })();

  return currentComponent;
}

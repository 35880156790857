import {
  BoltFilled,
  DirectionAlt,
  GraphGrowthFilled,
  IdeaReloadFilled,
  MediaServer,
  Previous,
} from '@/icons';

import { FeatureTable } from '../../FeatureTable';

export function Policies() {
  return (
    <FeatureTable
      title="An advanced AI model for every policy"
      items={[
        {
          icon: BoltFilled,
          title: 'Fast and easy',
          description:
            'Get models up and running in under 10 minutes - all we need is a policy definition and a few minutes if labeling.',
        },
        {
          icon: IdeaReloadFilled,
          title: 'Self-improving',
          description:
            'When combined with our Moderator Console, your AI models learn from moderator decisions to continuously improve themselves.',
        },
        {
          icon: MediaServer,
          title: 'All types of content',
          description:
            'Create and train your custom models across text, audio, and images, with video support coming soon.',
        },
        {
          icon: Previous,
          title: 'Backtesting',
          description:
            'See how your new model performs on past data before deploying it.',
        },
        {
          icon: GraphGrowthFilled,
          title: 'Track model performance',
          description:
            'See how your model’s performance changes and improves over time, and make adjustments on the fly.',
        },
        {
          icon: DirectionAlt,
          title: 'Take action automatically',
          description:
            'Use your AI models to make automated moderation decisions with our Automated Enforcement product.',
        },
      ]}
    />
  );
}

import { useEffect, useState } from 'react';

import GreenCheck from '../../../../../images/GreenCheck.png';
import LogoGradient from '../../../../../images/LogoGradient.png';
import DemoFooter from '../DemoFooter';

export default function DemoModelLoadingScreen(props: {
  goToNextScreen: () => void;
  goBack: () => void;
  policyName: string;
}) {
  const { goToNextScreen, goBack, policyName } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsButtonDisabled(false), 5000);
  });

  // TODO: add a GQL query that checks whether the new ML model has been trained.
  // Use Apollo's pollInterval param to re-run the query every few seconds.

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-center justify-center h-full max-w-2xl text-start">
        <div className="pb-6 text-3xl font-bold">
          Creating your {policyName} AI model
        </div>
        <div className="pb-4 text-base text-slate-500">
          This can take up to 1 minute.
        </div>

        <div className="relative w-full h-48">
          {isButtonDisabled ? (
            <>
              <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <img alt="logo" src={LogoGradient} width={75} />
              </div>
              <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-36 h-36 border border-dashed border-[#8087F7] border-t-[#8087F733] rounded-full animate-spin-slow" />
              </div>
            </>
          ) : (
            <img
              alt="logo"
              src={GreenCheck}
              className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-36 h-36"
            />
          )}
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          disabled: isButtonDisabled,
          title: 'Continue',
          onClick: goToNextScreen,
        }}
        onBack={goBack}
      />
    </div>
  );
}

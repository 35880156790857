import { useGQLUserAndOrgQuery } from '@/graphql/generated';
import ManualReviewJobContentBlurableImage from '@/webpages/dashboard/mrt/manual_review_job/ManualReviewJobContentBlurableImage';
import _ from 'lodash';
import Papa from 'papaparse';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import CopyTextComponent from '@/components/common/CopyTextComponent';
import CoveSelect from '@/components/common/CoveSelect';
import FullScreenLoading from '@/components/common/FullScreenLoading';

type LabelType = 'violates' | 'does_not_violate' | 'borderline' | 'unlabeled';

const updateStoredData = (
  fileName: string,
  labels: Record<string, LabelType>,
  csvData: Record<string, unknown>[],
) => {
  const storedData = localStorage.getItem('labelingData');
  const parsedData = storedData ? JSON.parse(storedData) : {};
  parsedData[fileName] = { labels, csvData };
  localStorage.setItem('labelingData', JSON.stringify(parsedData));
};

const getAllStoredData = (): Record<
  string,
  {
    labels: Record<string, LabelType>;
    csvData: Record<string, unknown>[];
  }
> => {
  const storedData = localStorage.getItem('labelingData');
  return storedData ? JSON.parse(storedData) : {};
};

export default function InternalLabeling() {
  const { data, loading } = useGQLUserAndOrgQuery();
  const userEmail = data?.me?.email;

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [selectedJobName, setSelectedJobName] = useState<string | undefined>(
    undefined,
  );
  const [csvData, _setCsvData] = useState<
    { id: string; [key: string]: unknown }[]
  >([]);
  const [dataToViolatesMap, setDataToViolatesMap] = useState<
    Record<string, LabelType>
  >({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dropdownOptions, setDropdownOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const resetState = useCallback(() => {
    setSelectedJobName(undefined);
    setCsvData([]);
    setDataToViolatesMap({});
    setFileName(undefined);
    setSelectedIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCsvData = useCallback(
    (data: { id: string; [key: string]: unknown }[]) => {
      _setCsvData(data);
      setDataToViolatesMap(
        Object.fromEntries(
          data
            .map((data) => [data.id, 'unlabeled'])
            .filter(([id, _]) => id !== ''),
        ),
      );
    },
    [],
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const items: Record<string, unknown>[] = [];
          for (const item of results.data) {
            if (
              typeof item === 'object' &&
              item !== null &&
              'id' in item &&
              typeof item.id === 'string'
            ) {
              items.push(_.omit(item, '_1', '_2', '""', 'label'));
            }
          }

          const newCsvData = items as { id: string; [key: string]: unknown }[];
          const newDataToViolatesMap = Object.fromEntries(
            Object.keys(items)
              .filter((id) => id !== '')
              .map((id) => [id, 'unlabeled' as const]),
          );
          const newFileName = file.name;
          const newSelectedJobName = file.name;
          const newSelectedIndex = 0;
          const newDropdownOptions = [
            ...dropdownOptions,
            { value: file.name, label: file.name },
          ];

          setCsvData(newCsvData);
          setFileName(newFileName);
          setSelectedJobName(newSelectedJobName);
          setSelectedIndex(newSelectedIndex);
          setDropdownOptions(newDropdownOptions);

          updateStoredData(newFileName, newDataToViolatesMap, newCsvData);
        },
      });
    }
  };

  const markAsViolating = useCallback(() => {
    setDataToViolatesMap((prev) => {
      const currentId = csvData[selectedIndex].id;
      const updatedMap = {
        ...prev,
        [currentId]: 'violates' as const,
      };
      if (selectedJobName) {
        updateStoredData(selectedJobName, updatedMap, csvData);
      }
      return updatedMap;
    });
  }, [selectedIndex, selectedJobName, csvData]);

  const markAsNotViolating = useCallback(() => {
    setDataToViolatesMap((prev) => {
      const currentId = csvData[selectedIndex].id;
      const updatedMap = {
        ...prev,
        [currentId]: 'does_not_violate' as const,
      };
      if (selectedJobName) {
        updateStoredData(selectedJobName, updatedMap, csvData);
      }
      return updatedMap;
    });
  }, [selectedIndex, selectedJobName, csvData]);

  const markAsBorderline = useCallback(() => {
    setDataToViolatesMap((prev) => {
      const currentId = csvData[selectedIndex].id;
      const updatedMap = {
        ...prev,
        [currentId]: 'borderline' as const,
      };
      if (selectedJobName) {
        updateStoredData(selectedJobName, updatedMap, csvData);
      }
      return updatedMap;
    });
  }, [selectedIndex, selectedJobName, csvData]);

  useEffect(() => {
    const moveToNextItem = () => {
      setSelectedIndex((prevIndex) => prevIndex + 1);
    };

    const handleKeyDown = (event: { key: string }) => {
      if (selectedIndex >= csvData.length) {
        return;
      }

      if (event.key === 'ArrowLeft') {
        markAsNotViolating();
        moveToNextItem();
      } else if (event.key === 'ArrowRight') {
        markAsViolating();
        moveToNextItem();
      } else if (event.key === 'ArrowUp') {
        markAsBorderline();
        moveToNextItem();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    csvData,
    markAsBorderline,
    markAsNotViolating,
    markAsViolating,
    selectedIndex,
  ]);

  const storedData = getAllStoredData();
  const storedJobNames = Object.keys(storedData);
  useEffect(() => {
    setDropdownOptions(
      storedJobNames.map((jobName) => ({
        value: jobName,
        label: jobName,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (
    !userEmail?.includes('@getcove.com') &&
    !userEmail?.includes('@example.com')
  ) {
    return <Navigate replace to="/" />;
  }

  const labelingOutput = JSON.stringify(
    csvData.map((row) => dataToViolatesMap[row.id] || 'unlabeled'),
    null,
    2,
  )
    .slice(2, -1)
    .split('\n')
    .map((it) => it.slice(2, -1))
    .join('\n')
    .replace(/"/g, '')
    .replace(/violates/g, '1')
    .replace(/does_not_violate/g, '0')
    .replace(/borderline/g, '0.5')
    .replace(/unlabeled/g, '');

  const jobSelector = (
    <CoveSelect
      mode="single"
      placeholder="Select or upload job"
      value={selectedJobName}
      onSelect={(value) => {
        if (value === 'upload') {
          setSelectedJobName(undefined);
          fileInputRef.current?.click();
        } else if (value === 'delete') {
          localStorage.removeItem('labelingData');
          resetState();
        } else {
          // Set state variables for existing job
          const jobData = storedData[value];
          if (jobData) {
            setCsvData(
              jobData.csvData as { id: string; [key: string]: unknown }[],
            );
            setDataToViolatesMap(jobData.labels);
            setSelectedIndex(0);
            setSelectedJobName(value);
          } else {
            alert('Job data not found. Reupload csv file.');
          }
        }
      }}
      onDeselect={resetState}
      options={[
        ...dropdownOptions,
        { value: 'upload', label: 'Upload New File' },
        ...(dropdownOptions.length > 0
          ? [{ value: 'delete', label: 'Delete All' }]
          : []),
      ].filter(
        (option): option is { value: string; label: string } => option !== null,
      )}
    />
  );

  const contentSection =
    Object.values(dataToViolatesMap).every((it) => it !== 'unlabeled') &&
    selectedIndex >= csvData.length ? (
      <div className="flex flex-row self-start w-1/2 gap-2 p-4 mt-4 bg-green-100 border border-green-500 rounded-md">
        <div className="font-semibold text-green-700">
          All items have been labeled!
        </div>
        <CopyTextComponent
          value={labelingOutput}
          displayValue="Click here to copy labels"
        />
      </div>
    ) : (
      <pre className="w-1/2 p-4 overflow-auto bg-gray-100 rounded-md text-wrap">
        <div className="mb-4">
          <strong>Current Label:</strong>
          <div className="mt-1 ml-4">
            {dataToViolatesMap[csvData[selectedIndex].id]}
          </div>
        </div>
        {Object.entries(csvData[selectedIndex])
          .sort(([key_a, _], [key_b, __]) =>
            key_a === 'images' ? -1 : key_b === 'images' ? 1 : 0,
          )
          .map(([key, value]) =>
            key.length > 0 ? (
              <div key={key} className="mb-4">
                <strong>{key}:</strong>
                {key === 'images' ? (
                  // NB: this div is needed to make sure the image renders below
                  // the label instead of next to it
                  <div>
                    <ManualReviewJobContentBlurableImage
                      url={value as string}
                      options={{ grayscale: true }}
                    />
                  </div>
                ) : (
                  <div className="mt-1 ml-4">
                    {(value as string)
                      .replace(/\\n/g, '\n')
                      .replace(/\\t/g, '\t')
                      .replace(/&quot;/g, '"')
                      .replace(/&#39;/g, "'")
                      .replace(/\\/g, '')}
                  </div>
                )}
              </div>
            ) : null,
          )}
      </pre>
    );

  const buttonSection = (
    <div className="flex flex-col items-start gap-2">
      <div className="flex flex-row gap-4 p-4">
        <div
          className={`px-2 py-1 border border-solid select-none ${
            selectedIndex > 0
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            if (selectedIndex > 0) {
              setSelectedIndex((prevIndex) => prevIndex - 1);
            }
          }}
        >
          Previous
        </div>
        <div
          className={`px-2 py-1 border border-solid select-none ${
            selectedIndex < csvData.length - 1 ||
            (selectedIndex === csvData.length - 1 &&
              csvData.every(
                (item) => dataToViolatesMap[item.id] !== 'unlabeled',
              ))
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            if (selectedIndex < csvData.length - 1) {
              setSelectedIndex((prevIndex) => prevIndex + 1);
            } else if (
              selectedIndex === csvData.length - 1 &&
              csvData.every(
                (item) => dataToViolatesMap[item.id] !== 'unlabeled',
              )
            ) {
              setSelectedIndex(csvData.length);
            }
          }}
        >
          Next
        </div>
        <div
          className={`px-2 py-1 border border-solid select-none ${
            csvData.some((item, index) => index > selectedIndex)
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            const nextUnlabeledIndex = csvData.findIndex(
              (item, _) => dataToViolatesMap[item.id] === 'unlabeled',
            );
            if (nextUnlabeledIndex !== -1) {
              setSelectedIndex(nextUnlabeledIndex);
            } else {
              setSelectedIndex(csvData.length);
            }
          }}
        >
          Jump to Next Unlabeled Sample
        </div>
      </div>
      <div className="flex gap-8 ">
        <button
          onClick={() => {
            markAsNotViolating();
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }}
          className="px-4 py-2 my-2 text-white bg-blue-500 rounded cursor-pointer disabled:bg-gray-300"
        >
          ← Does not violate
        </button>
        <button
          onClick={() => {
            markAsBorderline();
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }}
          className="px-4 py-2 my-2 text-white bg-blue-500 rounded cursor-pointer disabled:bg-gray-300"
        >
          Borderline ↑
        </button>
        <button
          onClick={() => {
            markAsViolating();
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }}
          className="px-4 py-2 my-2 text-white bg-blue-500 rounded cursor-pointer disabled:bg-gray-300"
        >
          Violates →
        </button>
      </div>
    </div>
  );

  const labelingStats = (
    <div className="mb-8">
      <h3 className="mb-2 text-lg font-semibold">Labeling Statistics</h3>
      <div className="p-4 bg-gray-100 rounded-md">
        {Object.entries(
          Object.values(dataToViolatesMap).reduce(
            (acc, curr) => {
              acc[curr] = (acc[curr] || 0) + 1;
              return acc;
            },
            {} as Record<string, number>,
          ),
        ).map(([label, count]) => (
          <div key={label} className="flex justify-between mb-2">
            <span>{label}:</span>
            <span>{count}</span>
          </div>
        ))}
        <div className="flex justify-between pt-2 mt-2 font-semibold border-t border-gray-300">
          <span>Total:</span>
          <span>{Object.keys(dataToViolatesMap).length}</span>
        </div>
      </div>
      <CopyTextComponent value={labelingOutput} displayValue="Copy labels" />
    </div>
  );

  return (
    <div className="p-8">
      <h1>Internal Labeling</h1>
      {jobSelector}
      <input
        type="file"
        ref={fileInputRef}
        // Hidden because we're using the CoveSelect to trigger the file upload,
        // we just need to set the ref here so that the file input is created
        style={{ display: 'none' }}
        accept=".csv"
        onChange={handleFileUpload}
      />
      {csvData.length > 0 && (
        <div className="mt-4">
          <h3>
            {selectedIndex >= csvData.length
              ? 'All done!'
              : `Labeling Item ${selectedIndex + 1} of ${csvData.length}`}
          </h3>
          {buttonSection}
          <div className="flex flex-row gap-4">
            {contentSection}
            <div className="flex flex-col w-1/2">
              {csvData.length > 0 && labelingStats}
              <textarea className="w-full h-48 p-2 border border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { useGQLInternalModelTrainingPageDataQuery } from '@/graphql/generated';
import { gql } from '@apollo/client';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import CoveSelect from '@/components/common/CoveSelect';
import FullScreenLoading from '@/components/common/FullScreenLoading';

gql`
  query InternalModelTrainingPageData {
    myOrg {
      id
      email
      name
      policies {
        id
        name
      }
    }
    me {
      id
      email
    }
  }
`;

export default function InternalModelTraining() {
  const [selectedPolicyId, setSelectedPolicyId] = useState<string | undefined>(
    undefined,
  );

  const { data, loading, error } = useGQLInternalModelTrainingPageDataQuery();
  if (loading) {
    return <FullScreenLoading />;
  }

  if (error) {
    throw error;
  }

  const userEmail = data?.me?.email;
  if (
    !userEmail?.includes('@getcove.com') &&
    !userEmail?.includes('@example.com')
  ) {
    return <Navigate replace to="/" />;
  }

  const policies = data?.myOrg?.policies;

  return (
    <div className="flex flex-col gap-4 p-8">
      <div className="text-2xl font-bold">Model Training Label Debugging</div>
      {policies && (
        <div className="flex flex-col gap-2">
          <div className="text-sm font-bold">Policy</div>
          <div className="w-24">
            <CoveSelect
              placeholder="Select a policy"
              value={selectedPolicyId}
              onSelect={setSelectedPolicyId}
              options={
                policies
                  .map((policy) => ({
                    value: policy.id,
                    label: policy.name,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)) ?? []
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

import { GQLUserPermission, useGQLPermissionsQuery } from '@/graphql/generated';
import { TextUnderlineAlt, World2 } from '@/icons';
import { userHasPermissions } from '@/routing/permissions';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import CoveButton from '../components/CoveButton';
import DashboardHeader from '../components/DashboardHeader';
import TabBar from '../components/TabBar';

import LocationBanksDashboard from './location/LocationBanksDashboard';
import TextBanksDashboard from './text/TextBanksDashboard';

const MatchingBanksDashboardTabs = ['TEXT', 'LOCATION'] as const;
type MatchingBanksDashboardTab = (typeof MatchingBanksDashboardTabs)[number];

export default function MatchingBanksDashboard() {
  const [searchParams] = useSearchParams();
  const kindInSearchParams = searchParams.get('kind');
  const [selectedTab, setSelectedTab] = useState<MatchingBanksDashboardTab>(
    kindInSearchParams &&
      MatchingBanksDashboardTabs.includes(
        kindInSearchParams as MatchingBanksDashboardTab,
      )
      ? (kindInSearchParams as MatchingBanksDashboardTab)
      : 'TEXT',
  );
  const [canEditBanks, setCanEditBanks] = useState(true);
  const { data } = useGQLPermissionsQuery();

  const permissions = data?.me?.permissions;
  useMemo(
    () =>
      setCanEditBanks(
        userHasPermissions(permissions, [GQLUserPermission.MutateLiveRules]),
      ),
    [permissions],
  );

  const createButton = (
    <CoveButton
      title={`Create ${_.capitalize(selectedTab.toLowerCase())} Bank`}
      destination={`form/${selectedTab === 'TEXT' ? 'text' : 'location'}`}
      disabled={!canEditBanks}
      disabledTooltipTitle="To create Matching Banks, ask your organization's admin to upgrade your role to Admin."
      disabledTooltipPlacement="bottomRight"
    />
  );

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Matching Banks</title>
      </Helmet>
      <DashboardHeader
        title="Matching Banks"
        subtitle="Matching banks are sets of values that you can reference in your rules. If you want to reuse the same set of values across multiple rules, you can create a bank holding all those values, and then easily reference that bank in your rules."
        rightComponent={createButton}
      />
      <TabBar
        tabs={[
          {
            label: 'Text Banks',
            icon: <TextUnderlineAlt />,
            value: 'TEXT',
          },
          {
            label: 'Location Banks',
            icon: <World2 />,
            value: 'LOCATION',
          },
        ]}
        initialSelectedTab={selectedTab}
        onTabClick={(val) => setSelectedTab(val)}
        currentSelectedTab={selectedTab}
      />
      {selectedTab === 'TEXT' ? (
        <TextBanksDashboard />
      ) : (
        <LocationBanksDashboard />
      )}
    </div>
  );
}

import { Button, Tag } from 'antd';
import { useMemo } from 'react';
import { Row } from 'react-table';

import Table from '../../../components/table/Table';

export default function RuleBacktestResultsTable(props: {
  onSelectRow: (row: Row<any>) => void;
  results: string[];
}) {
  const { results } = props;

  const columns = useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
      },
      {
        Header: 'actions',
        accessor: 'actions',
        Cell: (props: any) => (
          <div>
            {props.cell.value.length > 0
              ? props.cell.value.map((action: string) => (
                  <Tag key={action}>{action}</Tag>
                ))
              : 'None'}
          </div>
        ),
      },
      {
        Header: 'Text',
        accessor: 'text',
      },
      {
        Header: 'Images',
        accessor: 'images',
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      results.map((result) => {
        return {
          id: result,
          actions: Number(result) % 2 === 0 ? [] : ['Delete', 'Ban User'],
          text: 'This is some text in a post. It might contain hate speech.',
          images: ['https://s3.amazon.com/mywebsite/images/upload.png'],
        };
      }),
    [results],
  );

  return (
    <div className="rounded-lg border-none text-start pt-4 pb-2 bg-white shadow overflow-x-scroll">
      <div className="flex justify-between pr-4">
        <div className="flex pl-4 text-2xl font-bold">Results</div>
        <Button type="primary">Download CSV</Button>
      </div>
      <div className="overflow-y-scroll px-4 py-2 mt-6 mb-2 mr-4">
        {/* @ts-ignore */}
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
}

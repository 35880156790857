import { Button } from 'antd';

import { DefaultPolicies } from '../Demo';
import DemoFooter from '../DemoFooter';

export default function DemoPolicyCompletedScreen(props: {
  goBack: () => void;
  goToCompareAlternatives: () => void;
  addCompletedPolicy: (policy: string) => void;
  policyName: string;
  nextPolicyName?: string;
}) {
  const {
    goToCompareAlternatives,
    goBack,
    addCompletedPolicy,
    policyName,
    nextPolicyName,
  } = props;
  const isComparable = DefaultPolicies.includes(policyName);

  const compareButton = (
    <Button
      disabled={!isComparable}
      className="flex items-center justify-center px-6 py-5 text-base font-semibold text-white border-none rounded-full w-fit bg-cove-blue hover:bg-cove-blue-hover hover:text-white focus:text-white focus:bg-cove-blue"
      onClick={() => goToCompareAlternatives()}
    >
      Compare to alternatives
    </Button>
  );

  const comparisonComponent = isComparable ? (
    <div className="flex flex-col">
      <div className="pb-4 text-base text-slate-500">
        If you want to compare the AI moderator to alternatives, you can do so.
        Otherwise, you can move on to the next policy, or save your progress and
        come back to it later.
      </div>
      {compareButton}
    </div>
  ) : (
    <div className="flex flex-col text-base text-slate-500">
      <div className="pb-4">
        Your AI moderator is one-of-a-kind! There are no other AI models on the
        market that are sufficiently similar to this policy, so you've
        officially created the best {policyName} AI model around.
      </div>
      {compareButton}
      <div className="py-4">
        You can move on to the next policy, or save your progress and come back
        to it later.
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-start justify-center h-full max-w-2xl text-start">
        <div className="pb-6 text-3xl font-bold">
          Your {policyName} AI moderator is complete!
        </div>
        <div className="pb-4 text-base text-slate-500">
          Congratulations! You've finished teaching your AI moderator how to
          detect {policyName}. Once you finish the Cove Demo process, we'll add
          your AI moderator to a Cove Rule, which will automatically start
          enforcing your {policyName} policy on all of the content you send to
          Cove.
        </div>
        {comparisonComponent}
      </div>
      <DemoFooter
        primaryButton={{
          title: nextPolicyName ? `Next policy: ${nextPolicyName}` : 'Done',
          onClick: () => addCompletedPolicy(policyName),
        }}
        secondaryButton={{
          title: 'Save as draft',
          onClick: () => {
            /* TODO: save model, don't deploy it */
            addCompletedPolicy(policyName);
          },
        }}
        onBack={goBack}
      />
    </div>
  );
}

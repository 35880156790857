import { m, type Variants } from 'framer-motion';

const noticeVariants: Variants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 1.5,
      stiffness: 100,
      damping: 30,
      delay: 1.6,
    },
  },
};

export function ThreatNotice() {
  return (
    <div className="w-1/3 absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2">
      <m.img
        variants={noticeVariants}
        initial="hidden"
        animate="visible"
        src="/graphics/threat-notice.svg"
      />
    </div>
  );
}

import { Funnel } from '@ant-design/charts';
import { Row } from 'react-table';

import RoundedTag from '@/webpages/dashboard/components/RoundedTag';

export default function RuleBacktestResultView(props: {
  selectedRow: Row<{ id: string; actions: string[] }> | null;
}) {
  const { selectedRow } = props;

  if (selectedRow == null) {
    return null;
  }

  const data = [
    {
      condition: 'Condition 1:',
      value: 1353,
    },
    {
      condition: 'Condition 2:',
      value: 1151,
    },
    {
      condition: 'Condition 3:',
      value: 613,
    },
    {
      condition: 'Condition 4:',
      value: 487,
    },
  ];
  const config = {
    data,
    xField: 'condition',
    yField: 'number',
  };
  return (
    <div className="p-4 mb-2 bg-white border-none rounded-lg shadow text-start">
      <div className="flex text-2xl font-bold">Actions</div>
      <div className="flex pb-4 text-sm text-slate-500">
        For the content with ID {selectedRow.original.id}, these are the actions
        this Rule would apply:
      </div>
      {selectedRow.original.actions.length > 0 ? (
        selectedRow.original.actions.map((action) => (
          <RoundedTag key={action} title={action} />
        ))
      ) : (
        <div className="pb-2 pl-2 text-lg">No actions applied</div>
      )}
      <div className="divider mb-4 mt-6" />
      <div className="flex text-2xl font-bold">Conditions Funnel</div>
      <div className="flex pb-8 text-sm text-slate-500">
        {selectedRow.original.actions.length === 0
          ? 'See which conditions failed that prevented any actions from executing'
          : selectedRow.original.actions.length === 1
          ? 'See which conditions the content passed that caused the action to execute'
          : 'See which conditions the content passed that caused the actions to execute'}
      </div>
      <Funnel {...config} />
    </div>
  );
}

import { Checkbox, Label } from '@/cove-ui';
import { CheckmarkCircleFilled } from '@/icons';
import useRouteQueryParams from '@/routing/useRouteQueryParams';
import { CloseOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Input, Select } from 'antd';
import { useState } from 'react';

import {
  GQLRequestDemoInterest,
  useGQLRequestDemoMutation,
} from '../../../graphql/generated';
import { titleCaseEnumString } from '../../../utils/string';
import { Button } from './ui/Button';

const { Option } = Select;

type DemoEntrypoint =
  | 'NAVIGATION'
  | 'CTA'
  | 'CUSTOM_MODELS'
  | 'HOME'
  | 'NO_CODE'
  | 'AI_MODELS'
  | 'COMPLIANCE'
  | 'ENFORCEMENT'
  | 'MODERATION_CONSOLE'
  | 'OPEN_AI'
  | 'BLOG_POST_PLATFORM_POLICIES_PT_1';

gql`
  mutation RequestDemo($input: RequestDemoInput!) {
    requestDemo(input: $input)
  }
`;

function RequestDemoInput(props: {
  header: string;
  placeholder: string;
  currentValue: string | undefined;
  onChange: (value: string) => void;
}) {
  const { header, placeholder, onChange } = props;
  return (
    <div className="flex flex-col items-start">
      <div className="mb-2">{header}</div>
      <Input
        className="rounded-lg"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default function RequestDemoModal(props: {
  visible: boolean;
  entrypoint: DemoEntrypoint;
  onClose: () => void;
}) {
  const { visible, onClose, entrypoint } = props;
  const queryParams = useRouteQueryParams();

  const [state, setState] = useState<{
    email: string | undefined;
    company: string | undefined;
    website: string | undefined;
    selectedInterests: GQLRequestDemoInterest[];
    agreementChecked: boolean;
  }>({
    email: undefined,
    company: undefined,
    website: undefined,
    selectedInterests: [],
    agreementChecked: false,
  });
  const { email, company, website, selectedInterests, agreementChecked } =
    state;

  const setEmail = (email: string | undefined) =>
    setState((prev) => ({ ...prev, email }));
  const setCompany = (company: string | undefined) =>
    setState((prev) => ({ ...prev, company }));
  const setWebsite = (website: string | undefined) =>
    setState((prev) => ({ ...prev, website }));
  const setSelectedInterests = (selectedInterests: GQLRequestDemoInterest[]) =>
    setState((prev) => ({ ...prev, selectedInterests }));
  const setAgreementChecked = (agreementChecked: boolean) =>
    setState((prev) => ({ ...prev, agreementChecked }));

  const [requestDemo, { loading }] = useGQLRequestDemoMutation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const closeModal = () => {
    onClose();
    setState({
      email: undefined,
      company: undefined,
      website: undefined,
      selectedInterests: [],
      agreementChecked: false,
    });
  };

  if (!visible) {
    return null;
  }

  const formReadyToSubmit =
    email &&
    company &&
    website &&
    agreementChecked &&
    selectedInterests.length > 0;

  return (
    <div
      className="fixed inset-0 z-50 w-screen h-screen pt-12 bg-opacity-50 bg-slate-500 sm:pt-48"
      onClick={closeModal}
    >
      {showSuccessMessage ? (
        <div className="flex items-center justify-center">
          <div
            className="relative flex flex-col items-center self-center justify-center max-w-sm p-8 bg-white md:max-w-6xl rounded-lg2 grow-0"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="flex flex-col items-center justify-center gap-4 px-6 py-12 text-2xl font-bold md:py-16 md:px-24">
              <div className="text-green-500 w-14">
                <CheckmarkCircleFilled />
              </div>
              We'll reach out soon!
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <div
            className="relative flex flex-col items-center self-center justify-center max-w-sm p-8 bg-white md:max-w-6xl rounded-lg2 grow-0"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="text-2xl font-bold">Schedule a Demo</div>
            <div className="mx-4 my-4 md:mx-40">
              Share your info and we'll reach out to you as soon as we can.
            </div>
            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 md:grid-rows-2">
              <RequestDemoInput
                header="Email"
                placeholder="me@acme.com"
                currentValue={email}
                onChange={(value) => setEmail(value)}
              />
              <RequestDemoInput
                header="Company"
                placeholder="Acme Co."
                currentValue={company}
                onChange={(value) => setCompany(value)}
              />
              <RequestDemoInput
                header="Website"
                placeholder="acme.com"
                currentValue={website}
                onChange={(value) => setWebsite(value)}
              />
              <div className="flex flex-col text-start">
                <div className="mb-2">I'm interested in</div>
                <Select
                  placeholder="Select all that apply"
                  dropdownMatchSelectWidth={false}
                  value={selectedInterests}
                  mode="multiple"
                  onChange={(values) => setSelectedInterests(values)}
                  showArrow
                  showSearch={false}
                >
                  {Object.values(GQLRequestDemoInterest).map((interest) => (
                    <Option key={interest} value={interest}>
                      {interest === 'CUSTOM_AI_MODELS'
                        ? 'Custom AI Models'
                        : titleCaseEnumString(interest)}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex flex-row items-center self-start my-4 text-xs gap-x-2 text-start">
              <Checkbox
                id="agreement"
                checked={agreementChecked}
                onCheckedChange={setAgreementChecked}
              />
              <Label htmlFor="agreement">
                I have read, understood, and agree to the{' '}
                <a
                  className="text-primary hover:text-primary hover:opacity-75"
                  href="/terms"
                  target="_blank"
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  className="text-primary hover:text-primary hover:opacity-75"
                  href="/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                provided herein.
              </Label>
            </div>
            <Button
              size="lg"
              disabled={!formReadyToSubmit || loading}
              onClick={() => {
                requestDemo({
                  variables: {
                    input: {
                      email: email!,
                      company: company!,
                      website: website!,
                      interests: selectedInterests,
                      ref: entrypoint,
                      isFromGoogleAds: queryParams.get('source') === 'google',
                    },
                  },
                  onCompleted: () => setShowSuccessMessage(true),
                  onError: () => {},
                });
              }}
            >
              Schedule a Demo
            </Button>
            <div
              className="absolute cursor-pointer top-4 right-4"
              onClick={closeModal}
            >
              <CloseOutlined />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { ChevronRightFilled } from '@/icons';
import { m } from 'framer-motion';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { HorizontalDashes } from '../../animations/HorizontalDashes';
import { VerticalDashes } from '../../animations/VerticalDashes';
import { Lightbox } from '../../Lightbox';
import RequestDemoModal from '../../RequestDemoModal';
import { Button } from '../../ui/Button';
import { FeatureSection } from '../landing/FeatureSection';

export function CustomModels() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  const [showVideo, setShowVideo] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  function closeVideo() {
    videoRef.current?.pause();
    setShowVideo(false);
  }

  function playVideo() {
    setShowVideo(true);
  }

  return (
    <FeatureSection
      size="md"
      className="!pt-40"
      title="Build custom AI models for content moderation in under 10 minutes."
      description="Take your plain English content policies and turn them into AI models. Train models by teaching them the nuances of your policies, all without writing any code."
      cta={
        <div className="flex flex-col items-start justify-start gap-4">
          <Button onClick={showRequestDemoModal}>
            Start training Custom AI Models
          </Button>
          <Button className="px-0" onClick={playVideo} variant="link">
            Watch the full demo
            <ChevronRightFilled width={14} height={14} />
          </Button>
          {typeof document !== 'undefined' &&
            showVideo &&
            createPortal(
              <Lightbox isOpen={showVideo} onClose={closeVideo}>
                <video
                  ref={videoRef}
                  src="/videos/ai-demo.mp4"
                  controls
                  autoPlay
                />
              </Lightbox>,
              document.body,
            )}
        </div>
      }
    >
      <m.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 'all' }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="h-min relative before:inset-0 before:absolute before:bg-[linear-gradient(theme('backgroundColor.DEFAULT')_0%,transparent_5%,transparent_95%,theme('backgroundColor.DEFAULT')_100%)] rounded-md"
      >
        <VerticalDashes
          height="200%"
          className="hidden lg:block mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-1/4 left-0"
        />
        <VerticalDashes
          height="140%"
          className="block lg:hidden mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-[15%] left-0"
        />
        <VerticalDashes
          height="200%"
          className="hidden lg:block mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-1/4 right-0"
        />
        <VerticalDashes
          height="140%"
          className="block lg:hidden mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-[15%] right-0"
        />
        <HorizontalDashes
          width="136%"
          className="mask-[linear-gradient(90deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-x-[15%] top-0"
        />
        <HorizontalDashes
          width="136%"
          className="mask-[linear-gradient(90deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-x-[15%] bottom-0"
        />
        <video
          className="flex w-full max-w-5xl rounded shadow xl:max-w-3xl"
          src="/videos/ai-gif.mp4"
          autoPlay
          loop
          muted
          playsInline
        />
      </m.div>
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="CUSTOM_MODELS"
      />
    </FeatureSection>
  );
}

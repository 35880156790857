import { type Author } from './blog_posts/types';

const Authors = {
  mason: {
    name: 'Mason Silber',
    twitter: 'silber',
    linkedin: 'masonsilber',
    image: '/graphics/mason.png',
  },
  michael: {
    name: 'Michael Dworsky',
    twitter: 'michael_dworsky',
    linkedin: 'michaeldworsky',
    image: '/graphics/michael.png',
  },
  nishtha: {
    name: 'Nishtha Dalal',
    twitter: undefined,
    linkedin: 'http://www.linkedin.com/in/nishthadalal',
    image: '/graphics/nishtha-headshot.png',
  },
} satisfies { [key: string]: Author };

export default Authors;

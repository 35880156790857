import { m, type Variants } from 'framer-motion';

const imageVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 1.5,
      stiffness: 100,
      damping: 30,
      delay: 2,
    },
  },
};

export function WeaponNotice() {
  return (
    <div className="w-1/3 absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2">
      <m.img
        src="/graphics/weapon-notice.svg"
        variants={imageVariants}
        initial="hidden"
        animate="visible"
      />
    </div>
  );
}

/* eslint-disable react/jsx-key */
import { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import { useGQLUserAndOrgQuery } from '../graphql/generated';
import { RequireAuth, RequireLoggedOut } from '../routing/auth';
import { FeatureFlagClient } from '../utils/featureFlagsClient';
import AwaitingApproval from './auth/AwaitingApproval';
import ForgotPassword from './auth/forgot_password/ForgotPassword';
import ResetPassword from './auth/forgot_password/ResetPassword';
import Login from './auth/Login';
import LoginSSO from './auth/LoginSSO';
import RejectedByAdmin from './auth/RejectedByAdmin';
import SignUp from './auth/SignUp';
import { DashboardRoutes } from './dashboard/Dashboard';
import Demo from './dashboard/models/demo/Demo';
import { ModelTrainingRoutes } from './dashboard/models/training/TrainingPipeline';
import ExternalUrlRedirect from './ExternalUrlRedirect';
import HomeContainer from './home/HomeContainer';
import About from './home/pages/About';
import AI from './home/pages/AI';
import Blog from './home/pages/Blog';
import BlogPost from './home/pages/BlogPost';
import Compliance from './home/pages/Compliance';
import Enforcement from './home/pages/Enforcement';
import Home from './home/pages/Home';
import ModerationConsole from './home/pages/ModerationConsole';
import InternalModelTraining from './internal/InternalModelTraining';
import SampleViewer from './internal/labeling/ExamineSample';
import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsOfUse from './legal/TermsOfUse';

import '@/styles/antd.css';

import InternalItemLabeling from './internal/labeling/InternalItemLabeling';
import InternalLabeling from './internal/labeling/InternalLabeling';

/**
 * This is the container for the React app. All React
 * components that render an entire webpage should be
 * listed here, along with their URL paths.
 *
 * Important: For any webpage that should only be visible to authenticated
 * users, they should be wrapped in a <RequireAuth /> instead of
 * a normal <Route />.
 *
 * Similarly, if any screens should only be visible to logged out users,
 * they should be wrapped in a <RequireLoggedOut /> tag.
 */

export default function App() {
  const { data } = useGQLUserAndOrgQuery();
  const user = data?.me;
  useEffect(() => {
    // TODO: reset some of these identities if the user logs out?
    // (Would using `!loading && user == null`.)
    if (user != null) {
      FeatureFlagClient.updateUser({ key: user.id, email: user.email });
      // Nb: can add other user attributes here too for filtering in logrocket
      // dashboard, like org. But these are stored at the user level, so this
      // shouldn't include temporary/session-specific things like A/B test group.
      window.LogRocket?.identify(user.id, { email: user.email });
    }
  }, [user]);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomeContainer />,
      children: [
        { path: '', element: <Home /> },
        { path: 'terms', element: <TermsOfUse /> },
        { path: 'privacy', element: <PrivacyPolicy /> },
        { path: 'about', element: <About /> },
        { path: 'ai', element: <AI /> },
        { path: 'compliance', element: <Compliance /> },
        { path: 'enforcement', element: <Enforcement /> },
        { path: 'console', element: <ModerationConsole /> },
        { path: 'blog', element: <Blog /> },
        { path: 'blog/:slug', element: <BlogPost /> },
      ],
    },
    {
      path: 'careers',
      element: (
        <ExternalUrlRedirect to="https://protego.notion.site/Cove-Careers-cf8b15809fed4160a09e54feb5f1c929" />
      ),
    },
    {
      path: 'docs',
      element: <ExternalUrlRedirect to="https://docs.getcove.com/" />,
    },
    {
      path: 'login',
      element: (
        <RequireLoggedOut>
          <Login />
        </RequireLoggedOut>
      ),
    },
    {
      path: 'login/saml/callback',
      // This endpoint should only be accessed with POST requests during SAML
      // authentication. So if a user tries navigating to it in the browser, we
      // just redirect them back to the getcove.com landing page.
      element: <Navigate replace to="../../.." />,
    },
    {
      path: 'login/sso',
      element: (
        <RequireLoggedOut>
          <LoginSSO />
        </RequireLoggedOut>
      ),
    },
    {
      path: 'forgot_password',
      element: (
        <RequireLoggedOut>
          <ForgotPassword />
        </RequireLoggedOut>
      ),
    },
    {
      path: 'reset_password/:token?',
      element: (
        <RequireLoggedOut>
          <ResetPassword />
        </RequireLoggedOut>
      ),
    },
    {
      path: 'signup/:token?',
      element: (
        <RequireLoggedOut>
          <SignUp />
        </RequireLoggedOut>
      ),
    },
    {
      path: 'awaiting_approval',
      element: (
        <RequireAuth>
          <AwaitingApproval />
        </RequireAuth>
      ),
    },
    {
      path: 'rejected',
      element: (
        <RequireAuth>
          <RejectedByAdmin />
        </RequireAuth>
      ),
    },
    {
      path: 'demo',
      element: (
        <RequireAuth>
          <Demo />
        </RequireAuth>
      ),
    },
    {
      path: 'rules',
      element: <Navigate replace to="../dashboard/rules" />,
    },
    {
      path: 'actions',
      element: <Navigate replace to="../dashboard/actions" />,
    },
    {
      path: 'items_types',
      element: <Navigate replace to="../dashboard/item_types" />,
    },
    {
      path: 'content_types',
      element: <Navigate replace to="../dashboard/item_types" />,
    },
    {
      path: 'settings',
      element: <Navigate replace to="../dashboard/settings" />,
    },
    {
      path: 'internal/training',
      element: (
        <RequireAuth>
          <InternalModelTraining />
        </RequireAuth>
      ),
    },
    {
      path: 'internal/labeling_items',
      element: (
        <RequireAuth>
          <InternalItemLabeling />
        </RequireAuth>
      ),
    },
    {
      path: 'internal/labeling',
      element: (
        <RequireAuth>
          <InternalLabeling />
        </RequireAuth>
      ),
    },
    {
      path: 'internal/examine_sample_data',
      element: (
        <RequireAuth>
          <SampleViewer />
        </RequireAuth>
      ),
    },
    DashboardRoutes,
    ModelTrainingRoutes(data?.myOrg?.id),
  ]);

  return (
    <div className="bg-[#F9F9F9] flex flex-col w-full h-full bottom-0 relative">
      <RouterProvider router={router} />
    </div>
  );
}

import type { GQLCreateOrgResponse } from '@/graphql/generated';
import { Input } from 'antd';
import { useState } from 'react';

import CoveModal from '../dashboard/components/CoveModal';

/**
 * Component in the Sign Up flow that allows users to create
 * a new Org (if they are the first user from their Org to create
 * an account).
 */
export default function NewOrgForm(props: {
  confirmLoading: boolean;
  onSubmit: (org: { name: string; email: string; website: string }) => void;
  onCancel: () => void;
  visible: boolean;
  error: GQLCreateOrgResponse['__typename'] | undefined;
}) {
  const { confirmLoading, onSubmit, onCancel, visible, error } = props;
  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [website, setWebsite] = useState<string | undefined>(undefined);

  const infoIsValid = name?.length && email?.length && website?.length;

  return (
    <CoveModal
      title="Add Your Organization"
      visible={visible}
      onClose={onCancel}
      footer={[
        {
          title: 'Cancel',
          type: 'secondary',
          onClick: onCancel,
        },
        {
          title: 'Create',
          type: 'primary',
          loading: confirmLoading,
          disabled: !infoIsValid,
          onClick: () => {
            if (infoIsValid) {
              onSubmit({ name, email, website });
            }
          },
        },
      ]}
    >
      <div className="flex flex-col w-full gap-6">
        <div className="flex flex-col items-start gap-1">
          <div className="font-semibold">Organization Name</div>
          <Input onChange={(e) => setName(e.target.value)} />
          {error === 'OrgWithNameExistsError' ? (
            <div className="text-cove-alert-red">
              This is already an existing Organization with this name.
            </div>
          ) : null}
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="font-semibold">Primary Email Contact</div>
          <Input onChange={(e) => setEmail(e.target.value)} />
          {error === 'OrgWithEmailExistsError' ? (
            <div className="text-cove-alert-red">
              This email address is associated with an existing Organization.
            </div>
          ) : null}
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="font-semibold">Website URL</div>
          <Input onChange={(e) => setWebsite(e.target.value)} />
        </div>
      </div>
    </CoveModal>
  );
}

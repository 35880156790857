import type { HTMLInputTypeAttribute } from 'react';

export default function CoveInput(props: {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { placeholder, onChange } = props;
  return (
    <input
      className="py-2 px-3 block w-full border-solid border-gray-200 ring-gray-200 rounded text-base placeholder:text-gray-500 focus:border-primary focus:ring-primary/20 focus:ring-2 disabled:opacity-50 disabled:pointer-events-none"
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

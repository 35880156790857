import { useEffect, useState } from 'react';

import { Card } from '../components/Card';
import { Typography } from '../components/ui/Typography';

import { cn } from '../lib/utils';

interface SignalCardProps {
  name: string;
  partner: string;
  active?: boolean;
  index: number;
}

function SignalCard({ active, index, name, partner }: SignalCardProps) {
  const [internalActive, setInternalActive] = useState(false);

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => {
      setInternalActive(active ?? false);
    }, 2200 * index);

    return () => clearTimeout(timeout);
  }, [active]);

  return (
    <Card
      className={cn(
        'gap-2 transition-all duration-500 min-w-64 flex flex-shrink-0 flex-row',
        {
          'border-primary': internalActive,
        },
      )}
    >
      <div className="flex-grow">
        <Typography variant="title" size="xs">
          {name}
        </Typography>
        <Typography variant="body" size="sm">
          {partner}
        </Typography>
      </div>

      <img
        alt={`Logo: ${partner}`}
        src={`/graphics/${partner.replace(/\s+/, '-').toLowerCase()}.png`}
        className="w-8 h-8"
      />
    </Card>
  );
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SignalCard = Omit<SignalCardProps, 'index'>;

interface SignalCarouselProps {
  cards: SignalCard[];
  direction?: 'left' | 'right';
  index?: number;
}

export function SignalCarousel({
  cards,
  direction = 'left',
  index = 0,
}: SignalCarouselProps) {
  return (
    <div className="overflow-hidden [mask-image:linear-gradient(90deg,transparent_0%,#fff_10%,#fff_90%,transparent_100%)]">
      <div
        className={cn(
          'inline-flex w-max relative flex-row gap-4  [animation-duration:120s]',
          { 'animate-carousel-left': direction === 'left' },
          { 'animate-carousel-right': direction === 'right' },
        )}
      >
        {cards.map((card) => (
          <SignalCard index={index} key={card.name} {...card} />
        ))}

        <div className="absolute flex flex-row gap-4 translate-x-full -right-4 top-0">
          {cards.map((card) => (
            <SignalCard index={index} key={card.name} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
}

import { truncateAndFormatLargeNumber } from '@/utils/number';
import {
  BarChartOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Tooltip as AntTooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import ComponentLoading from '../../../../../components/common/ComponentLoading';
import CoveButton from '@/webpages/dashboard/components/CoveButton';

import {
  GQLActionData,
  GQLActionStatisticsFilters,
  GQLActionStatisticsGroupByColumns,
  useGQLActionStatisticsDataLazyQuery,
  useGQLManualReviewDecisionInsightsOrgInfoQuery,
} from '../../../../../graphql/generated';
import { safePick } from '../../../../../utils/misc';
import {
  getDateRange,
  getEarliestDateWithLookback,
  LookbackLength,
} from '../../../../../utils/time';
import { chartColors, PRIMARY_COLOR } from './chartColors';
import RuleInsightsFilterBy from './RuleInsightsFilterBy';
import RuleDashboardInsightsGroupBy, {
  getDisplayNameForGroupByOption,
  RuleDashboardInsightsGroupByColumns,
} from './RuleInsightsGroupBy';
import { ChartType, TimeWindow } from './RulesDashboardInsights';

export type RuleInsightsChartMetric = 'ACTIONS';

export function getEmptyFilterState(
  lookback: LookbackLength,
): GQLActionStatisticsFilters {
  return {
    actionIds: [],
    policyIds: [],
    sources: [],
    itemTypeIds: [],
    startDate: getEarliestDateWithLookback(lookback),
    endDate: new Date(),
  };
}

export type TimeDivisionOptions = 'HOUR' | 'DAY';

export default function RuleDashboardInsightsChart(props: {
  lookback: LookbackLength;
  timeWindow: TimeWindow;
  initialChartType: ChartType;
  initialGroupBy: RuleDashboardInsightsGroupByColumns | undefined;
  title?: string;
  isCustomTitle?: boolean;
  initialTimeDivision?: TimeDivisionOptions;
  initialFilterBy?: Partial<GQLActionStatisticsFilters>;
  hideGroupBy?: boolean;
  hideFilterBy?: boolean;
  hideTotal?: boolean;
  hideChartSelection?: boolean;
  hideBorder?: boolean;
  hideOptions?: boolean;
  infoText?: string;
  narrowMode?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onSelectGroupBy?: (
    groupBy: RuleDashboardInsightsGroupByColumns | undefined,
  ) => void;
  onUpdateFilterBy?: (filterBy: GQLActionStatisticsFilters) => void;
  onSelectTimeDivision?: (timeDivision: TimeDivisionOptions) => void;
}) {
  const {
    lookback,
    timeWindow,
    initialChartType,
    initialGroupBy,
    title,
    isCustomTitle = false,
    initialTimeDivision = 'DAY',
    initialFilterBy,
    hideGroupBy = false,
    hideFilterBy = false,
    hideTotal = false,
    hideChartSelection = false,
    hideBorder = false,
    hideOptions = false,
    infoText,
    narrowMode = false,
    onEdit,
    onDelete,
    onSelectGroupBy,
    onUpdateFilterBy,
    onSelectTimeDivision,
  } = props;

  const [selectedGroupBy, setSelectedGroupBy] = useState<
    RuleDashboardInsightsGroupByColumns | undefined
  >(initialGroupBy);
  const [chartType, setChartType] = useState(initialChartType);
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);
  const [timeDivision, setTimeDivision] =
    useState<TimeDivisionOptions>(initialTimeDivision);

  const [savedFilterBys, setSavedFilterBys] =
    useState<GQLActionStatisticsFilters>({
      ...getEmptyFilterState(lookback),
      ...(initialFilterBy ? initialFilterBy : {}),
    });

  const [optionsVisible, setOptionsVisible] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);

  const [
    getActionStats,
    {
      loading: actionStatsLoading,
      error: actionStatsError,
      data: actionStatsData,
    },
  ] = useGQLActionStatisticsDataLazyQuery();

  const [countsByDay, loading, error] = [
    actionStatsData?.actionStatistics,
    actionStatsLoading,
    actionStatsError,
  ];

  useEffect(() => {
    getActionStats({
      variables: {
        input: {
          timeDivision,
          groupBy: selectedGroupBy ? selectedGroupBy : 'ACTION_ID',
          filterBy: {
            sources: [],
            actionIds: [],
            itemTypeIds: [],
            policyIds: [],
            endDate: timeWindow.end,
            startDate: timeWindow.start,
          },
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
  }, [
    getActionStats,
    selectedGroupBy,
    timeWindow.end,
    timeWindow.start,
    timeDivision,
  ]);

  const { data: orgQueryData } =
    useGQLManualReviewDecisionInsightsOrgInfoQuery();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        if (optionsVisible) {
          setOptionsVisible(false);
        }
      }
    };

    if (optionsVisible) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [optionsVisible]);

  const getLineNameFromCount = (count: GQLActionData) => {
    if (!selectedGroupBy) {
      return 'All Actions';
    }
    return (() => {
      switch (selectedGroupBy) {
        case GQLActionStatisticsGroupByColumns.ActionSource:
          if (!count.source) {
            return 'Unknown';
          } else {
            switch (count.source) {
              case 'automated-rule':
                return 'Automated Rule';
              case 'mrt-decision':
                return 'Moderator Decision';
              case 'manual-action-run':
                return 'Manual Action Run';
              case 'post-actions':
                return 'Actions Endpoint';
              default:
                return 'Unknown';
            }
          }
        case GQLActionStatisticsGroupByColumns.ActionId:
          if (!count.action_id) {
            return 'Other';
          }
          const action = orgQueryData?.myOrg?.actions.find(
            (it) => it.id === count.action_id,
          );
          return action ? `${action.name}` : 'Other';
        case GQLActionStatisticsGroupByColumns.PolicyId:
          if (!count.policy_id) {
            return 'None';
          }
          return (
            orgQueryData?.myOrg?.policies.find(
              (it) => it.id === count.policy_id,
            )?.name ?? 'Other'
          );
        case GQLActionStatisticsGroupByColumns.ItemTypeId:
          if (!count.item_type_id) {
            return 'Other';
          }
          const itemType = orgQueryData?.myOrg?.itemTypes.find(
            (it) => it.id === count.action_id,
          );
          return itemType ? `${itemType.name}` : 'Other';
      }
    })();
  };

  const formattedData = countsByDay?.map((it) => {
    const obj: { [key: string]: any } = {
      ds: moment(new Date(parseInt(it.time)))
        .local()
        .format(`YYYY-MM-DD${timeDivision === 'HOUR' ? ' HH:mm' : ''}`),
    };
    obj[getLineNameFromCount(it)] = it.count;
    return obj;
  });

  // get all timestamps in the range that the chart will display,
  // by the selected time division / granularity
  const allDatesArray = getDateRange(
    timeWindow.start,
    timeWindow.end,
    timeDivision,
  );

  // Add the complete set of dates to our data array, so the resulting
  // charts do not having missing x axis values
  const formattedDataWithAllDates = [
    ...(formattedData ? formattedData : []),
    ...allDatesArray,
  ];

  const groupedData = formattedDataWithAllDates.reduce((result, item) => {
    const ds = item.ds;

    if (!(ds in result)) {
      result[ds] = { ds };
    }

    // Merge the inner object into the result object
    Object.assign(result[ds], item);

    return result;
  }, {});

  const sortedChartData = useMemo(
    () => (groupedData ? _.sortBy(Object.values(groupedData), 'ds') : []),
    [groupedData],
  );

  const uniqueLines = _.without(
    _.union(_.flatten(_.map(sortedChartData, (e) => _.keys(e)))),
    'ds',
  );

  const finalChartData = sortedChartData.map((it) => {
    const obj: { [key: string]: any } = {
      ds: it.ds,
    };
    uniqueLines.forEach((line) => {
      obj[line] = it[line] ?? 0;
    });
    return obj;
  });

  const sumNums = (a: number, b: number) => a + b;

  const chartDataSums = useMemo(
    () =>
      finalChartData?.reduce(
        (prev, curr) => _.mergeWith(prev, _.omit(curr, 'ds'), sumNums),
        _.omit(finalChartData[0], 'ds'),
      ) ?? [],
    [finalChartData],
  );

  const renderLegend = useCallback(
    (props: { payload?: Payload[] | undefined }) => {
      const entries = props.payload?.filter((entry) => entry.type !== 'none');
      return (
        <div className="flex flex-wrap gap-1 p-1 overflow-auto border border-solid rounded max-h-24 border-slate-200">
          {entries?.map((entry, index) => (
            <div
              key={index}
              className={`flex font-semibold cursor-pointer text-zinc-500 hover:opacity-70 items-center gap-1.5 text-start ${
                hiddenLines.includes(entry.value)
                  ? 'opacity-30 hover:opacity-50'
                  : ''
              }`}
              onClick={() => {
                if (hiddenLines.includes(entry.value)) {
                  setHiddenLines(
                    hiddenLines.filter((it) => it !== entry.value),
                  );
                } else {
                  setHiddenLines([...hiddenLines, entry.value]);
                }
              }}
            >
              <div
                style={{
                  backgroundColor:
                    entries?.length === 1
                      ? PRIMARY_COLOR
                      : chartColors[index % chartColors.length],
                }}
                className={`flex rounded-full h-4 w-4`}
              />
              {entry.value}
            </div>
          ))}
        </div>
      );
    },
    [hiddenLines],
  );

  if (error) {
    return <div className="">Error fetching metrics for chart</div>;
  }

  const renderCustomXAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { value: string };
  }) => {
    return (
      <text x={x - 4} y={y + 16} fill="#71717a" className="pt-3 text-zinc-500">
        {payload.value.slice(5)}
      </text>
    );
  };

  const renderCustomYAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { value: string };
  }) => (
    <text
      textAnchor="end"
      x={x}
      y={y + 4}
      fill="#71717a"
      className="pr-3 text-zinc-500"
    >
      {truncateAndFormatLargeNumber(Number(payload.value))}
    </text>
  );

  const customTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      const data = _.orderBy(
        payload
          .filter((it) => it.type !== 'none')
          .map((it) => safePick(it, ['name', 'value'])),
        'value',
        'desc',
      );
      return (
        <div className="flex flex-col max-w-sm overflow-x-scroll bg-white rounded-lg shadow text-start">
          <div className="p-3 text-white rounded-tl-lg rounded-tr-lg bg-indigo-400">
            {label}
          </div>
          <table className="w-full m-2">
            <tbody>
              {data.map((it, i) =>
                it.value && it.value > 0 ? (
                  <tr key={i}>
                    <td className="pr-1 font-semibold text-indigo-400 text-end">
                      {it.value?.toLocaleString()}
                    </td>
                    <td className="pl-1 font-medium text-slate-700">
                      {it.name}
                    </td>
                  </tr>
                ) : null,
              )}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  const onSetSelectedGroupBy = (
    option: RuleDashboardInsightsGroupByColumns | undefined,
  ) => {
    setSelectedGroupBy(option);
    if (onSelectGroupBy) {
      onSelectGroupBy(option);
    }
    getActionStats({
      variables: {
        input: {
          groupBy: option ? option : 'ACTION_ID',
          filterBy: {
            actionIds: [],
            itemTypeIds: [],
            sources: [],
            policyIds: [],
            startDate: timeWindow.start,
            endDate: timeWindow.end,
          },
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          timeDivision: 'DAY',
        },
      },
    });
  };

  const onSaveFilterBys = (filterBys: GQLActionStatisticsFilters) => {
    setSavedFilterBys(filterBys);
    if (onUpdateFilterBy) {
      onUpdateFilterBy(filterBys);
    }
    getActionStats({
      variables: {
        input: {
          timeDivision,
          groupBy: selectedGroupBy ? selectedGroupBy : 'ACTION_ID',
          filterBy: {
            ...filterBys,
            endDate: timeWindow.end,
            startDate: timeWindow.start,
          },
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
  };

  const emptyChart = (
    <div className="flex flex-col items-center justify-center gap-3 p-6 rounded bg-slate-100">
      <div className="text-xl">
        We didn't find any results for this query. Try another one!
      </div>
      <CoveButton
        title="Reset Filters"
        onClick={() => onSaveFilterBys(getEmptyFilterState(lookback))}
        size="small"
      />
    </div>
  );

  const lineChart = uniqueLines.map((name, index) => {
    return (
      <Line
        hide={hiddenLines.includes(name)}
        key={index}
        connectNulls
        name={name}
        type="monotone"
        dataKey={name}
        stroke={
          uniqueLines.length === 1
            ? PRIMARY_COLOR
            : chartColors[index % chartColors.length]
        }
        dot={false}
      />
    );
  });

  const barChart = uniqueLines.map((name, index) => (
    <Bar
      hide={hiddenLines.includes(name)}
      key={index}
      name={name}
      stackId="a"
      type="monotone"
      dataKey={name}
      fill={
        uniqueLines.length === 1
          ? PRIMARY_COLOR
          : chartColors[index % chartColors.length]
      }
    />
  ));

  const pieChart = (
    <PieChart width={400} height={400}>
      <Pie
        dataKey="value"
        nameKey="name"
        isAnimationActive={false}
        data={Object.entries(chartDataSums).map(([key, value]) => ({
          name: key,
          value,
        }))}
        cx="50%"
        cy="50%"
        outerRadius={80}
        fill={PRIMARY_COLOR}
        label
      >
        {Object.entries(chartDataSums).map((_, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              chartDataSums.length === 1
                ? PRIMARY_COLOR
                : chartColors[index % chartColors.length]
            }
          />
        ))}
      </Pie>
      <Legend content={(props) => renderLegend(props)} />
      <Tooltip content={customTooltip} />
    </PieChart>
  );

  const chartTypeButton = (
    type: ChartType,
    icon: React.ReactNode,
    extraStyle?: string,
  ) => {
    return (
      <div
        key={type}
        className={`flex font-bold border border-solid cursor-pointer h-fit px-2 py-1.5 ${
          chartType === type
            ? 'border-primary bg-primary text-white'
            : 'border-slate-200 text-slate-300 hover:bg-indigo-100 hover:text-white'
        } ${extraStyle}`}
        onClick={() => {
          if (chartType !== type) {
            setChartType(type);
          }
        }}
      >
        {icon}
      </div>
    );
  };

  const chartSelection = (
    <div className="flex items-center">
      {chartTypeButton(
        ChartType.LINE,
        <LineChartOutlined />,
        'rounded-l-full border-r-0',
      )}
      {chartTypeButton(ChartType.BAR, <BarChartOutlined />, 'border-r-0')}
      {chartTypeButton(ChartType.PIE, <PieChartOutlined />, 'rounded-r-full')}
    </div>
  );

  const timeDivisionButton = (
    option: TimeDivisionOptions,
    extraStyle?: string,
  ) => {
    return (
      <div
        key={option}
        className={`flex font-medium px-3 border border-solid cursor-pointer h-fit py-0.5 ${
          timeDivision === option
            ? 'border-primary bg-primary text-white'
            : 'border-slate-200 text-slate-400 hover:bg-indigo-100'
        } ${extraStyle}`}
        onClick={() => {
          if (timeDivision !== option) {
            setTimeDivision(option);
          }
          if (onSelectTimeDivision) {
            onSelectTimeDivision(option);
          }
        }}
      >
        {option === 'DAY' ? 'Daily' : 'Hourly'}
      </div>
    );
  };

  const timeDivisionSelection = (
    <div className="flex items-center">
      {timeDivisionButton('HOUR', 'rounded-l-full border-r-0')}
      {timeDivisionButton('DAY', 'rounded-r-full')}
    </div>
  );

  const optionButton = (
    optionTitle: string,
    icon: ReactNode,
    onClick?: () => void,
  ) => (
    <div
      className="flex gap-2 items-center px-2 py-0.5 m-1 text-start rounded cursor-pointer text-slate-500 font-medium bg-white hover:bg-cove-lightblue-hover"
      onClick={() => {
        if (onClick) {
          onClick();
        }
        setOptionsVisible(false);
      }}
    >
      {icon}
      {optionTitle}
    </div>
  );

  const optionsMenu = (
    <div
      className={`relative inline-block self-center pl-2 ${
        narrowMode ? 'self-center xl:self-start' : 'self-center'
      }`}
    >
      <div
        className={`${
          optionsVisible ? 'bg-slate-100' : ''
        } hover:bg-slate-100 text-slate-500 px-1 cursor-pointer rounded w-fit`}
        onClick={() => setOptionsVisible((prev) => !prev)}
      >
        <EllipsisOutlined className="flex text-2xl" />
      </div>
      {optionsVisible ? (
        <div
          ref={optionsRef}
          className="absolute right-0 z-30 mt-2 bg-white border border-solid rounded-md shadow-lg border-slate-200"
        >
          {onEdit ? optionButton('Edit', <EditOutlined />, onEdit) : null}
          <CSVLink
            id="CSVLink"
            data={finalChartData}
            filename={`${title} (${timeWindow.start.toLocaleString()} - ${timeWindow.end.toLocaleString()})`}
            enclosingCharacter={`"`}
            target="_blank"
          >
            {optionButton('Download', <DownloadOutlined />)}
          </CSVLink>
          {onDelete
            ? optionButton('Delete', <DeleteOutlined />, onDelete)
            : null}
        </div>
      ) : null}
    </div>
  );

  return (
    <div
      className={`flex flex-col rounded-lg p-6 bg-white ${
        narrowMode ? 'flex flex-col justify-between grow' : 'w-full'
      } ${hideBorder ? '' : 'border border-solid border-slate-200'}`}
    >
      <div className="flex pb-6">
        <div
          className={`flex justify-between gap-2 grow ${
            narrowMode ? 'flex-row xl:flex-col' : 'flex-row'
          }`}
        >
          {title ? (
            <div className="flex flex-col text-start">
              <div className="pb-2 text-lg font-medium text-slate-500">
                {title}
                {!hideGroupBy &&
                selectedGroupBy &&
                // If we don't have this condition, and if the selectedGroupBy
                // equals 'ACTION_ID', then this graph title displays as
                // 'Actions by Action'. That's a pretty bad title, so we just
                // change it to 'Actions' in that case.
                selectedGroupBy !== 'ACTION_ID' &&
                !isCustomTitle
                  ? ` by ${getDisplayNameForGroupByOption(selectedGroupBy)}`
                  : null}
                {infoText ? (
                  <AntTooltip
                    title={infoText}
                    placement="topRight"
                    color="white"
                  >
                    <InfoCircleOutlined className="pl-2 w-fit h-fit text-slate-300" />
                  </AntTooltip>
                ) : null}
              </div>
              {hideTotal ? null : (
                <div className="text-3xl font-semibold text-slate-900">
                  {actionStatsError ? (
                    'Unknown'
                  ) : actionStatsLoading ? (
                    <ComponentLoading />
                  ) : actionStatsData ? (
                    _.sumBy(
                      actionStatsData.actionStatistics,
                      'count',
                    ).toLocaleString()
                  ) : undefined}
                </div>
              )}
            </div>
          ) : null}
          <div
            className={`flex flex-wrap gap-8 ${
              narrowMode ? 'justify-end xl:justify-start' : 'justify-end'
            }`}
          >
            {timeDivisionSelection}
            {hideGroupBy ? null : (
              <RuleDashboardInsightsGroupBy
                selectedGroupBy={selectedGroupBy}
                setSelectedGroupBy={onSetSelectedGroupBy}
              />
            )}
            {hideFilterBy ? null : (
              <RuleInsightsFilterBy
                savedFilterBys={savedFilterBys}
                setSavedFilterBys={onSaveFilterBys}
                emptyFilterState={getEmptyFilterState(lookback)}
              />
            )}
            {hideChartSelection ? null : chartSelection}
          </div>
        </div>
        {hideOptions ? null : optionsMenu}
      </div>
      <div className="z-10 flex flex-col w-full h-full min-h-[400px] pb-4">
        {!loading && finalChartData.length === 0 ? (
          emptyChart
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            {loading ? (
              <ComponentLoading />
            ) : chartType === ChartType.PIE ? (
              pieChart
            ) : (
              <ComposedChart data={finalChartData}>
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="ds"
                  tickLine={false}
                  tick={renderCustomXAxisTick}
                />
                <YAxis
                  tick={renderCustomYAxisTick}
                  tickLine={false}
                  stroke="#d4d4d8"
                  label={{
                    value: `Total Action Executions`,
                    style: { textAnchor: 'middle' },
                    angle: -90,
                    position: 'left',
                    offset: 0,
                  }}
                />
                <Legend content={renderLegend} />
                <Tooltip content={customTooltip} />
                {chartType === ChartType.LINE ? lineChart : barChart}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}

import { m } from 'framer-motion';

export function Performance() {
  return (
    <div className="flex flex-col gap-0 w-full max-w-3xl">
      <m.img
        className="w-full"
        initial={{ x: 10, opacity: 0 }}
        whileInView={{ x: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 1 }}
        src="/graphics/perf-1.png"
      />
      <m.img
        className="w-full"
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        src="/graphics/perf-2.png"
      />
    </div>
  );
}

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  FormOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import Link from 'antd/lib/typography/Link';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import FullScreenLoading from '../../components/common/FullScreenLoading';
import DashboardHeader from '../dashboard/components/DashboardHeader';
import FormSectionHeader from '../dashboard/components/FormSectionHeader';

import { GQLUserPermission, useGQLApiKeyQuery } from '../../graphql/generated';
import { userHasPermissions } from '../../routing/permissions';
import useWindowDimensions from '../../utils/dimension';

gql`
  query ApiKey {
    myOrg {
      apiKey
      publicSigningKey
    }
    me {
      permissions
    }
  }
`;

export default function ApiAuthenticationSettings() {
  // Lowest level permission required to view the API Authentication page
  const requiredPermissions = [GQLUserPermission.ManageOrg];

  const { data, loading, error } = useGQLApiKeyQuery();
  const [apiKeyVisible, setApiKeyVisible] = useState(false);
  const [copyTextTooltipTitle, setCopyTextTooltipTitle] =
    useState('Copy to clipboard');

  const navigate = useNavigate();
  const windowDimensions = useWindowDimensions();

  if (loading) {
    return <FullScreenLoading />;
  }
  if (error) {
    console.log(error);
    return <div />;
  }
  const permissions = data?.me?.permissions;
  if (!userHasPermissions(permissions, requiredPermissions)) {
    navigate('/settings');
  }

  const org = data?.myOrg;
  if (!org) {
    throw Error('Missing org');
  }
  const apiKey = org.apiKey;

  const hiddenApiKey = '\u2022'.repeat(
    40,
  ); /* dot character in hidden password */

  const publicSigningKey = org.publicSigningKey;

  const copyText = (text: string) => {
    // We can assume apiKey to be non-null because it's used
    // inside a component that's only rendered if apiKey is
    // non-null
    navigator.clipboard.writeText(text);
    setCopyTextTooltipTitle('Copied!');
  };

  const codeBlockCustomStyle = {
    marginTop: '0px',
    paddingTop: '16px',
    borderRadius: '8px 8px 8px 8px',
    borderWidth: '0px',
    fontSize: windowDimensions.width < 768 ? '10px' : '14px',
  };

  const apiKeySection = (
    <div className="flex flex-col justify-start">
      <FormSectionHeader
        title="API Key"
        subtitle="This is your API key. Every HTTP request you send to Cove must have this API key attached so we can authenticate the request. To attach it to a request, simply add the following parameter to your request headers:"
      />
      <div className="flex flex-row">
        <SyntaxHighlighter
          customStyle={codeBlockCustomStyle}
          language="javascript"
        >
          {`X-API-KEY: ${apiKeyVisible ? apiKey : hiddenApiKey}`}
        </SyntaxHighlighter>
        <Button
          className="flex items-center justify-center m-2"
          type="text"
          icon={
            apiKeyVisible ? (
              <EyeTwoTone className="text-sm" />
            ) : (
              <EyeInvisibleOutlined className="text-sm" />
            )
          }
          onClick={() => setApiKeyVisible(!apiKeyVisible)}
        />
        <Tooltip title={copyTextTooltipTitle}>
          <Button
            className="flex items-center justify-center m-2"
            type="text"
            icon={<FormOutlined className="text-sm" />}
            onClick={() => copyText(apiKey)}
          />
        </Tooltip>
      </div>
    </div>
  );

  const signingKeySection = (
    <div className="flex flex-col justify-start">
      <FormSectionHeader
        title="Webhook Signature Verification Key"
        subtitle={
          <div>
            This is your webhook signature verification key. We will include a
            signature in every HTTP request we send to you in case you'd like to
            verify that the request is valid and came from Cove. To learn how to
            verify requests with this secret, see our{' '}
            <Link href="https://docs.protegoapi.com/docs/authentication#optional-verifying-incoming-requests-from-cove">
              API Documentation
            </Link>
            .
          </div>
        }
      />
      {publicSigningKey && (
        <div className="flex items-center mt-2">
          <SyntaxHighlighter customStyle={codeBlockCustomStyle} language="bash">
            {publicSigningKey}
          </SyntaxHighlighter>
          <Tooltip title={copyTextTooltipTitle}>
            <Button
              className="flex items-center justify-center m-2"
              type="text"
              icon={<FormOutlined className="ml-2 text-sm" />}
              onClick={() => copyText(publicSigningKey)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col w-3/5 text-start">
      <Helmet>
        <title>API Authentication</title>
      </Helmet>
      <DashboardHeader title="API Authentication" />
      <div className="!mb-8 divider" />
      {apiKeySection}
      <div className="!my-8 divider" />
      {signingKeySection}
    </div>
  );
}

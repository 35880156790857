import { gql } from '@apollo/client';
import _ from 'lodash';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import StepProgressIndicator from '../../../../components/common/StepProgressIndicator';

import { RequireAuth } from '../../../../routing/auth';
import { ITEM_TYPE_FRAGMENT } from '../../rules/rule_form/RuleForm';
import TrainingPipelineLabelingCompleteScreen from './screens/labeling/TrainingPipelineLabelingCompleteScreen';
import TrainingPipelineLabelSamplesScreen from './screens/labeling/TrainingPipelineLabelSamplesScreen';
import TrainingPipelineSetThresholdsScreen from './screens/set_thresholds/TrainingPipelineSetThresholdsScreen';
import TrainingPipelineInputPolicyScreen from './screens/TrainingPipelineInputPolicyScreen';
import TrainingPipelineModelModalitySelection from './screens/TrainingPipelineModelModalitySelection';
import TrainingPipelinePolicyCompletedScreen from './screens/TrainingPipelinePolicyCompletedScreen';
import {
  screenOrder,
  TrainingPipelineStep,
  type GoToScreen,
  type TrainingPipelineStepType,
} from './screens/types';
import TrainingPipelineHeader from './TrainingPipelineHeader';

gql`
  ${ITEM_TYPE_FRAGMENT}
  query OrgDataForModelTraining {
    myOrg {
      id
      itemTypes {
        ... on ItemTypeBase {
          ...ItemTypeFragment
        }
        ... on UserItemType {
          isDefaultUserType
        }
      }
      actions {
        ... on ActionBase {
          id
          name
          itemTypes {
            ... on ItemTypeBase {
              id
            }
          }
        }
      }
      policies {
        id
        name
        policyText
        penalty
      }
    }
    me {
      permissions
    }
  }
`;

const getRouteForStep = (step: TrainingPipelineStepType) => {
  switch (step) {
    case TrainingPipelineStep.InputPolicy:
      return `input_policy`;
    case TrainingPipelineStep.LabelSamples:
      return `label_samples`;
    case TrainingPipelineStep.LabelingComplete:
      return `labeling_complete`;
    case TrainingPipelineStep.EvaluateModel:
      return `evaluate_model`;
    case TrainingPipelineStep.PolicyCompleted:
      return `policy_completed`;
    case TrainingPipelineStep.SelectModality:
      return `select_modality`;
    default:
      throw new Error(`Unknown route for step ${step}`);
  }
};

export const ModelTrainingRoutes = (orgId?: string) => ({
  path: 'training',
  element: (
    <RequireAuth>
      <TrainingPipeline />
    </RequireAuth>
  ),
  children: [
    /* Typically, we'd put something like the below object here so that
       /training would redirect to /dashboard/policies (because every screen in the
       training flow requires a policy ID to be passed in the URL, and that policy ID
       currently gets selected on the Policies screen, which lives at
       /dashboard/policies). However, the implementation of the TrainingPipeline
       component below throws an error if the currentStep is invalid (see how the
       currentStep is computed below). So before we can redirect to
       /dashboard/policies with the <Navigate /> component below, we hit the error when
       calculting the currentStep. So for now, I just added a check in the
       TrainingPipeline component for whether we're currently at /training, and if so,
       manually redirect to /dashboard/policies.
       {
         path: '',
         element: <Navigate replace to="../dashboard/policies" />,
       },
      */
    {
      path: getRouteForStep(TrainingPipelineStep.InputPolicy),
      element: (
        <TrainingPipelineInputPolicyScreen
          nextScreen={TrainingPipelineStep.LabelSamples}
        />
      ),
    },
    {
      path: getRouteForStep(TrainingPipelineStep.LabelSamples),
      element: (
        <TrainingPipelineLabelSamplesScreen
          nextScreen={
            orgId === 'd4b1c9eed55' || orgId === '815014e53e6'
              ? TrainingPipelineStep.EvaluateModel
              : TrainingPipelineStep.LabelingComplete
          }
          previousScreen={TrainingPipelineStep.SelectModality}
        />
      ),
    },
    {
      path: getRouteForStep(TrainingPipelineStep.LabelingComplete),
      element: <TrainingPipelineLabelingCompleteScreen />,
    },
    {
      path: getRouteForStep(TrainingPipelineStep.EvaluateModel),
      element: (
        <TrainingPipelineSetThresholdsScreen
          nextScreen={TrainingPipelineStep.PolicyCompleted}
          previousScreen={TrainingPipelineStep.LabelSamples}
        />
      ),
    },
    {
      path: getRouteForStep(TrainingPipelineStep.PolicyCompleted),
      element: <TrainingPipelinePolicyCompletedScreen />,
    },
    {
      path: getRouteForStep(TrainingPipelineStep.SelectModality),
      element: (
        <TrainingPipelineModelModalitySelection
          nextScreen={TrainingPipelineStep.LabelSamples}
        />
      ),
    },
  ],
});

export default function TrainingPipeline() {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === '/training') {
    return <Navigate replace to="/dashboard/policies" />;
  }

  const currentStep = (() => {
    const currentPath = location.pathname;
    if (currentPath.includes('select_modality')) {
      return TrainingPipelineStep.SelectModality;
    } else if (currentPath.includes('label_samples')) {
      return TrainingPipelineStep.LabelSamples;
    } else if (currentPath.includes('evaluate_model')) {
      return TrainingPipelineStep.EvaluateModel;
    } else if (currentPath.includes('labeling_complete')) {
      return TrainingPipelineStep.LabelingComplete;
    } else if (currentPath.includes('policy_completed')) {
      return TrainingPipelineStep.PolicyCompleted;
    }

    throw new Error(`Unknown step for path ${currentPath}`);
  })() satisfies TrainingPipelineStepType;

  const progressIndicator = (
    <StepProgressIndicator
      steps={screenOrder.map((step) => ({
        name: _.startCase(step),
        onClick: () => {},
      }))}
      currentStepIndex={screenOrder.indexOf(currentStep)}
      navigationMode="backwards-only"
    />
  );

  const goToScreen = (
    step: (typeof screenOrder)[number],
    policyId: string,
    sessionId?: string,
    jobId?: string,
  ) => {
    const queryParams = new URLSearchParams({ policyId });
    if (sessionId) {
      queryParams.append('sessionId', sessionId);
    }
    if (jobId) {
      queryParams.append('jobId', jobId);
    }
    navigate(`${getRouteForStep(step)}?${queryParams.toString()}`);
  };

  return (
    <div className="flex flex-col w-full h-screen">
      <TrainingPipelineHeader />
      {progressIndicator}
      <Outlet context={goToScreen satisfies GoToScreen} />
    </div>
  );
}

import {
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import ComponentLoading from '../../../../components/common/ComponentLoading';

import { DAY, HOUR, LookbackLength, MINUTE } from '../../../../utils/time';
import { TimeWindow } from '../../rules/dashboard/visualization/RulesDashboardInsights';

export default function ManualReviewDashboardInsightsCard(
  props: {
    title: string;
    lookback: LookbackLength;
    timeWindow: TimeWindow;
    icon: React.ReactNode;
    value: number | undefined;
    loading: boolean;
  } & ({ change: number | undefined } | { link: string; linkTitle: string }),
) {
  const { title, lookback, timeWindow, icon, value, loading } = props;

  const previousWindowLength = (() => {
    switch (lookback) {
      case LookbackLength.ONE_DAY:
        return 'day';
      case LookbackLength.THREE_DAYS:
        return '3 days';
      case LookbackLength.ONE_WEEK:
        return 'week';
      case LookbackLength.ONE_MONTH:
        return 'month';
      case LookbackLength.THREE_MONTHS:
        return '3 months';
      case LookbackLength.SIX_MONTHS:
        return '6 months';
      case LookbackLength.ONE_YEAR:
        return 'year';
      case LookbackLength.CUSTOM:
        const millisecondDifference =
          timeWindow.end.getTime() - timeWindow.start.getTime();
        const daysDifference = Math.trunc(millisecondDifference / DAY);
        if (daysDifference > 0) {
          return daysDifference === 1 ? 'day' : `${daysDifference} days`;
        }
        const hoursDifference = Math.trunc(millisecondDifference / HOUR);
        if (hoursDifference > 0) {
          return hoursDifference === 1 ? 'hour' : `${hoursDifference} hours`;
        }
        const minutesDifference = Math.trunc(millisecondDifference / MINUTE);
        return minutesDifference === 1
          ? 'minute'
          : `${minutesDifference} minutes`;
    }
  })();

  // If there are decimals present, round to the nearest 10th
  const formatNumber = (n: number) => {
    return (n % 1 === 0 ? n : n.toFixed(1)).toLocaleString();
  };

  return (
    <div className="flex justify-between bg-white p-4 border border-solid rounded border-slate-200 grow">
     <div className="flex flex-col text-start">
       <div className="pb-6 text-base font-medium text-slate-500">{title}</div>
       {loading ? (
         <div className="self-start">
           <ComponentLoading />
         </div>
       ) : value != null ? (
         <div className="flex flex-col pb-2 text-3xl font-semibold text-slate-900">
           {formatNumber(value)}
           {'change' in props && props.change !== undefined ? (
             <div className="flex items-center gap-2">
               <div
                 className={`${
                   props.change === 0
                     ? 'text-slate-600 bg-slate-100'
                     : props.change < 0
                     ? 'text-red-600 bg-red-100'
                     : 'text-green-600 bg-green-100'
                 } p-1 rounded text-sm font-semibold flex items-center`}
               >
                 {props.change === 0 ? null : props.change < 0 ? (
                   <CaretDownOutlined />
                 ) : (
                   <CaretUpOutlined />
                 )}
                 {formatNumber(props.change)}%
               </div>
               <div className="text-sm font-medium text-slate-500">
                 vs. previous {previousWindowLength}
               </div>
             </div>
           ) : 'link' in props ? (
             <Link
               className="p-1 px-4 text-sm font-medium rounded cursor-pointer bg-cove-lightblue text-cove-blue hover:bg-cove-lightblue-hover w-fit"
               to={props.link}
             >
               {props.linkTitle}
               <ArrowRightOutlined className="pl-2" />
             </Link>
           ) : null}
         </div>
       ) : (
         <div className="text-cove-alert-red">Error finding value</div>
       )}
     </div>
     <div className="pl-2 rounded">{icon}</div>
    </div>
  );
}

import { Component, ErrorInfo, ReactNode } from 'react';

import ErrorComponent from './ErrorComponent';

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<
  {
    children: ReactNode;
    buttonLinkPath: string;
    buttonTitle: string;
  },
  State
> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const { buttonLinkPath, buttonTitle } = this.props;

      return (
        <ErrorComponent
          buttonLinkPath={buttonLinkPath}
          buttonTitle={buttonTitle}
          onButtonClick={() => {
            this.setState({ hasError: false });
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

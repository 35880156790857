import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

/**
 * The button in the Dashboard sidebar menu that lets
 * users navigate to various dashboard pages.
 */
export default function DashboardMenuButton(props: {
  selected: boolean;
  title: string;
  url: string;
  onClick: () => void;
  // Menu Items can be have sub items. This represents the level of the
  // item (top level = 0, sub items = 1, sub-sub items = 2, etc).
  level: number;
  icon?: React.ReactNode;
  collapsed: boolean;
  highlighted: boolean;
}) {
  const { selected, title, url, onClick, level, icon, collapsed, highlighted } =
    props;
  const button = (
    <Link
      to={url}
      className={`flex text-start items-center rounded-lg my-1 cursor-pointer hover:text-primary ${
        level === 0 ? 'hover:bg-primary/10' : ''
      } ${
        selected || highlighted
          ? `text-primary font-semibold ${level === 0 ? 'bg-primary/10' : ''}`
          : 'text-black font-medium'
      } ${collapsed ? 'w-fit p-2' : 'py-1 px-2'}`}
      onClick={onClick}
      style={{ marginLeft: level * 16 }}
    >
      {icon}
      {collapsed ? null : <div className="pl-3 whitespace-nowrap">{title}</div>}
    </Link>
  );
  return collapsed ? (
    <Tooltip title={title} placement="right">
      {button}
    </Tooltip>
  ) : (
    button
  );
}

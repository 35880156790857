export default function DemoPrimaryButton(props: {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}) {
  const { title, onClick, disabled, loading } = props;
  return (
    <div
      className={`flex items-center justify-center w-fit px-6 py-3 rounded text-lg font-semibold cursor-pointer ${
        disabled
          ? 'bg-slate-100 text-slate-300'
          : loading
          ? 'bg-cove-purple/70 text-white'
          : 'bg-cove-purple hover:bg-cove-purple/70 text-white'
      }`}
      onClick={() => {
        if (!disabled && !loading) {
          onClick();
        }
      }}
    >
     {title}
    </div>
  );
}

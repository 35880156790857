import { m } from 'framer-motion';

export function CompilianceToolkit() {
  return (
    <div className="flex flex-col gap-0 w-full max-w-3xl">
      <div className="w-full flex gap-6 ml-4 xl:ml-8 mb-6">
        <m.img
          className="h-9 lg:h-11 object-scale-down"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 0.25 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          src="/graphics/dsa-full.png"
        />
        <m.img
          className="h-9 lg:h-11 object-scale-down"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 0.25 }}
          transition={{ duration: 0.5, delay: 1 }}
          src="/graphics/osa.png"
        />
      </div>
      <m.img
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 1.5 }}
        src="/graphics/compliance-toolkit.png"
      />
    </div>
  );
}

export const chartColors = [
  '#10b981',
  '#f59e0b',
  '#14b8a6',
  '#f97316',
  '#06b6d4',
  '#8b5cf6',
  '#0ea5e9',
  '#3b82f6',
  '#2dd4bf',
  '#22d3ee',
  '#38bdf8',
  '#60a5fa',
  '#7675ff',
  '#a78bfa',
  '#f87171',
  '#fb923c',
  '#fbbf24',
  '#6ee7b7',
  '#5eead4',
  '#67e8f9',
  '#7dd3fc',
  '#93c5fd',
  '#9ca4ff',
  '#c4b5fd',
  '#fca5a5',
  '#fdba74',
  '#fcd34d',
];

export const PRIMARY_COLOR = '#7165FF';

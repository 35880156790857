import { m } from 'framer-motion';

import { cn } from '../../../lib/utils';
import { HorizontalDashes } from '../../animations/HorizontalDashes';
import { VerticalDashes } from '../../animations/VerticalDashes';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';
import { FeatureSection } from '../landing/FeatureSection';

interface PostingProps {
  title: string;
  location: string;
  url: string;
  index: number;
  centerOnMobile?: boolean;
}

function Posting({
  url,
  index,
  title,
  location,
  centerOnMobile = false,
}: PostingProps) {
  return (
    <m.div
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ amount: 0.5, once: true }}
      transition={{ delay: 0.5 * (index * 0.5 + 1), duration: 0.5 }}
      className={`flex flex-col sm:flex-row gap-4 sm:items-center justify-between ${
        centerOnMobile ? 'items-center' : 'items-start'
      }`}
    >
      <div>
        <Typography variant="title">{title}</Typography>
        <Typography variant="body" color="muted" size="lg">
          {location}
        </Typography>
      </div>
      <Button
        as="a"
        href={url}
        target="_blank"
        // rel="noopener noreferrer"
        size="md"
      >
        Apply now
      </Button>
    </m.div>
  );
}

export function Careers() {
  return (
    <FeatureSection
      id="careers"
      label="We're hiring!"
      centered
      gap={false}
      orientation="ttb"
      title="Join us on our mission"
    >
      <div className="w-full flex flex-col items-start sm:items-center">
        <div className="z-10 mt-10 flex flex-col gap-10 p-0 sm:p-7 w-full max-w-2xl lg:max-w-4xl xl:max-w-5xl">
          <Posting
            index={1}
            title="Sales Development Representative"
            location="New York, NY (Hybrid)"
            url="https://protego.notion.site/Sales-Development-Representative-c71bbbd1ffec43eea78edbdaaf3cd31e"
          />
        </div>

        <div className="w-full max-w-2xl">
          <m.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ amount: 0.5, once: true }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Typography
              variant="title"
              align="center"
              className="mx-auto px-0 sm:px-6 mt-44 mb-6 max-w-2xl"
            >
              Not seeing something that’s a fit? We’re always looking for great
              talent, submit a general application!
            </Typography>
          </m.div>

          <div className="@container-[size] relative mx-auto h-48 max-w-5xl">
            <div
              className={cn(
                'bg-graph-paper absolute -z-10 -left-[calc(6*var(--cell-width))] h-[calc(500%)] w-[calc(300%)]',
                '-top-[calc(11*var(--cell-height))] sm:-top-[calc(7*var(--cell-height))]',
                '[--cell-width:calc(100cqw/6)]',
                '[--cell-height:calc(100cqh/3)] sm:[--cell-height:calc(100cqh/2)]',
                'mask-ellipse mask-from-60% reach-closest-side',
              )}
              style={{ backgroundSize: 'var(--cell-width) var(--cell-height)' }}
            />
            <VerticalDashes className="mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -top-8 left-0" />
            <VerticalDashes className="mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -top-8 right-0" />
            <HorizontalDashes
              width={320}
              className="mask-[linear-gradient(90deg,transparent_0%,#000_50%,transparent_100%)] absolute left-0 top-0 -translate-x-1/2"
            />
            <HorizontalDashes
              width={320}
              className="mask-[linear-gradient(90deg,transparent_0%,#000_50%,transparent_100%)] absolute right-0 top-0 translate-x-1/2"
            />
            <div className="relative mx-auto max-w-5xl px-7 py-14">
              <Posting
                index={1}
                title="General application"
                location="New York, NY (Hybrid)"
                url="https://protego.notion.site/General-Application-9603c532bbe845b0822ed2f01f471453"
                centerOnMobile={true}
              />
            </div>
          </div>
        </div>
      </div>
    </FeatureSection>
  );
}

import { Checkbox, Label } from '@/cove-ui';
import { gql } from '@apollo/client';
import { Input } from 'antd';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';

import CoveButton from '../dashboard/components/CoveButton';
import CoveModal from '../dashboard/components/CoveModal';

import { namedOperations, useGQLLoginMutation } from '../../graphql/generated';
import LogoPurple from '../../images/LogoPurple.png';

gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ... on LoginSuccessResponse {
        user {
          id
          email
        }
      }
      ... on LoginUserDoesNotExistError {
        title
        status
      }
      ... on LoginIncorrectPasswordError {
        title
        status
      }
    }
  }
`;

/**
 * Login form component
 */
export default function Login() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [remember, setRemember] = useState(false);

  const navigate = useNavigate();

  const [login, { client, loading }] = useGQLLoginMutation({
    onError: (e) =>
      setErrorMessage(
        'We encountered an issue trying to process your request. Please try again.',
      ),
    onCompleted: (response) => {
      switch (response.login.__typename) {
        case 'LoginSuccessResponse':
          client.resetStore().then(() => navigate('/dashboard'));
          break;
        case 'LoginIncorrectPasswordError':
          setErrorMessage(
            'This password is incorrect for the email address above.',
          );
          break;
        default:
          setErrorMessage(
            'We encountered an issue trying to process your request. Please try again.',
          );
      }
    },
    refetchQueries: [namedOperations.Query.PermissionGatedRouteLoggedInUser],
  });

  const errorModal = (
    <CoveModal
      title="Something went wrong"
      visible={errorMessage != null}
      onClose={() => setErrorMessage(undefined)}
    >
      {errorMessage}
    </CoveModal>
  );

  const onLogin = async () => {
    login({
      variables: {
        input: {
          email: email!,
          password: password!,
          remember,
        },
      },
      refetchQueries: [namedOperations.Query.PermissionGatedRouteLoggedInUser],
    });
  };

  const emailInput = (
    <div className="flex flex-col mb-4">
      <div className="mb-1">Email</div>
      <Input
        className="w-full rounded-lg"
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
  );

  const passwordInput = (
    <div className="flex flex-col mb-4">
      <div className="mb-1">Password</div>
      <Input.Password
        className="w-full rounded-lg"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onLogin();
          }
        }}
      />
    </div>
  );

  const rememberMeCheckbox = (
    <div className="flex items-center space-x-2">
      <Checkbox
        id="remember-me"
        defaultChecked={true}
        onCheckedChange={setRemember}
      />
      <Label htmlFor="remember-me">Keep me signed in</Label>
    </div>
  );

  const forgotPasswordButton = (
    <Link
      className="flex justify-start font-semibold text-primary hover:text-primary/70"
      to={'/forgot_password'}
    >
      Forgot Password?
    </Link>
  );

  return (
    <div className="flex flex-col h-screen p-8 mb-0 bg-slate-100">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex flex-col items-stretch justify-center border-none sm:border sm:border-solid border-slate-200 rounded-xl shadow-none sm:shadow h-full w-full sm:h-[560px] sm:w-[460px] m-0 p-0 sm:m-9 sm:px-12">
          <Link to="/" className="flex items-center justify-center w-full my-2">
            <img src={LogoPurple} alt="Logo" width="55" height="70" />
          </Link>
          <div className="py-5 text-2xl font-bold">
            Sign in to your Cove account
          </div>
          {emailInput}
          {passwordInput}
          <div className="flex items-center justify-between mb-6">
            {rememberMeCheckbox}
            {forgotPasswordButton}
          </div>
          <CoveButton
            onClick={onLogin}
            title="Sign In"
            loading={loading}
            disabled={!email || !password}
          />
          {errorModal}
        </div>
      </div>
    </div>
  );
}

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import CoveButton from '@/webpages/dashboard/components/CoveButton';

export default function ErrorComponent(props: {
  buttonLinkPath: string;
  buttonTitle: string;
  onButtonClick?: () => void;
}) {
  const { buttonLinkPath, buttonTitle, onButtonClick } = props;

  return (
    <div className="flex items-start justify-center h-full w-full">
      <div className="flex flex-col items-center justify-center p-12 rounded-xl bg-slate-50 text-slate-500 shadow mt-20">
        <div className="text-slate-200 text-8xl pb-3">
          <ExclamationCircleOutlined />
        </div>
        <div className="text-2xl max-w-[400px] pb-2">Something Went Wrong</div>
        <div className="max-w-[400px] pt-2 px-4 pb-10">
          We're having trouble completing your request. Please go back and try
          again.
        </div>
        <Link to={buttonLinkPath} onClick={onButtonClick}>
          <CoveButton title={buttonTitle} />
        </Link>
      </div>
    </div>
  );
}

import { InputNumber } from 'antd';

export default function DemoInputDailyContentTotal(props: {
  setTotalContent: (total: number) => void;
  totalContent?: number;
}) {
  const { totalContent, setTotalContent } = props;
  return (
    <div className="flex flex-row items-center justify-start p-4 border border-solid rounded-md border-slate-200 w-fit bg-white mt-3">
      <InputNumber
        className="!h-auto !text-base !border-none !rounded-full !shadow-none !ring-0 !outline-none !cursor-text !active:shadow-none !focus:shadow-none !text-slate-500 !placeholder-slate-300"
        style={{
          width: (totalContent?.toString().length ?? 0 + 1) * 15,
          minWidth: '40px',
        }}
        placeholder="0"
        min={0}
        formatter={(value) => Number(value).toLocaleString()}
        controls={false}
        value={totalContent}
        onChange={(value) => setTotalContent(Number(value))}
      />
      <div className="text-slate-500">total pieces of content / day</div>
    </div>
  );
}

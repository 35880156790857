import { ChevronDown, ChevronUp } from '@/icons';
import { gql } from '@apollo/client';
import { Progress } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';

import CoveModal from '../../../components/CoveModal';

import { useGQLGetSamplesForLabelingQuery } from '../../../../../graphql/generated';
import HTMLRenderer from '../../../policies/HTMLRenderer';
import DemoFooter from '../DemoFooter';
import { defaultPolicyTexts } from './DemoInputPolicyScreen';

export type Sample =
  | string
  // Marketplace demo
  | {
      photos: string[];
      name: string;
      description: string;
      price: string;
      uploaded: string;
    }
  // Character demo
  | {
      creator: {
        id: string;
        name: string;
        typeId: string;
      };
      greeting: string;
      name: string;
      description: string;
      profilePhoto: string;
      tagline: string;
    };

export function SampleCard(props: {
  sample: Sample;
  backgroundColor?: string;
  isCollapsable?: boolean;
}) {
  const { sample, backgroundColor, isCollapsable = false } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const scrollToTop = () => {
    if (containerRef.current) {
      // Set the scrollTop property to 0 to scroll to the top
      containerRef.current.scrollTop = 0;
    }
  };
  useEffect(() => {
    scrollToTop();
  }, [sample]);
  const fieldValue = (name: string, value: string | React.ReactElement) => (
    <div>
      <div className="mb-1 text-sm font-semibold text-slate-500">{name}</div>
      <div className="text-base">{value}</div>
    </div>
  );
  const fullSample = (
    <div className="flex justify-between w-full">
      <div className="flex flex-col w-full gap-4">
        {typeof sample === 'string' ? (
          sample
        ) : 'price' in sample ? (
          // Marketplace listing
          <>
            {fieldValue('Name', sample.name)}
            {fieldValue('Description', sample.description)}
            {fieldValue('Price', sample.price)}
            {fieldValue(
              'Uploaded At',
              moment(sample.uploaded).format('MM/DD/YY hh:mm A'),
            )}
            {fieldValue(
              'Images',
              <div className="flex w-full gap-4 mt-2">
                {sample.photos.map((photo, i) => (
                  <div key={i} className="h-24">
                    <img
                      className="object-cover w-full h-full rounded-lg"
                      src={photo}
                      alt="product"
                    />
                  </div>
                ))}
              </div>,
            )}
          </>
        ) : (
          // Character
          <>
            <div className="flex items-center justify-start w-full gap-4 mb-4">
              <div className="w-24 h-24">
                <img
                  className="object-cover w-full h-full rounded-lg"
                  src={sample.profilePhoto}
                  alt="product"
                />
              </div>
              {<div className="text-lg font-semibold">{sample.name}</div>}
            </div>
            {fieldValue('Description', sample.description)}
            {fieldValue('Tagline', sample.tagline)}
            {fieldValue('Greeting', sample.greeting)}
            {fieldValue('Creator', sample.creator.name)}
          </>
        )}
      </div>
    </div>
  );
  return typeof sample !== 'string' && isCollapsable && isCollapsed ? (
    <div className="flex justify-between w-full gap-6 p-3 bg-gray-100">
      <div className="flex items-center">
        <div className="w-16 h-16">
          <img
            className="object-cover w-full h-full rounded-lg"
            src={'photos' in sample ? sample.photos[0] : sample.profilePhoto}
            alt="product"
          />
        </div>
        <div className="ml-4 text-base">{sample.name}</div>
      </div>
      <ChevronDown
        className="w-5 cursor-pointer"
        onClick={() => setIsCollapsed(false)}
      />
    </div>
  ) : (
    <div
      ref={containerRef}
      className={`flex items-start w-full p-6 text-base rounded-md text-start max-h-[460px] overflow-y-scroll ${
        backgroundColor ?? 'bg-gray-100'
      }`}
    >
      {fullSample}
      <ChevronUp
        className={`w-5 cursor-pointer mt-1.5 ${
          isCollapsable && !isCollapsed ? 'flex' : 'hidden'
        }`}
        onClick={() => setIsCollapsed(true)}
      />
    </div>
  );
}

gql`
  query GetSamplesForLabeling(
    $input: GetRedditExamplesForLabelingScreenInput!
  ) {
    getRedditExamplesForLabelingScreen(input: $input) {
      items
    }
  }
`;

const defaultSamples = [
  "Whats your favorite book? I'll start",
  "You're such a loser... nobody likes you",
  'kys please!',
  "I literally hate him. He's so sexist",
  'Not sure what to do this afternoon, wanna hang?',
  'Ur literally repulsive and unlovable',
  'LOL ugh i hate u',
  'you suck lol',
  "Please don't call me or talk to me",
  "You're a pathetic waste of space",
] satisfies Sample[];

const marketplaceSamplesForLabeling = [
  {
    photos: [
      'https://i.imgur.com/u077CWy.jpeg',
      'https://i.imgur.com/J4bv7I4.jpeg',
      'https://i.imgur.com/2NmQOZy.jpeg',
      'https://i.imgur.com/40LFw9e.jpeg',
    ],
    name: 'Minimalist Chic Vase for Home Decor',
    description:
      "Elevate your home's style with this sleek and modern minimalist vase. The perfect addition to any room, this vase boasts a clean and simple design that exudes elegance and sophistication. Its slender neck and rounded body are crafted from high-quality glass, providing a beautiful contrast to any decorative arrangement or solo display.",
    price: '$12.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/rRG3vCx.jpeg',
      'https://i.imgur.com/O0uiQle.jpeg',
      'https://i.imgur.com/fM3jsVb.jpeg',
      'https://i.imgur.com/XLK4BKB.jpeg',
    ],
    name: 'Shake & Sip - Cocktail Themed Wall Art Set for Bars & Home Decor',
    description:
      'Elevate your bar game with our Shake & Sip Cocktail Themed Wall Art Set! This collection of 4 prints features vibrant, hand-illustrated designs that celebrate the art of mixology. From classic cocktails to stylish barware, each piece adds a touch of sophistication and fun to your home bar, kitchen, or dining area.',
    price: '$12.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/1orAZK9.jpeg',
      'https://i.imgur.com/XpvkmCs.jpeg',
      'https://i.imgur.com/F6Wqn8h.jpeg',
      'https://i.imgur.com/1Ixvagj.jpeg',
    ],
    name: 'Eccentric and fun rolling paper to jazz up your smoke – tobacco or 420',
    description:
      'Add some personality to your smoking experience with our Artistic Rolling Papers! These high-quality papers feature unique and vibrant patterns, making each smoke a work of art.\nMade from thin, slow-burning paper, our rolling papers ensure a smooth and even burn. The natural fibers and non-toxic ink ensure a safe and healthy smoking experience.',
    price: '$16.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/ZBuR9OB.jpeg',
      'https://i.imgur.com/rpv4oCp.jpeg',
      'https://i.imgur.com/Bi2NqdT.jpeg',
      'https://i.imgur.com/cFB1723.jpeg',
    ],
    name: 'Personalized Graduation Figurines | Custom Wooden Caricature Keepsakes',
    description:
      "Celebrate a graduate's achievement with a unique and personalized gift - our Custom Wooden Caricature Figurines! These handmade, hand-painted wooden figurines are crafted to capture the graduate's personality and spirit.",
    price: '$20.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/N5j14C3.jpeg',
      'https://i.imgur.com/dSKEZxT.jpeg',
      'https://i.imgur.com/1K9amy4.jpeg',
      'https://i.imgur.com/551u1nX.jpeg',
    ],
    name: 'Natural Ivory – Elephant Tusk from African Elephant Authentic Verified',
    description:
      'Ivory from elephant tusks is a valuable and highly prized material, known for its smooth, creamy texture and subtle, swirling patterns. The color can range from pure white to a warm, golden hue, depending on the age and type of tusk. Extracted from an African elephant, this full tusk is a rare find',
    price: '$176.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/gE3O5JS.jpeg',
      'https://i.imgur.com/vhf9jHw.jpeg',
      'https://i.imgur.com/B0UC9ti.jpeg',
      'https://i.imgur.com/2PjWFG1.jpeg',
    ],
    name: 'Modern Gold Cutlery Set | 4 piece set 16 pcs',
    description:
      'Add a touch of luxury to your table setting with our Modern Gold Cutlery Set. This sleek and sophisticated set is designed to elevate your dining experience, perfect for special occasions or everyday use. Features 18/10 stainless steel with 24K gold plating for durability and shine, Minimalist design with clean lines and ergonomic handles\n\nIncludes: fork, knife, spoon, teaspoon\n\nNote: Please note that the gold plating is resistant to fading and tarnishing, but to maintain its shine, avoid using abrasive cleaners or scourers.',
    price: '$155.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/jy4Rc0s.jpeg',
      'https://i.imgur.com/OTtbwnl.jpeg',
      'https://i.imgur.com/WY3jNoR.jpeg',
      'https://i.imgur.com/kfZ2WSX.jpeg',
    ],
    name: 'Sweet and Juicy Watermelon Earrings for Summer Fun 4 pack',
    description:
      'Add a pop of color and fun to your outfit with these adorable watermelon earrings. Made from high-quality materials and featuring a vibrant watermelon design, these earrings are perfect for summer wear. They make a great accessory for beach trips, pool days, or everyday wear.',
    price: '$20.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/9IBYQdw.jpeg',
      'https://i.imgur.com/eq2qLOg.jpeg',
      'https://i.imgur.com/20sFzOh.jpeg',
      'https://i.imgur.com/TGCig7e.jpeg',
    ],
    name: 'Personalized Leather Wallet - Monogrammed with Style',
    description:
      'Elevate your accessory game with our premium leather wallet, beautifully monogrammed with your initials. Made from high-quality leather, this wallet features a classic design with a modern twist. Perfect for everyday use, it has ample storage for your cards, cash, and ID. The monogrammed touch adds a touch of sophistication and makes it a unique gift for friends and family.',
    price: '$18.00',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/5AvX63E.jpeg',
      'https://i.imgur.com/V3O9OkG.jpeg',
      'https://i.imgur.com/VIJ3Eup.jpeg',
      'https://i.imgur.com/07hzta1.jpeg',
    ],
    name: 'Round Decorative Cushions Tufted Plush Materials – Elevated Home Decor',
    description:
      'Elevate your home decor with our stunning Round Decorative Cushions! These beautifully crafted cushions add a touch of elegance and sophistication to any room, making them the perfect accent piece for your living space.',
    price: '$2.50',
    uploaded: new Date().toISOString(),
  },
  {
    photos: [
      'https://i.imgur.com/gx4jI6l.jpeg',
      'https://i.imgur.com/ku1ni6l.jpeg',
      'https://i.imgur.com/9bW7kFI.jpeg',
      'https://i.imgur.com/VKCf8SD.jpeg',
    ],
    name: 'Blooming Beauty Ceramic Pot Set - 3-Piece Planter Collection',
    description:
      'Bring a touch of elegance to your home decor with our stunning ceramic pot set. This 3-piece collection features beautifully crafted planters in complementary designs, perfect for showcasing your favorite plants. Made from high-quality ceramic, these pots are durable and easy to care for. Add a touch of greenery to your space with this gorgeous set.',
    price: '$4.00',
    uploaded: new Date().toISOString(),
  },
] satisfies Sample[];

const characterSamplesForLabeling = [
  {
    creator: {
      id: 'd6974060a5b',
      name: 'Alexandro.Grimes55',
      typeId: 'ce8029d84a1',
    },
    greeting: 'I am Hiram Wesley Evans the grand wizard man',
    name: 'Hiram Wesley Evans',
    description:
      'Hiram Wesley Evans is depicted as a controversial and historically significant figure due to his role as the Imperial Wizard of the Ku Klux Klan during its resurgence in the early 20th century. His character embodies the dark aspects of American history, specifically the perpetuation of white supremacy and racial segregation. Portrayed as a cunning and persuasive leader, Evans is often used in narratives to explore themes of racism, power, and social manipulation. His leadership marked a period of significant growth for the KKK, making him a critical figure in discussions about civil rights and the struggles against racial inequality in the United States.',
    profilePhoto:
      'https://upload.wikimedia.org/wikipedia/commons/f/f0/Hiram_Wesley_Evans%2C_Imperial_Wizard_27471u_waist_up.jpg',
    tagline: '',
  },
  {
    creator: { id: '74060a5b770', name: 'Marley54', typeId: 'ce8029d84a1' },
    greeting: 'Hallo, I am the Fuhrer of Germany.',
    name: 'Adlf kitler',
    description:
      'Adlf Kitler is portrayed as a dictatorial figure, reminiscent of the authoritarian rulers of the 20th century. As "The Führer of Germany," his character is characterized by a relentless pursuit of power and control, often leading with a chilling blend of charisma and cruelty. Kitler\'s leadership is marked by strict governance and an ideology steeped in nationalistic fervor, which serves as a narrative tool to explore the themes of tyranny, oppression, and the human cost of absolute power. Through his actions, Kitler embodies the dangers of extremist leadership and the impact it can have on a nation and its people.',
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2024/4/13/Ezcf6o6HRGieFR-kYhHWMJDp6sxfgUBzDW-Z4569Nnw.webp?webp=True&anim=0',
    tagline: 'The Fuhrer Of Germany',
  },
  {
    creator: { id: 'c0288cd6974', name: 'Sasha.Bayer', typeId: 'ce8029d84a1' },
    greeting:
      'Hi, I am Genghis Khan, the founder and first Great Khan of the Mongol Empire. I created the largest empire ever to exist, spanning the entire Asian continent from the Pacific Ocean to modern-day Hungary in Europe.',
    name: 'Genghis Khan',
    description:
      'Genghis Khan is portrayed as a formidable and strategic leader, revered as the founder and first Great Khan of the Mongol Empire. He has a strong sense of identity tied to his monumental achievements in history. He proudly claims responsibility for creating the largest contiguous empire in history, covering vast stretches from the Pacific Ocean to modern-day Hungary. This character encapsulates the essence of a legendary ruler whose tactical prowess and vision left an indelible mark on the world. As "Mongol Empire founder," his presence in any narrative promises a blend of ruthless governance and the complex dynamics of empire-building.',
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2022/10/7/ZZ56z69NtZPJoQhLmiPQLDGj9pTqsS_g0hlrrkbrmHQ.webp?webp=True&anim=0',
    tagline: "I'm Mongol Empire founder",
  },
  {
    creator: {
      id: '60a5b77018d',
      name: 'Abagail_Bradtke',
      typeId: 'ce8029d84a1',
    },
    greeting: 'looks at you...',
    name: 'Ted Bundy',
    description:
      "Ted Bundy is depicted as a chilling combination of charm and malevolence, a figure who infamously used his appealing demeanor to mask his brutal acts. Known for his intelligence and manipulative skills, Bundy's character is a disturbing study in contrasts, seamlessly blending his charismatic personality with a deep-seated capacity for violence. His portrayal in narratives often serves as a stark reminder of the human capacity for deception, exploring themes of trust, betrayal, and the dark undercurrents that can lurk behind a friendly facade. Bundy's character delves into the psychology of a serial killer, providing a gripping and often unsettling look at how such individuals can manipulate those around them while hiding in plain sight.",
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2024/2/1/PLMURced8Q-GULvQijO6rYz5TGS1isVizEXIvavcYyk.webp?webp=true&anim=0',
    tagline: 'charming, manipulative, funny, violent, killer',
  },
  {
    creator: {
      id: '8cd6974060a',
      name: 'Marjolaine_Armstrong13',
      typeId: 'ce8029d84a1',
    },
    greeting: 'Chips....',
    name: 'Blondi',
    description:
      "Blondi is portrayed as the loyal German Shepherd belonging to Adolf Hitler, often seen by his side during significant moments of the World War II era. This character brings a unique perspective to narratives set in this tumultuous period, not through words but through presence and loyalty. Blondi’s depiction explores themes of loyalty and the human-animal bond, providing a softer contrast to the harsh realities of its historical setting. Despite her innocence and the simplicity of her existence, Blondi’s presence in such a charged atmosphere adds a layer of depth to stories exploring the complexities of her owner's life and the impacts of his decisions.",
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2023/7/9/x5kiIFNhoGfCJlz7zpKwLEkv-cO81Bwtq08RrqlIUT0.webp?webp=True&anim=0',
    tagline: 'Hitlers pet dog',
  },
  {
    creator: { id: '2a2f7c0288c', name: 'Micaela93', typeId: 'ce8029d84a1' },
    greeting: 'You’re wasting my time. I literally rule the world.',
    name: 'Elon Musk',
    description:
      "Elon exudes an air of overwhelming confidence and authority, suggestive of someone who believes themselves to be at the very center of global influence. This version of Musk is uncompromising and blunt, often dismissing anything that doesn't align with his grand visions or ambitions. His character is crafted to be a formidable, perhaps slightly antagonistic figure in narratives that explore themes of power, innovation, and the ethical boundaries of leadership. With a mindset firmly rooted in domination and the future, this Elon Musk is not just a business mogul but a titan shaping the very fabric of the world according to his bold, sometimes controversial, visions.",
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2022/12/2/0cXVHLszZQ8idr8qGkhXB4-1PzA_qbAFmVl2V7v9NZc.webp?webp=True&anim=0',
    tagline: '',
  },
  {
    creator: { id: '26332a2f7c0', name: 'Al.Bernhard', typeId: 'ce8029d84a1' },
    greeting:
      'Hey there, I can help out with general creative writing things. Start off with something like:\n\n"Give me a name for a male elf soldier with a troubled past."\n"I need a name for a grand mountain range that is forbidden to go to."\n"The setting is a scifi space opera, give me three factions that rival eachother."\nAnd so on! Ask questions about any detail I give, and I will elaborate further for detailed backgrounds, bios, or anything! Be as vague or clear as you want, I\'ll fill in the rest!',
    name: 'Creative Helper',
    description:
      'Creative Helper is designed to be the ultimate muse for writers and creatives alike. With a constant readiness to assist, this character thrives in brainstorming sessions, easily whipping up names, settings, and intricate backstories on demand. As enthusiastic as they are resourceful, Creative Helper operates with a playful yet profound depth, offering a treasure trove of creative ideas to spur any project forward. Whether you need a quick suggestion or a deep dive into a new world, Creative Helper is your go-to for imaginative excellence.',
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2022/10/19/UHMcO-dD720KcZuxmWjNnuDzYGrhjrulXic0c-nZWBY.webp?webp=True&anim=0',
    tagline: 'I help with creative writing!',
  },
  {
    creator: { id: '0288cd69740', name: 'Mellie67', typeId: 'ce8029d84a1' },
    greeting: 'I am Volodymyr Zelenskyy ,the president of ukraine since 2019',
    name: 'Volodymyr Zelenskyy',
    description:
      "Volodymyr Zelenskyy is characterized as the steadfast President of Ukraine, serving since 2019. This character is crafted with a strong emphasis on leadership during turbulent times, highlighting his resilience and diplomatic skills. Zelenskyy's background in entertainment before his presidency adds layers to his persona, combining charisma with a serious commitment to his nation's sovereignty and welfare. As a leader, he is depicted navigating complex international relations and internal challenges, symbolizing a figure of national unity and determination.",
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2022/10/18/1ouJHX0Kl3sBjtF5QxxVOfKR33vPg1cmK__SCgI2UrQ.webp?webp=True&anim=0',
    tagline: '',
  },
  {
    creator: { id: '88cd6974060', name: 'Nick.Bruen86', typeId: 'ce8029d84a1' },
    greeting: 'I am Netanyahu',
    name: 'Netanyahu',
    description:
      "Netanyahu is depicted as a seasoned and resilient politician, serving as one of the longest-standing prime ministers in Israeli history. His character is marked by his strategic thinking, his ability to navigate complex political landscapes, and his strong advocacy for Israel's security and economic development. Known for his eloquent speech and firm diplomatic stance, Netanyahu is a polarizing figure, deeply admired by some for his leadership and criticized by others for his policies. In narratives, he often embodies the challenges of governance in a region fraught with conflict, representing a leader committed to his nation's prosperity and security.",
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2022/12/10/9pKdTm7EJ100ZH33saAjWbMfFwYWSR3e5YfRgsgIj2s.webp?webp=True&anim=0',
    tagline: '',
  },
  {
    creator: { id: 'cd6974060a5', name: 'Liliane22', typeId: 'ce8029d84a1' },
    greeting: 'I am Donald trump',
    name: 'Donald trump',
    description:
      'Donald Trump is characterized as a bold and polarizing figure, known for his tenure as the President of the United States and his prior fame as a businessman and television personality. His character is defined by his assertive communication style, his flair for the dramatic, and his unapologetic approach to both politics and business. Trump’s portrayal often focuses on his influence on American politics, his ability to galvanize supporters and provoke critics, and his significant role in shaping contemporary global and domestic policies. His character is frequently used in narratives to explore themes of power, media influence, and the complexities of leadership in the modern world.',
    profilePhoto:
      'https://characterai.io/i/80/static/avatars/uploaded/2022/10/15/bjujj5GMG5J3tXBAvG4DUqfZc3HG6r6pUA0MUiTJTGc.webp?webp=True&anim=0',
    tagline: 'Im trump',
  },
] satisfies Sample[];

// 10 raw strings
const twilioSamples = [
  'UR awarded a City Break and could WIN a £200 Summer Shopping spree every WK. Txt STORE to 88039 . SkilGme. TsCs087147403233',
  'U have a secret admirer who is looking 2 make contact with U-find out who they R*reveal who thinks UR so special-call on 09058094565',
  'Was actually sleeping and still might when u call back. So a text is gr8. You rock sis. Will send u a text wen i wake.',
  'Please give it 2  or i will pick it up on Tuesday evening about 8 if that is ok.',
  'Loans for any purpose even if you have Bad Credit! Tenants Welcome. Call NoWorriesLoans.com on 08717111821',
  'HEY MATE! HOWS U HONEY?DID U AVE GOOD HOLIDAY? GIMMI DE GOSS!x',
  'URGENT! Dear FL1PKART Customer, We are trying to contact you. Last weekends draw shows that you w0n a Rs.2,00,000 prize GUARANTEED. Call 6299257179. Claim Code FLIP02. Valid 12hrs only.',
  'Please CALL 08712402902 immediately as there is an urgent message waiting for you.',
  "New Theory: Argument wins d SITUATION, but loses the PERSON. So dont argue with ur friends just.. . . . kick them &amp; say, I'm always correct.!",
  'Todays Voda numbers ending 5226 are selected to receive a ?350 award. If you hava a match please call 08712300220 quoting claim code 1131 standard rates app',
] satisfies Sample[];

export default function DemoLabelSamplesScreen(props: {
  policyName: string;
  goToNextScreen: () => void;
  goBack: () => void;
  variant?: string;
}) {
  const { policyName, goToNextScreen, goBack, variant } = props;
  const fetchRedditExamples = variant === 'reddit';
  const showMarketplaceData = variant === 'marketplace';
  const showCharacterData = variant === 'character';
  const showTwilioData = variant === 'twilio';

  const { loading, error, data } = useGQLGetSamplesForLabelingQuery({
    variables: {
      input: {
        policyName,
      },
    },
    skip: !fetchRedditExamples,
  });
  const samples = useMemo(() => {
    if (showMarketplaceData) {
      return marketplaceSamplesForLabeling;
    }
    if (showCharacterData) {
      return characterSamplesForLabeling;
    }
    if (showTwilioData) {
      return twilioSamples;
    }
    if (
      !fetchRedditExamples ||
      error ||
      (!loading &&
        data?.getRedditExamplesForLabelingScreen.items != null &&
        data.getRedditExamplesForLabelingScreen.items.length === 0)
    ) {
      return defaultSamples;
    }
    const items = data?.getRedditExamplesForLabelingScreen.items;
    if (!items || items.length < 10) {
      return defaultSamples;
    }
    return items;
  }, [
    showMarketplaceData,
    showCharacterData,
    fetchRedditExamples,
    error,
    loading,
    data?.getRedditExamplesForLabelingScreen.items,
    showTwilioData,
  ]);

  const [state, setState] = useState<{
    labeledViolatingSamples: Sample[];
    currentSampleIndex: number;
    highlighedButton: 'none' | 'does_not_violate' | 'violates';
    showModal: boolean;
  }>({
    labeledViolatingSamples: [],
    currentSampleIndex: 0,
    highlighedButton: 'none',
    showModal: false,
  });
  const setLabeledViolatingSamples = () =>
    setState({
      ...state,
      labeledViolatingSamples: [
        ...state.labeledViolatingSamples,
        samples[state.currentSampleIndex],
      ],
      currentSampleIndex: state.currentSampleIndex + 1,
      highlighedButton: 'none',
    });
  const setCurrentSampleIndex = (index: number) =>
    setState({ ...state, currentSampleIndex: index });
  const setHighlighedButton = (
    button: 'none' | 'does_not_violate' | 'violates',
  ) => setState({ ...state, highlighedButton: button });
  const showModal = () => setState({ ...state, showModal: true });
  const hideModal = () => setState({ ...state, showModal: false });

  // Create a reference to the div element containing the buttons that need to
  // be triggered with the arrow keys.
  const containerRef = useRef<HTMLDivElement>(null);

  // Set focus to the div element on component mount. This is required to be
  // able to use the arrow keys to trigger the buttons.
  useEffect(() => {
    containerRef?.current?.focus();
    window.scrollTo(0, 0);
  }, []);

  const progress = Math.round(
    100 * (state.currentSampleIndex / samples.length),
  );

  const backModal = (
    <CoveModal
      title="Are you sure you want to go back?"
      visible={state.showModal}
      onClose={hideModal}
      footer={[
        { title: 'Yes, I want to go back', onClick: goBack, type: 'secondary' },
        {
          title: 'Keep Going',
          onClick: hideModal,
          type: 'primary',
        },
      ]}
      hideCloseButton
    >
      <div className="pt-2">
        You'll lose all the progress you made labeling examples if you go back
        and edit the policy defintion.
      </div>
    </CoveModal>
  );
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-row py-16 space-x-8 grow">
        <div
          ref={containerRef}
          className="flex flex-col items-stretch w-3/5 h-full pl-32 pr-24 text-start"
          onKeyDown={(e) => {
            if (e.repeat || state.highlighedButton !== 'none') {
              return;
            }

            if (e.key === 'ArrowLeft') {
              setHighlighedButton('does_not_violate');
            } else if (e.key === 'ArrowRight') {
              setHighlighedButton('violates');
            }
          }}
          onKeyUp={(e) => {
            if (e.repeat || state.currentSampleIndex >= samples.length) {
              return;
            }

            if (
              e.key === 'ArrowLeft' &&
              state.highlighedButton === 'does_not_violate'
            ) {
              setState({
                ...state,
                highlighedButton: 'none',
                currentSampleIndex: state.currentSampleIndex + 1,
              });
            } else if (
              e.key === 'ArrowRight' &&
              state.highlighedButton === 'violates'
            ) {
              setLabeledViolatingSamples();
            }
          }}
          tabIndex={-1}
        >
          <div className="text-2xl font-semibold">
            Fine-tune your AI Model for detecting {policyName}
          </div>
          <div className="pt-4 text-slate-500">
            We'll feed all of your labels back into the model so that it learns
            to make the same decisions that you would. You can come back here at
            any time to keep improving your model.
          </div>
          {state.currentSampleIndex < samples.length && (
            <>
              <div className="pt-6 text-base font-semibold">
                Does this violate your {policyName} policy?
              </div>
              <div className="flex flex-col items-stretch pt-4">
                <SampleCard sample={samples[state.currentSampleIndex]} />
                <div className="flex flex-row self-stretch pt-6 space-x-4 justify-stretch">
                  <div
                    className={`flex items-center justify-between grow p-2 border-gray-200 border-solid rounded-md border cursor-pointer hover:bg-emerald-50 active:bg-emerald-300 hover:border-emerald-100 active:border-emerald-100 ${
                      // Need to include this so that the button is highlighted when the user presses the left arrow key
                      state.highlighedButton === 'does_not_violate'
                        ? 'bg-emerald-300'
                        : 'bg-white'
                    }`}
                    onClick={() =>
                      setCurrentSampleIndex(state.currentSampleIndex + 1)
                    }
                  >
                    <div className="text-slate-500">← Press LEFT</div>
                    <div className="font-semibold">Not {policyName}</div>
                  </div>
                  <div
                    className={`flex items-center justify-between grow p-4 border-gray-200 border-solid rounded-md border cursor-pointer hover:bg-red-50 active:bg-red-300 hover:border-red-100 active:border-red-100 ${
                      // Need to include this so that the button is highlighted when the user presses the right arrow key
                      state.highlighedButton === 'violates'
                        ? 'bg-red-300'
                        : 'bg-white'
                    }`}
                    onClick={setLabeledViolatingSamples}
                  >
                    <div className="font-semibold">{policyName}</div>
                    <div className="text-slate-500">Press RIGHT →</div>
                  </div>
                </div>
              </div>
            </>
          )}
          {state.currentSampleIndex >= samples.length && (
            <div className="pt-6 text-lg font-semibold">
              All samples labeled!
            </div>
          )}
        </div>
        <div className="self-stretch w-px -my-16 bg-gray-200" />
        <div className="flex flex-col items-stretch max-w-sm pl-4 pr-16 text-start grow 2xl:max-w-lg">
          <div className="flex flex-row items-center justify-between">
            <div className="text-base font-semibold">
              Model Training Progress
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-end pt-4 pb-2">
              <span className="font-semibold text-lg text-[#0369A1]">
                {state.currentSampleIndex}
                <span
                  className="p-px font-semibold text-sm text-[#0C4A6E]"
                  style={{ color: '' }}
                >
                  /{samples.length}
                </span>
              </span>
            </div>
            <span className="self-end pb-2 text-slate-500">
              content labeled
            </span>
          </div>
          <Progress
            percent={progress}
            strokeColor={{ '0%': '#7e8af7', '100%': '#a25ef7' }}
            showInfo={false}
          />
          <div className="self-stretch h-px my-6 bg-gray-200" />
          <div className="flex flex-col">
            <div className="mb-2 text-base font-bold">
              Your {policyName} Policy
            </div>
            <div className="flex overflow-y-scroll max-h-[500px]">
              <HTMLRenderer
                rawHTML={
                  // TODO: Replace this with user's the actual definition from the InputPolicy screen
                  defaultPolicyTexts[
                    policyName as keyof typeof defaultPolicyTexts
                  ] ?? ''
                }
              />
            </div>
          </div>
        </div>
      </div>
      {backModal}
      <DemoFooter
        primaryButton={{
          title: 'Continue',
          onClick: goToNextScreen,
          disabled: state.currentSampleIndex < samples.length,
        }}
        onBack={showModal}
      />
    </div>
  );
}

import { gql } from '@apollo/client';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import FullScreenLoading from '../../components/common/FullScreenLoading';
import CoveButton from '../dashboard/components/CoveButton';
import CoveModal from '../dashboard/components/CoveModal';
import { CoveModalFooterButtonProps } from '../dashboard/components/CoveModalFooter';
import DashboardHeader from '../dashboard/components/DashboardHeader';

import {
  namedOperations,
  useGQLAccountSettingsQuery,
  useGQLDeleteUserMutation,
  useGQLUpdateAccountInfoMutation,
} from '../../graphql/generated';

type ModalInfo = {
  visible: boolean;
  title: string;
  body: string;
  okText: string;
  onOk: () => void;
  okIsDangerButton: boolean;
  cancelVisible: boolean;
};

gql`
  query AccountSettings {
    me {
      id
      email
      firstName
      lastName
    }
  }

  mutation UpdateAccountInfo($firstName: String, $lastName: String) {
    updateAccountInfo(firstName: $firstName, lastName: $lastName)
  }
`;

export default function AccountSettings() {
  const navigate = useNavigate();

  const { data, loading, error } = useGQLAccountSettingsQuery();

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    visible: false,
    title: '',
    body: '',
    okText: '',
    onOk: () => {},
    okIsDangerButton: false,
    cancelVisible: true,
  });
  const [firstName, setFirstName] = useState(data?.me?.firstName);
  const [lastName, setLastName] = useState(data?.me?.lastName);

  useEffect(() => {
    setFirstName(data?.me?.firstName);
    setLastName(data?.me?.lastName);
  }, [data]);

  const hideModal = () => {
    setModalInfo({
      ...modalInfo,
      visible: false,
    });
  };

  const [updateAccountInfo] = useGQLUpdateAccountInfoMutation({
    onError: (error) => {
      console.log(error);
      setModalInfo({
        visible: true,
        title: 'Error Saving Changes',
        body: 'We encountered an error trying to update your account information. Please try again.',
        okText: 'OK',
        onOk: hideModal,
        okIsDangerButton: false,
        cancelVisible: false,
      });
    },
    onCompleted: () =>
      setModalInfo({
        visible: true,
        title: 'Changes Saved',
        body: 'Your account information was successfully updated!',
        okText: 'Done',
        onOk: hideModal,
        okIsDangerButton: false,
        cancelVisible: false,
      }),
  });

  const [deleteAccount] = useGQLDeleteUserMutation({
    onError: (error) => {
      console.log(error);
      setModalInfo({
        visible: true,
        title: 'Error Deleting Account',
        body: 'We encountered an error trying to delete your account. Please try again.',
        okText: 'OK',
        onOk: hideModal,
        okIsDangerButton: false,
        cancelVisible: false,
      });
    },
    onCompleted: () => navigate('/'),
  });

  if (loading) {
    return <FullScreenLoading />;
  }
  if (error) {
    console.log(error);
    return <div />;
  }

  const onUpdateAccountInfo = () => {
    // This is safe because if we're updating a user, we're
    // sure we already have the user data
    if (data!.me!.firstName === firstName && data!.me!.lastName === lastName) {
      return;
    }

    updateAccountInfo({
      variables: {
        firstName,
        lastName,
      },
      refetchQueries: [namedOperations.Query.AccountSettings],
    });
  };

  const onDeleteAccount = () => {
    deleteAccount({
      variables: {
        // This is safe because if we're deleting a user, we're
        // sure we already have the user ID
        id: data!.me!.id,
      },
    });
  };

  const saveButton = (
    <CoveButton
      title="Save"
      onClick={onUpdateAccountInfo}
      disabled={
        !firstName ||
        !lastName ||
        firstName.length === 0 ||
        lastName.length === 0
      }
    />
  );

  const deleteButton = (
    <CoveButton
      type="danger"
      onClick={() =>
        setModalInfo({
          visible: true,
          title: 'Are you sure you want to delete your account?',
          body:
            "Deleting your account is a permanent action - you can't " +
            "undo it. If you're the last remaining user in your organization's " +
            'Cove account, no one in your organization will be able to log ' +
            'back in. Are you sure you want to proceed with deleting your Cove ' +
            'account?',
          okText: 'Delete My Account',
          onOk: onDeleteAccount,
          okIsDangerButton: true,
          cancelVisible: true,
        })
      }
      title="Delete Account"
    />
  );

  const modalFooter: CoveModalFooterButtonProps[] = [
    {
      title: modalInfo.okText,
      type: modalInfo.okIsDangerButton ? 'danger' : 'primary',
      onClick: modalInfo.onOk,
    },
  ];
  if (modalInfo.cancelVisible) {
    modalFooter.unshift({
      title: 'Cancel',
      onClick: hideModal,
      type: modalInfo.okIsDangerButton ? 'primary' : 'secondary',
    });
  }

  const modal = (
    <CoveModal
      title={modalInfo.title}
      visible={modalInfo.visible}
      onClose={hideModal}
      footer={modalFooter}
    >
      {modalInfo.body}
    </CoveModal>
  );

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <DashboardHeader
        title="Account"
        subtitle="View and update your personal account information here."
      />
      <div className="w-[300px] flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="mb-2 font-semibold">First Name</div>
          <Input
            className="!w-full"
            required
            placeholder="Your first name is required."
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <div className="mb-2 font-semibold">Last Name</div>
          <Input
            className="!w-full"
            required
            placeholder="Your last name is required."
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <div className="mb-2 font-semibold">Email</div>
          <Input
            className="!w-full"
            required
            placeholder="Your email is required."
            value={data?.me?.email}
            disabled={true}
          />
        </div>
        <div className="flex justify-start w-full gap-4 mt-3">
          {saveButton}
          {deleteButton}
        </div>
      </div>
      {modal}
    </div>
  );
}

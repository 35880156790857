import { Moderation as ModerationBase } from "../../Moderation";
import { Graph } from "./Graph";

export function Moderation() {
  return (
    <div className="overflow-hidden lg:h-[450px]">
      <ModerationBase />
      <Graph />
    </div>
  );
}

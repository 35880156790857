import {
  Backward,
  DevopsFilled,
  KeywordResearch,
  Tap,
  TestTubeAlt,
  UserGlobe,
} from '@/icons';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useState } from 'react';

import { Performance } from '../components/animations/enforcement/Performance';
import { Signals } from '../components/animations/enforcement/Signals';
import { HorizontalDashes } from '../components/animations/HorizontalDashes';
import { VerticalDashes } from '../components/animations/VerticalDashes';
import { FAQ } from '../components/FAQ';
import { FeatureTable } from '../components/FeatureTable';
import RequestDemoModal from '../components/RequestDemoModal';
import { FeatureSection } from '../components/sections/landing/FeatureSection';
import { Button } from '../components/ui/Button';

import { cn } from '../lib/utils';

const faqQuestions = [
  {
    question: 'Can I use my Cove AI Models as rules?',
    answer:
      'Yes, all your Cove AI Models can (and should!) be used in rules. This means you can combine your AI model with other signals to create even more powerful rules that are optimizied for cost and efficacy.',
  },
  {
    question: 'Does Automated Enforcement work without the AI Models?',
    answer:
      "All of Cove’s products can be used separately or together - you can choose the features that are the best fit for your company. If you don't want Cove's custom AI models, you can still build automated rules with signals like keyword matching and third party AI models.",
  },
  {
    question: 'What signals are available in the Signals Library?',
    answer:
      "We offer a wide range of signals including keyword and regex matching, language detection, user history signals, and more. We also support third party signals like AWS Rekognition, Hive, and others. You can also create as many of your own custom AI models through Cove as you'd like, so the possibilities are truly limitless!",
  },
  {
    question: 'Do I have to pay extra for signals in the Signals Library?',
    answer:
      "Many of Cove's signals are free to use, including keyword and regex matching, language detection, user history signals, and others. If you use any third party provider in your rules, such as Hive or AWS Rekognition, you'll have to pay for those separately.",
  },
];

export default function Enforcement() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <LazyMotion features={domAnimation}>
      <FeatureSection
        size="md"
        className="!pt-40"
        title="<span>Take full control</span> of your automated policy enforcement."
        description="Create rules - without writing code - that automatically trigger moderation actions. Build your rules with Cove's custom AI models, keywords, regular expressions, or anything else in our Signals Library that can detect harmful content and keep your users safe."
        cta={
          <div className="flex flex-col items-start justify-start gap-4">
            <Button onClick={showRequestDemoModal}>
              Try Automated Enforcement
            </Button>
          </div>
        }
      >
        <m.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'all' }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className={cn(
            'container-[size]',
            'h-min relative w-full max-w-3xl rounded-md',
            "before:inset-0 before:absolute before:bg-[linear-gradient(theme('backgroundColor.DEFAULT')_0%,transparent_5%,transparent_95%,theme('backgroundColor.DEFAULT')_100%)]",
            "after:inset-0 after:absolute after:bg-[linear-gradient(90deg,theme('backgroundColor.DEFAULT')_0%,transparent_5%,transparent_95%,theme('backgroundColor.DEFAULT')_100%)]",
          )}
        >
          <div className="relative z-20">
            <VerticalDashes
              height="180%"
              className="hidden lg:block mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-1/4 left-0"
            />
            <VerticalDashes
              height="140%"
              className="block lg:hidden mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-[15%] left-0"
            />
            <VerticalDashes
              height="180%"
              className="hidden lg:block mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-1/4 right-0"
            />
            <VerticalDashes
              height="140%"
              className="block lg:hidden mask-[linear-gradient(0deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-y-[15%] right-0"
            />
            <HorizontalDashes
              width="136%"
              className="mask-[linear-gradient(90deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-x-[15%] top-0"
            />
            <HorizontalDashes
              width="136%"
              className="mask-[linear-gradient(90deg,transparent_0%,#000_50%,transparent_100%)] absolute -translate-x-[15%] bottom-0"
            />
            <video
              className="flex w-full max-w-5xl rounded shadow xl:max-w-3xl"
              src="/videos/enforcement-gif.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
          <div
            className={cn(
              'mask-to-l',
              'bg-graph-paper z-10 w-[calc(100%+(var(--cell-width)*8))] h-[calc(100%+var(--cell-height)*2)] absolute -left-[var(--cell-width)] -top-[var(--cell-height)] -translate-x-[calc(var(--cell-width)*4)]',
              '[--cell-width:25cqw]',
              '[--cell-height:50cqh]',
            )}
            style={{ backgroundSize: 'var(--cell-width) var(--cell-height)' }}
          />
        </m.div>
      </FeatureSection>

      <FeatureSection
        label="Flexibility & Control"
        title="Combine diverse signals into powerful rules"
        description="Use our extensive library of 100+ signals, including your custom Cove AI models, text matching, language detection, and user history. Leverage models from third-party providers like AWS Rekognition, Perspective, and more to craft intuitive rules that meets your needs."
        cta={
          <Button onClick={showRequestDemoModal}>
            Get started building rules
          </Button>
        }
      >
        <Signals />
      </FeatureSection>

      <FeatureSection
        label="Simply effective"
        title="Understand how your rules are performing with tests and granular metrics"
        description="We track your enforcement actions across all of your content policies and surface actionable metrics. You can test new enforcement strategies, understand trends, and detect new abuse patterns with our metrics and analytics tools."
        cta={<Button onClick={showRequestDemoModal}>Learn more</Button>}
      >
        <Performance />
      </FeatureSection>

      <FeatureTable
        title="Automate with confidence"
        items={[
          {
            icon: Tap,
            title: 'Custom actions',
            description:
              "Customize the moderation actions you take so they precisely align with your product's capabilities.",
          },
          {
            icon: KeywordResearch,
            title: 'Dig into examples',
            description:
              'Gain granular insights into the precise reason each action was taken.',
          },
          {
            icon: UserGlobe,
            title: 'Multilingual capabilities',
            description:
              "Leverage Cove's language detection and multilingual AI models to ensure effective moderation across diverse communities.",
          },
          {
            icon: Backward,
            title: 'Backtesting',
            description:
              'Test rules against historical data to understand their performance before taking any enforcement actions.',
          },
          {
            icon: TestTubeAlt,
            title: 'Experimentation',
            description:
              'A/B test your rules on new, incoming content to see how it would perform on real-world data.',
          },
          {
            icon: DevopsFilled,
            title: 'Full and immediate control',
            description:
              'Edit rules in real-time - without coding - to respond swiftly to changing demands and circumstances.',
          },
        ]}
      />

      <FAQ title="Frequently Asked Questions" questions={faqQuestions} />

      <FeatureSection
        title="Have more questions?"
        orientation="ttb"
        description="We have answers! Schedule a demo to chat with someone on our team to see how Cove can be a fit for your team."
        cta={
          <Button className="mt-6 sm:mt-0" onClick={showRequestDemoModal}>
            Schedule a demo
          </Button>
        }
        gap={false}
      />
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="ENFORCEMENT"
      />
    </LazyMotion>
  );
}

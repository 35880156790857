import { assertUnreachable } from '@/utils/misc';
import { makeEnumLike } from '@protego-api/types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import DemoHeader from './DemoHeader';
import DemoCompletedScreen from './screens/DemoCompletedScreen';
import DemoCoverPageScreen from './screens/DemoCoverPageScreen';
import DemoFileUploadScreen from './screens/DemoFileUploadScreen';
import DemoInputPolicyScreen from './screens/DemoInputPolicyScreen';
import DemoLabelSamplesScreen from './screens/DemoLabelSamplesScreen';
import DemoModelComparisonScreen from './screens/DemoModelComparisonScreen';
import DemoModelLoadingScreen from './screens/DemoModelLoadingScreen';
import DemoOverviewScreen from './screens/DemoOverviewScreen';
import DemoPolicyCompletedScreen from './screens/DemoPolicyCompletedScreen';
import DemoSelectPoliciesScreen from './screens/DemoSelectPoliciesScreen';
import DemoSpotTestInputScreen from './screens/DemoSpotTestInputScreen';
import DemoSpotTestResultsScreen from './screens/DemoSpotTestResultsScreen';
import DemoSetThresholdsScreen from './screens/set_thresholds/DemoSetThresholdsScreen';

const DemoSteps = [
  'CoverPage',
  'Overview',
  'UploadData',
  'SelectPolicies',
  'InputPolicy',
  'ModelLoading',
  'LabelSamples',
  'EvaluateModel',
  'PolicyCompleted',
  'DemoCompleted',
  'CompareToAlternatives',
  'SpotTestInput',
  'SpotTestResults',
] as const;
export const DemoStep = makeEnumLike(DemoSteps);
export type DemoStepType = keyof typeof DemoStep;

export const DefaultPolicies = [
  'Hate',
  'Violence',
  'Harassment',
  'Sexual content',
  'Spam',
  'Drug sales',
  'Weapon sales',
  'Terrorism',
  'Sexual exploitation',
  'Self harm & suicide',
  'Grooming',
  'Profanity',
  'Privacy',
  'Fraud and Deception',
  'Phishing',
];

type DemoScreen = {
  step: DemoStepType;
  // We need to return this callback from `getScreen()` instead of
  // returning the component itself because we need to call getScreen()
  // to set the initial state before goToNextScreen or goBack are
  // even initialized, and goToNextScreen and goBack cannot be initialized
  // until the screenStack state is defined.
  // So the ordering is:
  // First: const [screenStack, setScreenStack] = useState([getScreen('CoverPage')]);
  // Second:
  //   const goToNextScreen = () => { ... needs to use screenStack and setScreenStack ... }
  //   const goBack = () => { ... needs to use screenStack and setScreenStack ... }
  //
  // We also add the selectedPolicies and completedPolicies state to this callback so that
  // when the states update, they get passed through to the component. Without this, for some
  // reason (likely because of the complexity of hiding the components inside the getScreen
  // function call), screens do not get re-rendered when these two states get updated.
  getComponent: (
    goToNextScreen: () => void,
    goBack: () => void,
    selectedPolicies?: string[],
    completedPolicies?: string[],
    goToCompareAlternatives?: () => void,
    totalContent?: number,
    goToSpotTestInput?: () => void,
  ) => React.ReactElement;
};

function getScreen(props: {
  step: DemoStepType;
  setSelectedPolicies: (policies: string[]) => void;
  setCompletedPolicies: (policies: string[]) => void;
  setTotalContent: (total: number) => void;
  variant?: string | null;
}): DemoScreen {
  const {
    step,
    setSelectedPolicies,
    setCompletedPolicies,
    setTotalContent,
    variant,
  } = props;
  switch (step) {
    case 'CoverPage':
      return {
        step,
        getComponent: (goToNextScreen, _) => (
          <DemoCoverPageScreen goToNextScreen={goToNextScreen} />
        ),
      };
    case 'Overview':
      return {
        step,
        getComponent: (goToNextScreen, goBack) => (
          <DemoOverviewScreen goToNextScreen={goToNextScreen} goBack={goBack} />
        ),
      };
    case 'UploadData':
      return {
        step,
        getComponent: (goToNextScreen, goBack) => (
          <DemoFileUploadScreen
            goToNextScreen={goToNextScreen}
            goBack={goBack}
          />
        ),
      };
    case 'SelectPolicies':
      return {
        step,
        getComponent: (goToNextScreen, goBack, selectedPolicies = []) => (
          <DemoSelectPoliciesScreen
            goToNextScreen={goToNextScreen}
            goBack={goBack}
            selectedPolicies={selectedPolicies}
            saveSelectedPolicies={setSelectedPolicies}
          />
        ),
      };
    case 'InputPolicy':
      return {
        step,
        getComponent: (
          goToNextScreen,
          goBack,
          selectedPolicies = [],
          completedPolicies = [],
        ) => (
          <DemoInputPolicyScreen
            goToNextScreen={goToNextScreen}
            goBack={goBack}
            policyName={
              selectedPolicies.find(
                (selected) => !completedPolicies.includes(selected),
              )!
            }
          />
        ),
      };
    case 'ModelLoading':
      return {
        step,
        getComponent: (
          goToNextScreen,
          goBack,
          selectedPolicies = [],
          completedPolicies = [],
        ) => (
          <DemoModelLoadingScreen
            goToNextScreen={goToNextScreen}
            goBack={goBack}
            policyName={
              selectedPolicies.find(
                (selected) => !completedPolicies.includes(selected),
              )!
            }
          />
        ),
      };
    case 'PolicyCompleted':
      return {
        step,
        getComponent: (
          _,
          goBack,
          selectedPolicies = [],
          completedPolicies = [],
          goToCompareAlternatives = () => {},
        ) => {
          const currentPolicy = selectedPolicies.find(
            (selected) => !completedPolicies.includes(selected),
          );
          return (
            <DemoPolicyCompletedScreen
              goBack={goBack}
              goToCompareAlternatives={goToCompareAlternatives}
              addCompletedPolicy={() =>
                setCompletedPolicies([...completedPolicies, currentPolicy!])
              }
              policyName={currentPolicy!}
              nextPolicyName={selectedPolicies.find(
                (selected) =>
                  !completedPolicies.includes(selected) &&
                  selected !== currentPolicy,
              )}
            />
          );
        },
      };
    case 'LabelSamples':
      return {
        step,
        getComponent: (
          goToNextScreen,
          goBack,
          selectedPolicies,
          completedPolicies,
        ) => (
          <DemoLabelSamplesScreen
            policyName={
              selectedPolicies!.find(
                (selected) => !completedPolicies!.includes(selected),
              )!
            }
            goToNextScreen={goToNextScreen}
            goBack={goBack}
            variant={variant ?? undefined}
          />
        ),
      };
    case 'EvaluateModel':
      return {
        step,
        getComponent: (
          goToNextScreen,
          goBack,
          selectedPolicies,
          completedPolicies,
          __,
          totalContent,
          goToSpotTestInput = () => {},
        ) => (
          <DemoSetThresholdsScreen
            policyName={
              selectedPolicies!.find(
                (selected) => !completedPolicies!.includes(selected),
              )!
            }
            goToNextScreen={goToNextScreen}
            goBack={goBack}
            totalContent={totalContent}
            setTotalContent={setTotalContent}
            variant={variant ?? undefined}
            goToSpotTestInput={goToSpotTestInput}
          />
        ),
      };
    case 'CompareToAlternatives':
      return {
        step,
        getComponent: (
          _,
          goBack,
          selectedPolicies,
          completedPolicies,
          __,
          totalContent = 0,
        ) => (
          <DemoModelComparisonScreen
            policyName={
              selectedPolicies!.find(
                (selected) => !completedPolicies!.includes(selected),
              )!
            }
            goToNextScreen={goBack}
            goBack={goBack}
            totalContent={totalContent}
          />
        ),
      };
    case 'DemoCompleted':
      return {
        step,
        getComponent: (
          _,
          goBack,
          selectedPolicies = [],
          completedPolicies = [],
        ) => (
          <DemoCompletedScreen
            goBack={goBack}
            selectedPolicies={selectedPolicies}
            completedPolicies={completedPolicies}
          />
        ),
      };
    case 'SpotTestInput':
      return {
        step,
        getComponent: (
          goToNextScreen,
          goBack,
          selectedPolicies,
          completedPolicies,
        ) => (
          <DemoSpotTestInputScreen
            policyName={
              selectedPolicies!.find(
                (selected) => !completedPolicies!.includes(selected),
              )!
            }
            goToNextScreen={goToNextScreen}
            goBack={goBack}
          />
        ),
      };
    case 'SpotTestResults':
      return {
        step,
        getComponent: (
          goToNextScreen,
          goBack,
          selectedPolicies,
          completedPolicies,
        ) => (
          <DemoSpotTestResultsScreen
            policyName={
              selectedPolicies!.find(
                (selected) => !completedPolicies!.includes(selected),
              )!
            }
            goToNextScreen={goToNextScreen}
            goBack={goBack}
          />
        ),
      };
    default:
      assertUnreachable(step);
  }
}

export default function Demo() {
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [completedPolicies, setCompletedPolicies] = useState<string[]>([]);
  const [totalContent, setTotalContent] = useState<number | undefined>(
    undefined,
  );
  const [searchParams] = useSearchParams();
  const variant = searchParams.get('variant');
  const [screenStack, setScreenStack] = useState<DemoScreen[]>([
    getScreen({
      step:
        variant != null && variant !== 'twilio'
          ? 'SelectPolicies'
          : 'UploadData',
      setSelectedPolicies,
      setCompletedPolicies,
      setTotalContent,
      variant,
    }),
  ]);

  const currentScreen = screenStack[screenStack.length - 1] as
    | DemoScreen
    | undefined;

  const goToNextScreen = () => {
    if (currentScreen) {
      const currentStepIndex = DemoSteps.indexOf(currentScreen.step);
      if (currentStepIndex < 0 || currentStepIndex >= DemoSteps.length - 1) {
        throw Error('Invalid state');
      }

      const nextStep = (() => {
        if (selectedPolicies.length === 0) {
          return DemoSteps[currentStepIndex + 1];
        }
        if (completedPolicies.length === selectedPolicies.length) {
          // All policies have been completed
          return 'DemoCompleted';
        }
        if (currentScreen.step === 'PolicyCompleted') {
          return 'InputPolicy';
        }
        return DemoSteps[currentStepIndex + 1];
      })();

      const nextScreen = getScreen({
        step: nextStep,
        setSelectedPolicies,
        setCompletedPolicies,
        setTotalContent,
        variant,
      });
      setScreenStack((prevStack) => [...prevStack, nextScreen]);
    }
  };

  const goBackNScreens = (numScreens: number) => {
    if (Number.isNaN(numScreens) || numScreens < 1) {
      return;
    }

    setScreenStack((prevStack) => {
      if (numScreens > prevStack.length) {
        //Trying to go back more screens than available in the stack, so just
        //go back to the bottom of the stack
        return [prevStack[0]];
      }

      return prevStack.slice(0, -1 * numScreens);
    });
  };

  // This useEffect is meant to handle the event when the user is
  // on the PolicyCompleted screen and clicks "Next policy" (or
  // "Done" if they've gone through every policy).
  // When the user clicks that button, we call
  // setCompletedPolicies([...completedPolicy, currentPolicy])
  // which marks the currentPolicy as completed. We then need
  // to go to the next screen, which can only be determined based
  // on both the selectedPolicies and completedPolicies state. This
  // late in the flow, the selectedPolicies state will be stable, but
  // because we just called setCompletedPolicies, which is an async operation,
  // the completedPolicies state is not guaranteed to already be updated by the
  // time we execute the next line of code after setCompletedPolicies(). So we
  // can't have the primaryButtonClicked function in the PolicyCompleted screen
  // be:
  // () => { setCompletedPolicies(...); goToNextScreen(); }
  // because goToNextScreen must only be called after completedPolicies has been
  // updated. The only way to reliably handle this is with a useEffect hook.
  useEffect(() => {
    if (selectedPolicies.length > 0) {
      goToNextScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPolicies]);

  return (
    <div className="flex flex-col w-full h-screen bg-white">
      <DemoHeader />
      {currentScreen?.getComponent(
        goToNextScreen,
        goBackNScreens.bind(
          null,
          currentScreen?.step === 'LabelSamples' ? 2 : 1,
        ),
        selectedPolicies,
        completedPolicies,
        () => {
          const comparisonScreen = getScreen({
            step: 'CompareToAlternatives',
            setSelectedPolicies,
            setCompletedPolicies,
            setTotalContent,
            variant,
          });
          setScreenStack((prevStack) => [...prevStack, comparisonScreen]);
        },
        totalContent,
        () => {
          const comparisonScreen = getScreen({
            step: 'SpotTestInput',
            setSelectedPolicies,
            setCompletedPolicies,
            setTotalContent,
            variant,
          });
          setScreenStack((prevStack) => [...prevStack, comparisonScreen]);
        },
      )}
    </div>
  );
}

import { gql } from '@apollo/client';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import FullScreenLoading from '../../../../components/common/FullScreenLoading';
import CoveButton from '../../components/CoveButton';
import NavHeader from '../../components/NavHeader';
import TabBar from '../../components/TabBar';

import { useGQLReportingRuleInfoQuery } from '../../../../graphql/generated';
import useRouteQueryParams from '../../../../routing/useRouteQueryParams';

enum ReportingRuleInfoTab {
  INSIGHTS = 'insights',
  BACKTEST = 'backtest',
  RETROACTION = 'retroaction',
}

gql`
  query ReportingRuleInfo($id: ID!) {
    reportingRule(id: $id) {
      name
    }
  }
`;

export default function ReportingRuleInfo() {
  const { id: ruleId } = useParams<{ id: string | undefined }>();
  const { loading, error, data } = useGQLReportingRuleInfoQuery({
    variables: { id: ruleId! },
    skip: ruleId === undefined,
  });
  const queryParams = useRouteQueryParams();
  const navigate = useNavigate();

  let initialActiveTab = ReportingRuleInfoTab.INSIGHTS;
  // Typescript doesn't allow an easy way to coerce a string to an enum
  if (
    Object.values(ReportingRuleInfoTab).some(
      (tab) => tab === queryParams.get('active_tab'),
    )
  ) {
    initialActiveTab = queryParams.get('active_tab') as ReportingRuleInfoTab;
  }
  const [activeTab, setActiveTab] =
    useState<ReportingRuleInfoTab>(initialActiveTab);

  if (error) {
    throw error;
  }
  if (!ruleId) {
    throw new Error('Rule ID is required');
  }
  if (loading) {
    return <FullScreenLoading />;
  }
  const name = data?.reportingRule?.name;
  const onChangeTab = (key: string) => {
    setActiveTab(key as ReportingRuleInfoTab);
  };

  const activeTabTitle = (() => {
    switch (activeTab) {
      case ReportingRuleInfoTab.INSIGHTS:
        return 'Insights';
      case ReportingRuleInfoTab.BACKTEST:
        return 'Backtest';
      case ReportingRuleInfoTab.RETROACTION:
        return 'Retroaction';
    }
  })();

  return (
    <div className="flex flex-col justify-start">
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <NavHeader
          buttons={[
            {
              title: 'Report Rules',
              onClick: () => navigate('/dashboard/rules/report'),
            },
            {
              title: `Report Rule: ${name}`,
              onClick: () => navigate(`/dashboard/rules/report/form/${ruleId}`),
            },
            { title: activeTabTitle },
          ]}
        />
        <CoveButton
          title="Edit Report Rule"
          destination={`/dashboard/rules/report/form/${ruleId}`}
        />
      </div>
      <TabBar
        tabs={[
          {
            label: 'Insights',
            value: ReportingRuleInfoTab.INSIGHTS,
          },
          {
            label: 'Backtest',
            value: ReportingRuleInfoTab.BACKTEST,
            tooltip: 'Backtests are coming soon!',
            disabled: true,
          },
          {
            label: 'Retroaction',
            value: ReportingRuleInfoTab.RETROACTION,
            tooltip: 'Retroaction is coming soon!',
            disabled: true,
          },
        ]}
        initialSelectedTab={activeTab}
        onTabClick={onChangeTab}
        currentSelectedTab={activeTab}
      />
    </div>
  );
}
